
<!--

-->
<app-main-baner></app-main-baner>



<div class="wrapper">

   

    <div style="margin-top:12px">
        <app-main-decisions ></app-main-decisions>
    </div>

  <!--<div style="margin-top:64px">
        <app-main-content></app-main-content>
    </div>-->



    <div style=" margin-top: 7vw;">
       <!-- <app-main-present></app-main-present>-->
        <app-main-present-two></app-main-present-two>
    </div>
            
 
    <div style="margin-top: 7vw;">
        <app-main-products></app-main-products>
    </div>

    <div style=" margin-top: 7vw;">
        <app-banner-bottom-animation></app-banner-bottom-animation>
    </div>

    <div class="discription-wrap" style="margin-top: 7vw; width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
        <app-discriptions  style="width: 90%;"
            [link]="'Ссылка'"
            [title]="'Компания TripAdvance'"
            [subtitle]="'За каждым достижением стоит команда'"
        >
            <div class="text__block">
            <h3 style="width: 100%;" class="text">Наша компания занимается разработкой и поддержкой программного обеспечения на базе платформы 1С:Отель. Мы предоставляем полный спектр услуг от разработки
                и <a class="text__block-link" href="#" style="color:#ED3E3E;">внедрения</a> до сопровождения и поддержки. Имеем большой опыт в области автоматизации бизнес-процессов и готовы предложить нашим клиентам индивидуальные
                решения. Наши специалисты имеют высокую квалификацию и опыт работы в сфере 1С, что позволяет нам быстро и качественно выполнять задачи любой сложности</h3>
             </div> 
             <div style="margin-top: 50px;"></div>
             <app-button>О КОМПАНИИ</app-button> 
        </app-discriptions>
        </div>

        <div class="discription-wrap" style=" margin-top:7vw; width: 100%;">
            <app-banner-content></app-banner-content>
        
            <div class="discription-wrap" style=" margin-top: 100px;"></div>
            <div style=" width: 95%; height: 0.5px; background: #c9c9c9b3; margin: auto;"></div>
            <div class="partner-slider">
               <app-element-partners></app-element-partners>
            </div>
        </div>     
        
      <div style="margin-bottom: 7vw; margin-top:4vw;">
            <app-paralel-scroll ngSkipHydration></app-paralel-scroll>
        </div> 
        
        <div style="margin-top: 100px;">
            <app-footer></app-footer>
          </div> 

         
<!--
    <div style="margin: 64px 24px 0px 24px">
        <app-main-chapters></app-main-chapters>
    </div>

    <div style="margin: 64px 24px 0px 24px">
        <app-main-integrations></app-main-integrations>
    </div>     

    <div style="margin: 64px 24px 0px 24px">
        <app-main-partners></app-main-partners>
    </div>   

    <div style="margin: 64px 24px 0px 24px">
        <app-main-company></app-main-company>
    </div>   

    <div style="margin: 64px 24px 0px 24px">
        <app-main-news></app-main-news>
    </div> 
    
-->
   <!-- <app-modal-window></app-modal-window>-->
</div>
