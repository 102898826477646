<div style="width: 100%; margin-top: 40px;" >
   
    <div style="width: 90%; margin: auto; margin-bottom: 100px;">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
    <div style="width: 90%; margin: auto;">
      <app-element-product-text
       [item]="this.page.text1">
      </app-element-product-text> 
   </div> 
 
   <div class="margins-section-tag">
    <app-element-tags [item]='[
         { text:"Модуль бронирования"},
         { text:"Обучение 1С:Отель с нуля"}, 
         { text:"1С:Отель + Битрикс24"},
         { text:"1С: Отель отчеты"},
         { text:"Лицензии"},
         { text:"Программа 1С: Отель"},
         { text:"1С + кассы онлайн"},
         { text:"Разработка печатных форм"},
         { text:"Услуги программиста по 1С: Отель"},
       ]'>
       </app-element-tags>
   </div>

   
    <div class="margins-section">
        <app-banner-partner [item]="this.page.banerOne" ngSkipHydration></app-banner-partner>
      
    </div>
   

    <div class="margins-section-two">
        <app-marcetong-cards-two [item]="this.page.marketingCards"></app-marcetong-cards-two>
    </div>
  
    <div class="margins-section-tree">
        <h2 class="litle">Изменим ваш подход в управлении объектом размещения и работе в программе 1С: Отель </h2>
    </div>

    <div class="margins-section-four">
        <app-content-img-riht [item]="this.page.contentImgRiht"></app-content-img-riht>
    </div>
    <div class="margins-section-four">
        <app-content-img-left [item]="this.page.contentImgLeft"></app-content-img-left>
    </div>
    <div class="margins-section-four">
        <app-content-img-riht [item]="this.page.contentImgRiht2"></app-content-img-riht>
    </div>

    <div class="margins-section-five">
     <app-element-baner-form></app-element-baner-form>
   </div>

    <div class="margins-section-footer">
       <app-footer></app-footer> 
     </div> 

     
 </div>


