<div class="wrapper" *ngIf="isClose === false && isVisible"><!--обертка формы закрытие всей формы-->
   <div class="form-popup"><!--родительский компонент - форма-->

  
   <div class="form-upper" *ngIf="showUpper"><!--обертка верхней формы с инпутами для закрытия формы при отправке добавить в тег - -->
      <app-modal-forms-upper (removeUpper)="onRemoveUpper()"   (toggleEvent)="toggleComponents()"></app-modal-forms-upper><!--компонент формы с инпутами-->
      <div class="form-close" (click)="this.close()"></div><!--кнопка закрытия всей  формы-->
   </div>
  
   <div class="form-lower">
      <app-modal-forms-lower (click)="this.close()"></app-modal-forms-lower><!--компонент формы с благодарностью при нажатии на весь компонент форма закроется  -->
   </div>
   </div>
</div> 