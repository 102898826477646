import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-red-arrow',
  templateUrl: './red-arrow.component.html',
  styleUrls: ['./red-arrow.component.scss']
})
export class RedArrowComponent {
  @Input() small:any = false;

  @Output('onClick') event = new EventEmitter<any>();

  productNext(){
    this.event.emit(1);
  }

  productPrev(){
    this.event.emit(-1);
  }
}
