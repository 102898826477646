
<div class="wrapper">
    <div class="left">
        <div class="line" *ngFor="let item of this.accordions"> 
            <div class="btn"  (click)="this.onChange(item)" >
                <h1 class="btn-title" [class.title-big]="item.expanded==true">{{item.title}}</h1>
                <img class="icon" src="assets/header/right-arrow.svg" [class.arrow-actual]="item.expanded==true"/>
            </div>
            <app-collapse [expanded]="item.expanded">  
                <p class="discription">{{item.description}}</p> 
                <img class="mobile-img-accordion" [src]="this.item.img"/> 
                <a class="link" href="">
                  <app-button-detail>ПОДРОБНЕЕ</app-button-detail>
                </a>
            </app-collapse>
        </div>
    </div> 
 
    
    <div class="riht" *ngIf=" this.currentPage!=undefined">
        <div class="block-baner__container" [style.background-image]="this.currentPage.image"></div>
    </div>
</div> 

