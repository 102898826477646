import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-element-card-marketing',
  templateUrl: './element-card-marketing.component.html',
  styleUrls: ['./element-card-marketing.component.scss']
})
export class ElementCardMarketingComponent {
  @Input() item:any = {};
    
}
