<div class="wrapper">
    <div class="wrapper-products">
        <div class="products">
            <div class="product">
                <div class="product-top">
                    
                    <div class="product-header">
                        <div class="product-header__title">
                            <h3 class="product-header__title_text1">{{this.item.title}}</h3>
                            <h3 class="product-header__title_text2">{{this.item.description}}</h3>
                        </div>   
                        <img class="product-header__title_icon" [attr.src]="this.item.image">
                    </div>
                </div>
                <div class="product-bottom">
                    <div class="product-navigation"> 
                        <app-button>ПОДРОБНЕЕ</app-button>
                        <!--
                        <div class="product-navigation__button pointer">
                            <a [attr.href]="this.item.href">Подробнее</a>
                        </div>
                        -->
                        
                        <p class="product-navigation__service">{{this.item.type}}</p>
                    </div>
                    <p class="product-description">
                        {{this.item.text}}
                    </p>
                </div>
            
            
            </div>
        </div>

    </div>
</div>

