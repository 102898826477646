<div class="wrapper">
    <div class="group-logo">
        <div class="logo-ta">
            <app-icon style="height: 60px;" name="Tripadvanse-logo" size ="60" color="#ED3E3E"></app-icon>
            <div class="logo-text">
                <h5>1С:Франчайзи</h5>
                <h5>Комплекс решений для отелей</h5>
            </div>
        </div>
        <div class="logo-1c">
            <app-icon style="height: 80px;" name="Logo-1C" size ="80" color="#ED3E3E"></app-icon>
            <div class="logo-text">
                <h5>Аттестованны франчайзи фирмы "1С"</h5>
                <h5>№ 098-09-09-09-09</h5>
                <p>ISO / Маркировка / Сертифицированные сотрудники</p>
            </div>
        </div>
    </div>
    <div class="company">
        <h1 class="company-title desktop">КОМПАНИЯ "ООО ТРИП АДВАНСЕ"</h1>
        <h1 class="company-title mobile">КОМПАНИЯ</h1>
        <h1 class="company-title mobile">"ООО ТРИП АДВАНСЕ"</h1>
        
        <a href="/company" class="company-detail link">ПОДРОБНЕЕ О НАШЕЙ КОМПАНИИ</a>
        <p class="company-text company-text__margin-top">За каждым достижением стоит команда</p>
        <p class="company-text company-text__margin-top">Наша компания занимается разработкой и поддержкой программного обеспечения на базе платформы 1C:Отель. Мы предоставляем полный спектр услуг от разработки и внедрения до сопровождения и поддержки. Имеем большой опыт в области автоматизации бизнес-процессов и готовы предложить нашим клиентам индивидуальные решения. Наши специалисты имеют высокую квалификацию и опыт работы в сфере 1C, что позволяет нам быстро и качественно выполнять задачи любой сложности. Кроме того, мы предлагаем гибкие условия сотрудничества и индивидуальный подход к каждому клиенту.</p>
        <p class="company-text">На сегодняшний день, за нашими плечами есть собственные разработки и сложные интеграции, которые успешно были внедрены в действующие рабочие процессы, вот некоторые из них: Модуль бронирования, программирование карт для гостей, интеграция с электронными замками и шлагбаумами, двухсторонний обмен с CRM Битрикс 24</p>
    
        <p class="company-text company-text__margin-top">Уважаемые партнеры!</p>
        <p class="company-text">Мы рады предложить вам сотрудничество в рамках нашего общего проекта. Мы уверены, что наш опыт и профессионализм позволят нам достичь общих целей и предоставить нашим клиентам наилучший сервис. Со своей стороны, также гарантируем высокое качество наших услуг и индивидуальный подход к каждому клиенту. Мы ценим ваше время и готовы работать с вами на всех этапах сотрудничества</p>
    
    </div>
</div>
