import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ViewChildren, ContentChildren, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { SliderAnimationType } from '../../ui/slider/slider.enum';
import { SliderComponent } from '../../ui/slider/slider.component';
import { SlideComponent } from '../../ui/slide/slide.component';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { SiteService } from '../../site.service';

@Component({
  selector: 'app-slider-full-width',
  templateUrl: './slider-full-width.component.html',
  styleUrls: ['./slider-full-width.component.scss']
})
export class SliderFullWidthComponent {
  @Input() item:any = {}; 
  @Input() sizeTitle:any = 18;
  @Input() height:any = "auto"; 
  @Input() padding:any = "6px 24px 0px 24px";

  @ViewChild(SliderComponent) slider!: SliderComponent;
  @ContentChildren(SlideComponent, { read: ElementRef }) elSliders!: any;
  
  public animationType: SliderAnimationType = SliderAnimationType.EaseInOutSine;
  public animationDuration: number = 500;

  constructor(public site: SiteService){}

  productNext(){
    this.slider.next();
  }

  productPrev(){
    this.slider.prev();
  }
}
