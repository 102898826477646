import { Component, Input,  ElementRef, AfterViewInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-element-baner-form',
  templateUrl: './element-baner-form.component.html',
  styleUrls: ['./element-baner-form.component.scss']
})
export class ElementBanerFormComponent {
  @Input() item:any = {};
  @Input() item2:any = {};
  @Input() item3:any = {};
  @Input() item4:any = {};

  public currentPage:any = undefined;
  public currentPageTwo:any = undefined;
  public currentPageTree:any = undefined;
  public currentPageFour:any = undefined;

  @Input() inputTitle:any =[] 
  @Input() inputTitleTwo:any =[] 
  @Input() inputTitleTree:any =[] 
  @Input() inputTitleFour:any =[] 



  phone: string | undefined;
  constructor(){
    this.phone = ''; // Или другое начальное значение
   
  }
  @ViewChild('targetElement') targetElement!: ElementRef<HTMLElement>;
  isActive = false;

  toggleClass() { 
    this.isActive = true;

    // Устанавливаем таймер для удаления класса через 1 секунду
    setTimeout(() => {
      this.isActive = false;
    }, 5000);
  }


  ngOnInit(): void{
    this.phoneForm = new FormGroup({
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$')
      ])
    });
  }
  phoneForm!: FormGroup;

  onKeyUp(event: KeyboardEvent) {
     const target = event.target as HTMLInputElement;
     let value = target.value.replace(/[^0-9]/g, ''); // Убираем все символы кроме цифр
 
     if (value.length === 10 || value.length === 11) {
       value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
     } else if (value.length >= 12) {
       value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
     }
 
     target.value = value;
   }
  onChange(item:any){
    this.item2.topTwo=false;
    item.top = !item.top;
  }
  onChangeTwo(item2:any){
    this.item.top=false;
    item2.topTwo = !item2.topTwo;
  }

  onChangeTree(item3:any){
    if(item3 == this.currentPageTree){
      return;
    }
    item3.topTree = !item3.topTree;
    this.currentPageTree = item3;

    if(item3.topTree){
      for(let i of this.inputTitleTree){
        if(i != item3)
          i.topTree = false;
      }
   
    }
  }

 
 
} 
