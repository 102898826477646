import { Component } from '@angular/core';

@Component({
  selector: 'app-services-escort-ut',
  templateUrl: './services-escort-ut.component.html',
  styleUrls: ['./services-escort-ut.component.scss']
})
export class ServicesEscortUtComponent {

}
