import { Component } from '@angular/core';

@Component({
  selector: 'app-programms',
  templateUrl: './programms.component.html',
  styleUrls: ['./programms.component.scss']
})
export class ProgrammsComponent {

}
