import { Component } from '@angular/core';

@Component({
  selector: 'app-booking-module',
  templateUrl: './booking-module.component.html',
  styleUrls: ['./booking-module.component.scss']
})
export class BookingModuleComponent {

}
