<div class="wrapp">
    <div class="button">
       <app-red-arrow  (onClick)="($event == 1) ? this.productNext() : this.productPrev()">
       </app-red-arrow > 
    </div> 

    <div class="wrapp-slider decktop-slider">
        <app-slider [type]="this.animationType" [countView]="this.getCountProductView()" [duration]="400">
            
            <app-slide *ngFor="let item of this.site.elements.partnersSlider">
                
                <div class="slider__cont-icon">
                    <img class="slider__icon"  [src]="this.item"/> 
                </div>
                  
            </app-slide> 
                        
        </app-slider>
    </div>
</div>
