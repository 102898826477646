
<div style="width: 100%; margin-top: 40px;" >

    <div style="width: 90%; margin: auto; margin-bottom: 100px;">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
    <div style="width: 90%; margin: auto;">
      <app-element-product-text
       [item]="this.page.text1">
      </app-element-product-text>  
   </div>
 
   <div class="margins-section-tag">
    <app-element-tags [item]='[
         { text:"Модуль бронирования"},
         { text:"Обучение 1С:Отель с нуля"},
         { text:"1С:Отель + Битрикс24"},
         { text:"1С: Отель отчеты"},
         { text:"Лицензии"},
         { text:"Программа 1С: Отель"},
         { text:"1С + кассы онлайн"},
         { text:"Разработка печатных форм"},
         { text:"Услуги программиста по 1С: Отель"},
       ]'>
       </app-element-tags> 
   </div>
 
    <div class="margins-section">
       <app-accordion-all-pages ngSkipHydration *ngIf="this.page.accordion!=undefined"
          [accordions]="this.page.accordion">
       </app-accordion-all-pages>
    </div>
    <div>
       <app-element-packages [item]="this.page.packages">
       </app-element-packages>
     </div>
    
     <div class="margins-section-accordion2"> 
        <div class="margins-section-accordion2__element">
           <app-accordion-all-pages-two ngSkipHydration *ngIf="this.page.accordion!=undefined"
             [accordionsT]="this.page.accordion2"></app-accordion-all-pages-two>
        </div>
    </div>  
 
    <div class="margins-section-footer">
       <app-footer></app-footer> 
     </div> 
 </div>

<div class="forms-style" [class]="bgOpen">
   <div class="forms-style-child" >
     <app-modal-forms ></app-modal-forms> <!-- для открытия формы поставить в тег - [class]="backgroundClass"-->
   </div>
</div>


<app-popup-form></app-popup-form>

   