import { Component, Input  } from '@angular/core';
import { SiteService } from '../../site.service';

@Component({
  selector: 'app-footer-menu',
  templateUrl: './footer-menu.component.html',
  styleUrls: ['./footer-menu.component.scss']
})
export class FooterMenuComponent { 
  @Input() item:any = {};
  public currentPage:any = undefined;
  @Input() accordions:any =[]
 
  constructor(
     public site:SiteService,
  ) {
    this.accordions = site.menuMobile;
  }
  ngOnInit(){
    if(this.accordions.length>0)
      this.currentPage = this.accordions[0];
  }

  onChange(item:any, parent:any, close:any){

   // if(item == this.currentPage){
   //   return;
   // }
   var expanded = !item.expanded;
 
    
    if(expanded){
      if(close){
        this.closeAll();
      }

      item.expanded = expanded;
      for(let i of parent){
        if(i != item)
          i.expanded = false;
      }
    }
  }

  closeAll(){
    for(let item of this.accordions){
      for(let item2 of item.items){
        item.expanded = false;
        for(let item3 of item2.items){
          item2.expanded = false;
        }
      }
    }
  }
}
