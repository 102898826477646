import { Component } from '@angular/core';

@Component({
  selector: 'app-services-escort-bp',
  templateUrl: './services-escort-bp.component.html',
  styleUrls: ['./services-escort-bp.component.scss']
})
export class ServicesEscortBpComponent {

}
