import { Component,Input  } from '@angular/core';

@Component({
  selector: 'app-mini-banner',
  templateUrl: './mini-banner.component.html',
  styleUrls: ['./mini-banner.component.scss']
})
export class MiniBannerComponent {
  @Input() item:any = {};
  ngOnInit(){
    console.log(this.item);
  }
}
