<app-services-escort
    title = "1С:Бухгалтерия - Сопровождение"
    description = "Проведем аудит и предложим правильное программное обеспечивание, как в базовой конфигурации, так и в индивидуальной. Внедрим и настроим программы на любом
    этапе, обучим персонал и закроем белые пятна в работе. Настроим сложные интеграции и создадим структуру по обмену данных. Разработаем новые печатные формы и
    отчеты. Синхронизируем кассы онлайн, интернет эквайринг. Осуществляем базовую и расширенную техническую поддержку"
>
    <app-panel>
        <app-panel-page
            title='Сопровождение 1С:Бухгалтерия
                   Пакет "Базовый"'
        >

        <div >
            <p style="padding: 24px;">Наша команда специалистов готова помочь вам настроить систему под ваши потребности и обеспечить ее поддержку на
                всех этапах использования. Мы гарантируем быстрое внедрение и обучение вашей команды работе с 1С Отель.
                Не упустите возможность улучшить работу вашей гостиницы и повысить уровень сервиса для ваших гостей!</p>

        </div>

        </app-panel-page>
        <app-panel-page
            title='Сопровождение 1С:Бухгалтерия
            Пакет "Расширенный"'
        >

        <div>
            

        </div>
        </app-panel-page>

        <app-panel-page
            title='Сопровождение 1С:Бухгалтерия
            Пакет "Полный"'
        >


        <div>
            

        </div>

        </app-panel-page>
        
    </app-panel>
</app-services-escort>
