<div class="block" style="height:100%">
    <div class="block-content">
        <img #image [src]="this.item.image" style="max-height:550px" class="image" alt="lorem" [style.height]="this.heightImage"/>
        <div class="block-title">
            <h3>{{this.item.title}}</h3>
            <h3>{{this.item.type}}</h3> 
        </div>
    </div>

    <div  class="block-description" style="height:100%">
        <app-element-minimize class="minimize-wrapper" style="height:100%; max-height: 120px;" height="100%" [item]="{
            description: item.description,
            href: item.href,
        }"></app-element-minimize>
    </div>


</div>