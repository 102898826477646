<!--модуль описание-->
<div>
    <!--<h2 class="link" >{{this.link}}</h2>-->
    <h2 class="title">{{this.title}}</h2>
    <p class="subtitle">{{this.subtitle}}</p>
    <div class="text__block">
        
       <ng-content></ng-content> 

       
    </div>
</div>
