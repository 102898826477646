import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion-all-pages',
  templateUrl: './accordion-all-pages.component.html',
  styleUrls: ['./accordion-all-pages.component.scss']
})
export class AccordionAllPagesComponent {
  @Input() item:any = {};
  public currentPage:any = undefined;
  @Input() accordions:any =[] 
 
  constructor(){

  }
  ngOnInit(){
    if(this.accordions.length>0)
      this.currentPage = this.accordions[0];
  }

  onChange(item:any){
    if(item == this.currentPage){
      return;
    }
    item.expanded = !item.expanded;
    this.currentPage = item;

    if(item.expanded){
      for(let i of this.accordions){
        if(i != item)
          i.expanded = false;
      }
    }
  }
}
