import { Component } from '@angular/core';

@Component({
  selector: 'app-services-fresh',
  templateUrl: './services-fresh.component.html',
  styleUrls: ['./services-fresh.component.scss']
})
export class ServicesFreshComponent {

}
