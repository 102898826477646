import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {provideClientHydration} from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { IconComponent } from './ui/icon/icon.component';
import { MenuComponent } from './menu/menu.component';
import { ArrowComponent } from './ui/arrow/arrow.component';
import { SelectComponent } from './ui/select/select.component';
import { SearchComponent } from './ui/search/search.component';
import { MainBanerComponent } from './main-baner/main-baner.component';
import { SliderComponent } from './ui/slider/slider.component';
import { SliderAnimationType } from './ui/slider/slider.enum';
import { SlideComponent } from './ui/slide/slide.component';
import { MainComponent } from './pages/main/main.component';
import { MainDecisionsComponent } from './main-decisions/main-decisions.component';
import { MainContentComponent } from './main-content/main-content.component';
import { MainPresentComponent } from './main-present/main-present.component';
import { ProductComponent } from './product/product.component';
import { MainProductsComponent } from './main-products/main-products.component';
import { MainChaptersComponent } from './main-chapters/main-chapters.component';
import { ButtonComponent } from './ui/button/button.component';
import { ButtonDetailComponent } from './ui/button-detail/button-detail.component';
import { MainIntegrationsComponent } from './main-integrations/main-integrations.component';
import { MainPartnersComponent } from './main-partners/main-partners.component';
import { MainCompanyComponent } from './main-company/main-company.component';
import { MainNewsComponent } from './main-news/main-news.component';
import { FooterComponent } from './footer/footer.component';
import { CollapseComponent } from './ui/collapse/collapse.component';
import { MainPacketComponent } from './main-packet/main-packet.component';
import { MainPacketBlockComponent } from './main-packet-block/main-packet-block.component';
import { ProductsComponent } from './pages/products/products.component';
import { CompanyComponent } from './pages/company/company.component';
import { BookingModuleComponent } from './pages/booking-module/booking-module.component';
import { ServicesComponent } from './pages/services/services.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { ProgrammsComponent } from './pages/programms/programms.component';
import { DevelopmentsComponent } from './pages/developments/developments.component';
import { IntegrationsComponent } from './pages/integrations/integrations.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { ServicesIntegrationHotelComponent } from './pages/services-integration-hotel/services-integration-hotel.component';
import { ProductDecisionComponent } from './product-decision/product-decision.component';
import { ProductMinimizeComponent } from './product-minimize/product-minimize.component';
import { PanelComponent } from './ui/panel/panel.component';
import { PanelPageComponent } from './ui/panel-page/panel-page.component';
import { ServicesIntegrationBitrix24Component } from './pages/services-integration-bitrix24/services-integration-bitrix24.component';
import { ServicesIntegrationComponent } from './pages/services-integration/services-integration.component';
import { ServicesIntegrationTraktirComponent } from './pages/services-integration-traktir/services-integration-traktir.component';
import { ServicesIntegrationBpComponent } from './pages/services-integration-bp/services-integration-bp.component';
import { ServicesIntegrationUtComponent } from './pages/services-integration-ut/services-integration-ut.component';
import { ServicesIntegrationRoznicaComponent } from './pages/services-integration-roznica/services-integration-roznica.component';
import { ServicesIntegrationBookingModuleComponent } from './pages/services-integration-booking-module/services-integration-booking-module.component';
import { DevelopmentsBookingModuleComponent } from './pages/developments-booking-module/developments-booking-module.component';
import { DevelopmentsUkdComponent } from './pages/developments-ukd/developments-ukd.component';
import { ServicesEscortHotelComponent } from './pages/services-escort-hotel/services-escort-hotel.component';
import { ServicesEscortBpComponent } from './pages/services-escort-bp/services-escort-bp.component';
import { ServicesEscortUtComponent } from './pages/services-escort-ut/services-escort-ut.component';
import { ServicesEscortComponent } from './pages/services-escort/services-escort.component';
import { ServicesProgrammingComponent } from './pages/services-programming/services-programming.component';
import { ServicesProgrammingPrintedFormsComponent } from './pages/services-programming-printed-forms/services-programming-printed-forms.component';
import { ServicesProgrammingReportsComponent } from './pages/services-programming-reports/services-programming-reports.component';
import { ServicesProgrammingExtensionsComponent } from './pages/services-programming-extensions/services-programming-extensions.component';
import { ServicesProgrammingProcessingComponent } from './pages/services-programming-processing/services-programming-processing.component';
import { TrainingComponent } from './pages/training/training.component';
import { TrainingIndividualComponent } from './pages/training-individual/training-individual.component';
import { TrainingClassComponent } from './pages/training-class/training-class.component';
import { TrainingOnlineComponent } from './pages/training-online/training-online.component';
import { FiscalStorageComponent } from './pages/fiscal-storage/fiscal-storage.component';

import { PageContentComponent } from './pages/page-content/page-content.component';
import { ServicesFreshComponent } from './pages/services-fresh/services-fresh.component';
import { ServicesGrmComponent } from './pages/services-grm/services-grm.component';
import { DevicesCashRegistersComponent } from './pages/devices-cash-registers/devices-cash-registers.component';
import { BlockProgrammingComponent } from './blocks/block-programming/block-programming.component';

import { ElementYellowComponent } from './elements/element-yellow/element-yellow.component';
import { ElementMinimizeComponent } from './elements/element-minimize/element-minimize.component';
import { ElementDicisionComponent } from './elements/element-dicision/element-dicision.component';
import { ElementBreadcrumbsComponent } from './elements/element-breadcrumbs/element-breadcrumbs.component';
import { ElementPresentComponent } from './elements/element-present/element-present.component';
import { TestComponent } from './test/test.component';
import { ElementChapterComponent } from './elements/element-chapter/element-chapter.component';
import { ElementTagsComponent } from './elements/element-tags/element-tags.component';
import { DevicesHotelComponent } from './pages/devices-hotel/devices-hotel.component';
import { ElementProductBanerComponent } from './elements/element-product-baner/element-product-baner.component';
import { ElementProductTextComponent } from './elements/element-product-text/element-product-text.component';
import { ElementProductRowComponent } from './elements/element-product-row/element-product-row.component';
import { ElementProductListComponent } from './elements/element-product-list/element-product-list.component';
import { ElementPackagesComponent } from './elements/element-packages/element-packages.component';
import { ButtonMobileComponent } from './ui/button-mobile/button-mobile.component';
import { ElementProductItemComponent } from './elements/element-product-item/element-product-item.component';
import { ButtonSliderComponent } from './ui/button-slider/button-slider.component';
import { PriceComponent } from './ui/price/price.component';
import { CheckboxComponent } from './ui/checkbox/checkbox.component';
import { SpinnerComponent } from './ui/spinner/spinner.component';

import { DescriptionComponent } from './elements/description/description.component';
import { StikyHeaderComponent } from './elements/stiky-header/stiky-header.component';
import { MenuBigComponent } from './elements/menu-big/menu-big.component';
import { RedArrowComponent } from './ui/red-arrow/red-arrow.component';
import { MainPresentTwoComponent } from './elements/main-present-two/main-present-two.component';
import { BannerBottomAnimationComponent } from './elements/banner-bottom-animation/banner-bottom-animation.component';
import { ButtonSliderWihteComponent } from './elements/button-slider-wihte/button-slider-wihte.component';
import { ElementSlidesBottomAnimationComponent } from './elements/element-slides-bottom-animation/element-slides-bottom-animation.component';
import { DiscriptionsComponent } from './elements/discriptions/discriptions.component';
import { BannerContentComponent } from './elements/banner-content/banner-content.component';
import { ElementPartnersComponent } from './elements/element-partners/element-partners.component';
import { ParalelScrollComponent } from './elements/paralel-scroll/paralel-scroll.component';
import { BlockProductsTwoComponent } from './elements/block-products-two/block-products-two.component';
import { AccordionAllPagesComponent } from './elements/accordion-all-pages/accordion-all-pages.component';
import { SearchDarkComponent } from './ui/search-dark/search-dark.component';
import { FootnoteComponent } from './elements/footnote/footnote.component';
//тестовые кнопки для модуля

import { ServicesProgrammingLicenziiComponent } from './pages/services-programming-licenzii/services-programming-licenzii.component';

import { AccordionAllPagesTwoComponent } from './elements/accordion-all-pages-two/accordion-all-pages-two.component';
import { MenuAccordionMobileComponent } from './elements/menu-accordion-mobile/menu-accordion-mobile.component';
import { MenuAccordionElementComponent } from './elements/menu-accordion-element/menu-accordion-element.component';
import { ONasComponent } from './pages/o-nas/o-nas.component';
import { PhotoGallereyComponent } from './elements/photo-gallerey/photo-gallerey.component';
import { DiscriptionRowComponent } from './elements/discription-row/discription-row.component';
import { DisriptonBannerRowComponent } from './elements/disripton-banner-row/disripton-banner-row.component';
import { CardSlidesComponent } from './elements/card-slides/card-slides.component';
import { MiniGrinArrowComponent } from './ui/mini-grin-arrow/mini-grin-arrow.component';
import { ElementSlideCardComponent } from './elements/element-slide-card/element-slide-card.component';
import { ElementCardMarketingComponent } from './elements/element-card-marketing/element-card-marketing.component';
import { FooterMenuComponent } from './elements/footer-menu/footer-menu.component';
import { ModalWindowComponent } from './elements/modal-window/modal-window.component';
import { PriceTopComponent } from './elements/price-top/price-top.component';
import { RoundSpanComponent } from './elements/round-span/round-span.component';
import { PopupInformationComponent } from './elements/popup-information/popup-information.component';
import { MiniBannerComponent } from './elements/mini-banner/mini-banner.component';
import { TextContentComponent } from './elements/text-content/text-content.component';
import { ElementBanerFormComponent } from './elements/element-baner-form/element-baner-form.component';
import { CrossToogleComponent } from './ui/cross-toogle/cross-toogle.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { BannerPartnerComponent } from './elements/banner-partner/banner-partner.component';
import { CardComponent } from './ui/card/card.component';
import { SliderFullWidthComponent } from './elements/slider-full-width/slider-full-width.component';
import { PoliticsConfendialityComponent } from './pages/politics-confendiality/politics-confendiality.component';
import { ElementFullWSliderComponent } from './elements/element-full-w-slider/element-full-w-slider.component';
import { TehStackComponent } from './pages/teh-stack/teh-stack.component';
import { TrainingOneCHotelComponent } from './pages/training-one-c-hotel/training-one-c-hotel.component';
import { EscortOneCHotelComponent } from './pages/escort-one-c-hotel/escort-one-c-hotel.component';
import { MarcetongCardsTwoComponent } from './elements/marcetong-cards-two/marcetong-cards-two.component';
import { ContentImgLeftComponent } from './elements/content-img-left/content-img-left.component';
import { ContentImgRihtComponent } from './elements/content-img-riht/content-img-riht.component';
import { PopupFormComponent } from './elements/popup-form/popup-form.component';
import { CheckedFomsComponent } from './ui/checked-foms/checked-foms.component';
import { ButtonFullWithComponent } from './ui/button-full-with/button-full-with.component';
import { ModalFormsComponent } from './elements/modal-forms/modal-forms.component';
import { ModalFormsLowerComponent } from './elements/modal-forms-lower/modal-forms-lower.component';
import { ModalFormsUpperComponent } from './elements/modal-forms-upper/modal-forms-upper.component';
import { ToggleService } from './servises/toggle.service'; 
import { ReactiveFormsModule } from '@angular/forms'; // Добавьте этот импорт
import { InputPhoneComponent } from './ui/input-phone/input-phone.component';
import { EquipmentRegistrationFnsComponent } from './pages/equipment-registration-fns/equipment-registration-fns.component';
import { ListTitleComponent } from './elements/list-title/list-title.component';
import { PanelInformationComponent } from './elements/panel-information/panel-information.component';
import { UslugiComponent } from './elements/uslugi/uslugi.component';
import { EquipmentConnectionOfdComponent } from './pages/equipment-connection-ofd/equipment-connection-ofd.component';
import { EquipmentCustomizationKkmComponent } from './pages/equipment-customization-kkm/equipment-customization-kkm.component';
import { EquipmentConnectionOneCComponent } from './pages/equipment-connection-one-c/equipment-connection-one-c.component';
import { ProgramsConnectionEgaisComponent } from './pages/programs-connection-egais/programs-connection-egais.component';
import { ProgramsConnectionChestnuiZnakComponent } from './pages/programs-connection-chestnui-znak/programs-connection-chestnui-znak.component';
import { ProgramsConnectionEdoComponent } from './pages/programs-connection-edo/programs-connection-edo.component';
import { ProgramsConnectionUtmComponent } from './pages/programs-connection-utm/programs-connection-utm.component';



@NgModule({ 
  declarations: [
    ProgramsConnectionUtmComponent,
    ProgramsConnectionEdoComponent,
    ProgramsConnectionChestnuiZnakComponent,
    ProgramsConnectionEgaisComponent,
    EquipmentConnectionOneCComponent,
    EquipmentCustomizationKkmComponent,
    EquipmentConnectionOfdComponent,
    UslugiComponent,
    PanelInformationComponent,
    ListTitleComponent,
    EquipmentRegistrationFnsComponent,
    InputPhoneComponent,
    ModalFormsComponent,
    ModalFormsLowerComponent,
    ModalFormsUpperComponent,
    ButtonFullWithComponent,
    CheckedFomsComponent,
    PopupFormComponent,
    ContentImgLeftComponent,
    ContentImgRihtComponent,
    MarcetongCardsTwoComponent,
    EscortOneCHotelComponent,
    TrainingOneCHotelComponent,
    TehStackComponent,
    ElementFullWSliderComponent,
    PoliticsConfendialityComponent,
    SliderFullWidthComponent,
    CardComponent,
    BannerPartnerComponent,
    PartnersComponent,
    CrossToogleComponent,
    ElementBanerFormComponent,
    TextContentComponent,
    MiniBannerComponent,
    PopupInformationComponent,
    RoundSpanComponent,
    PriceTopComponent,
    ModalWindowComponent,
    FooterMenuComponent,
    ElementCardMarketingComponent,
    ElementSlideCardComponent,
    MiniGrinArrowComponent,
    CardSlidesComponent,
    DisriptonBannerRowComponent,
    DiscriptionRowComponent,
    PhotoGallereyComponent,
    ONasComponent,
    MenuAccordionElementComponent,
    MenuAccordionMobileComponent,
    AccordionAllPagesTwoComponent,
    ServicesProgrammingLicenziiComponent,
    FootnoteComponent,
    SearchDarkComponent,
    AccordionAllPagesComponent, 
    FiscalStorageComponent,
    BlockProductsTwoComponent,
    ParalelScrollComponent,
    ElementPartnersComponent,
    BannerContentComponent,
    DiscriptionsComponent,
    ElementSlidesBottomAnimationComponent,
    ButtonSliderWihteComponent,
    BannerBottomAnimationComponent,
    MainPresentTwoComponent,
    RedArrowComponent,
    MenuBigComponent,
    StikyHeaderComponent,
    AppComponent,
    HeaderComponent,
    IconComponent,
    MenuComponent,
    ArrowComponent,
    SelectComponent,
    SearchComponent,
    MainBanerComponent,
    SliderComponent,
    SlideComponent,
    MainComponent,
    MainDecisionsComponent,
    MainContentComponent,
    MainPresentComponent,
    ProductComponent,
    MainProductsComponent,
    MainChaptersComponent,
    ButtonComponent,
    ButtonDetailComponent,
    MainIntegrationsComponent,
    MainPartnersComponent,
    MainCompanyComponent,
    MainNewsComponent,
    FooterComponent,
    CollapseComponent,
    MainPacketComponent,
    MainPacketBlockComponent,
    ProductsComponent,
    CompanyComponent,
    BookingModuleComponent,
    ServicesComponent,
    DevicesComponent,
    ProgrammsComponent,
    DevelopmentsComponent,
    IntegrationsComponent,
    ContactsComponent,
    LeftMenuComponent,
    ServicesIntegrationHotelComponent,
    ProductDecisionComponent,
    ProductMinimizeComponent,
    PanelComponent,
    PanelPageComponent,
    ServicesIntegrationBitrix24Component,
    ServicesIntegrationComponent,
    ServicesIntegrationTraktirComponent,
    ServicesIntegrationBpComponent,
    ServicesIntegrationUtComponent,
    ServicesIntegrationRoznicaComponent,
    ServicesIntegrationBookingModuleComponent,
    DevelopmentsBookingModuleComponent,
    DevelopmentsUkdComponent,
    ServicesEscortHotelComponent,
    ServicesEscortBpComponent,
    ServicesEscortUtComponent,
    ServicesEscortComponent,
    ServicesProgrammingComponent,
    ServicesProgrammingPrintedFormsComponent,
    ServicesProgrammingReportsComponent,
    ServicesProgrammingExtensionsComponent,
    ServicesProgrammingProcessingComponent,
    TrainingComponent,
    TrainingIndividualComponent,
    TrainingClassComponent,
    TrainingOnlineComponent,
    PageContentComponent,
    ServicesFreshComponent,
    ServicesGrmComponent,
    DevicesCashRegistersComponent,
    BlockProgrammingComponent,
    ElementYellowComponent,
    ElementMinimizeComponent,
    ElementDicisionComponent,
    ElementBreadcrumbsComponent,
    ElementPresentComponent,
    TestComponent,
    ElementChapterComponent,
    ElementTagsComponent,
    DevicesHotelComponent,
    ElementProductBanerComponent,
    ElementProductTextComponent,
    ElementProductRowComponent,
    ElementProductListComponent,
    ElementPackagesComponent,
    ButtonMobileComponent,
    ElementProductItemComponent,
    ButtonSliderComponent,
    PriceComponent,
    CheckboxComponent,
    SpinnerComponent,
    DescriptionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule // Добавьте этот модуль сюда
  ],
  providers: [ provideClientHydration(),
    ToggleService
   ],
  bootstrap: [
    AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
 }
 