<div class="wrapper">
  <div class="left" *ngFor="let item of this.accordions" >
      <div class="btn"  (click)="this.onChange(item, this.accordions, true)">
          <div>
            <h1 class="btn-title">{{item.title}}
              <img class="icon" src="assets/header/right-arrow.svg" [class.arrow-actual]="item.expanded==true"/>
            </h1>
          </div>
       </div>
       <app-collapse [expanded]="item.expanded"> 
          <div class="line">

               <div *ngFor="let item2 of item.items">
                <div class="btn"  (click)="this.onChange(item2, item.items, false)">
                  <div>
                    <h1 class="btn-subtitle">
                      <img class="icon2" src="assets/header/right-arrow.svg"/>
                      {{item2.title}}</h1>
                  </div>
               </div>

                <app-collapse [expanded]="item2.expanded">
                  <div class="line">
                    <div class="btn-subtitle3-contenier" *ngFor="let item3 of item2.items">
                      <a class="btn-subtitle3" (click)="this.navigateTo(item3.href)" rel="nofollow" [attr.href]="item3.href">{{item3.title}} </a> 
                    </div>
                  </div>

                </app-collapse>



<!--
                    <app-collapse [expanded]="item2.expanded"> 
                    <div class="line">
          
                        <div *ngFor="let item3 of item2.items">
                          <h1 class="btn-title">{{item3.title}} </h1>
          
          
          
          
                          
                        </div>
                        
                    </div> 
                </app-collapse>              
-->



               </div>
               
          </div> 
       </app-collapse>
  </div> 
  
</div>