
<div  class="wrapper">

    <app-slider  [type]="this.animationType" [duration]="this.animationDuration" [autoPlayDelay]="10000" (onChange)="this.changeSlide($event)"> <!--(onAnimate)="this.sliderAnimate($event)-->
        <app-slide>
            <div class="banner">
                <img src="../../assets/main/Banner 1v4.jpg" class="banner-image" alt="lorem">
                <!--<div class="wrapper-text">
                    <div class="left">
                        <div class="title desktop">
                            <div class="title_row">
                                <h1 class="title_text1">КОМФОРТ В РАБОТЕ НА</h1>
                            </div>
                            <div class="title_row">
                                <h1 class="title_text2">*ЯЗЫКЕ</h1>
                                <h1 class="title_text3">1С:ОТЕЛЬ В 2023</h1>
                            </div>
                        </div>
            
                        <div class="title mobile">
                            <div class="title_row">
                                <h1 class="title_text1">КОМФОРТ В</h1>
                            </div>
                            <div class="title_row">
                                <h1 class="title_text2">РАБОТЕ НА *ЯЗЫКЕ</h1>
                            </div>
                            <div class="title_row">
                                <h1 class="title_text3">1С:ОТЕЛЬ</h1>
                            </div>
                        </div>
                
                        <div class="title2">
                            <h1 class="title2_text">Внедрение</h1>
                            <h1 class="title2_text">Сопровождение</h1>
                            <h1 class="title2_text">Интеграции</h1>
                        </div>
                
                        <div class="title3 desktop">
                            <h1 class="title3_text">Пакетные решения</h1> 
                            <div class="title3_separator"></div>
                            <h1 class="title3_text">Разработки</h1> 
                        </div>
                
                        <div class="button pointer">
                            <app-button>ПОДРОБНЕЕ</app-button>
                    
                        </div>
                    </div>
                    <div class="right">
                        <div class="right-background"></div>
                        <div class="right-block-text">
            
                            <h4 class="right_text1">Расширение возможностей</h4>
                            <h4 class="right_text1">уже сегодня!</h4>
                
                            <h2 class="right_text2" style="margin-top:24px">Выгрузка броней с CRM</h2>
                            <h2 class="right_text2">Интеграция эл. замков</h2>
                            <h2 class="right_text2">Модуль бронирования</h2>
                            
                            <h4 class="right_text1" style="margin-top:24px">TDD разработка</h4>
                            <h4 class="right_text1" style="margin-top:18px">Программирование касс</h4>
                            <h4 class="right_text1">Нетиповые конфигурации 1С</h4>
                            <h4 class="right_text1">Контроль доступа в отеле</h4>
            
                            <div class="right-icon" style="margin-top: 36px;">
                                <app-icon style="height: 54px; display: block;" name="Logo-1C" size ="64" color="white"></app-icon>
                                <h4 class="right_text1">ФОРМЫ / ОТЧЕТЫ</h4>
                            </div>
                        </div>
                    </div>  
                </div>-->
        
            </div>
        </app-slide>
        <app-slide>
            <div class="banner">
                <img src="../../assets/main/Banner 1C 2V3.jpg" class="banner-image" alt="lorem">
               <!-- <div class="wrapper-text">
                    <div class="left">
                        <div class="title desktop">
                            <div class="title_row">
                                <h1 class="title_text1">КОМФОРТ В РАБОТЕ НА</h1>
                            </div>
                            <div class="title_row">
                                <h1 class="title_text2">*ЯЗЫКЕ</h1>
                                <h1 class="title_text3">БАНЕР 2</h1>
                            </div>
                        </div>
            
                        <div class="title mobile">
                            <div class="title_row">
                                <h1 class="title_text1">КОМФОРТ В</h1>
                            </div>
                            <div class="title_row">
                                <h1 class="title_text2">РАБОТЕ НА *ЯЗЫКЕ</h1>
                            </div>
                            <div class="title_row">
                                <h1 class="title_text3">1С:ОТЕЛЬ</h1>
                            </div>
                        </div>
                
                        <div class="title2">
                            <h1 class="title2_text">Внедрение</h1>
                            <h1 class="title2_text">Сопровождение</h1>
                            <h1 class="title2_text">Интеграции</h1>
                        </div>
                
                        <div class="title3 desktop">
                            <h1 class="title3_text">Пакетные решения</h1> 
                            <div class="title3_separator"></div>
                            <h1 class="title3_text">Разработки</h1> 
                        </div>
                
                        <div class="button pointer">
                            <app-button>ПОДРОБНЕЕ</app-button>
                        </div>
                    </div>
                    <div class="right">
                        <div class="right-background"></div>
                        <div class="right-block-text">
            
                            <h4 class="right_text1">Расширение возможностей</h4>
                            <h4 class="right_text1">уже сегодня!</h4>
                
                            <h2 class="right_text2" style="margin-top:24px">Выгрузка броней с CRM</h2>
                            <h2 class="right_text2">Интеграция эл. замков</h2>
                            <h2 class="right_text2">Модуль бронирования</h2>
                            
                            <h4 class="right_text1" style="margin-top:24px">TDD разработка</h4>
                            <h4 class="right_text1" style="margin-top:18px">Программирование касс</h4>
                            <h4 class="right_text1">Нетиповые конфигурации 1С</h4>
                            <h4 class="right_text1">Контроль доступа в отеле</h4>
            
                            <div class="right-icon" style="margin-top: 36px;">
                                <app-icon style="height: 54px; display: block;" name="Logo-1C" size ="64" color="white"></app-icon>
                                <h4 class="right_text1">ФОРМЫ / ОТЧЕТЫ</h4>
                            </div>
                        </div>
                    </div>  
                </div>-->
        
            </div>
        </app-slide> 
        <app-slide>
            <div class="banner">
                <img src="../../assets/main/Bitrix New 2.jpg" class="banner-image" alt="lorem">
                <!--<div class="wrapper-text">
                    <div class="left">
                        <div class="title desktop">
                            <div class="title_row">
                                <h1 class="title_text1">КОМФОРТ В РАБОТЕ НА</h1>
                            </div>
                            <div class="title_row">
                                <h1 class="title_text2">*ЯЗЫКЕ</h1>
                                <h1 class="title_text3">БАНЕР 3</h1>
                            </div>
                        </div>
            
                        <div class="title mobile">
                            <div class="title_row">
                                <h1 class="title_text1">КОМФОРТ В</h1>
                            </div>
                            <div class="title_row">
                                <h1 class="title_text2">РАБОТЕ НА *ЯЗЫКЕ</h1>
                            </div>
                            <div class="title_row">
                                <h1 class="title_text3">1С:ОТЕЛЬ</h1>
                            </div>
                        </div>
                
                        <div class="title2">
                            <h1 class="title2_text">Внедрение</h1>
                            <h1 class="title2_text">Сопровождение</h1>
                            <h1 class="title2_text">Интеграции</h1>
                        </div>
                
                        <div class="title3 desktop">
                            <h1 class="title3_text">Пакетные решения</h1> 
                            <div class="title3_separator"></div>
                            <h1 class="title3_text">Разработки</h1> 
                        </div>
                
                        <div class="button pointer">
                            <app-button>ПОДРОБНЕЕ</app-button>
                        </div>
                    </div>
                    <div class="right">
                        <div class="right-background"></div>
                        <div class="right-block-text">
            
                            <h4 class="right_text1">Расширение возможностей</h4>
                            <h4 class="right_text1">уже сегодня!</h4>
                
                            <h2 class="right_text2" style="margin-top:24px">Выгрузка броней с CRM</h2>
                            <h2 class="right_text2">Интеграция эл. замков</h2>
                            <h2 class="right_text2">Модуль бронирования</h2>
                            
                            <h4 class="right_text1" style="margin-top:24px">TDD разработка</h4>
                            <h4 class="right_text1" style="margin-top:18px">Программирование касс</h4>
                            <h4 class="right_text1">Нетиповые конфигурации 1С</h4>
                            <h4 class="right_text1">Контроль доступа в отеле</h4>
            
                            <div class="right-icon" style="margin-top: 36px;">
                                <app-icon style="height: 54px; display: block;" name="Logo-1C" size ="64" color="white"></app-icon>
                                <h4 class="right_text1">ФОРМЫ / ОТЧЕТЫ</h4>
                            </div>
                        </div>
                    </div>  
                </div>-->
                        
            </div>
        </app-slide>     
    </app-slider>

    <div class="block-progress">
        <div class="counter">
            <p class="counter_item" [style.color]="this.getCounterColor(0)" (click)="this.setBaner(0)">&mdash;</p>
            <p class="counter_item" [style.color]="this.getCounterColor(1)" (click)="this.setBaner(1)">&mdash;</p>
            <p class="counter_item" [style.color]="this.getCounterColor(2)" (click)="this.setBaner(2)">&mdash;</p>
        </div>
        <div class="progress">
            <!--<div #progressBar class="progress-bar"></div>-->
        </div>
    </div>



</div>