import { Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class SiteService {

  constructor(@Inject(PLATFORM_ID) private platformId: object, private router: Router, private route: ActivatedRoute) { 
    
  }

  public menu:any = [
    {title: "Главная", href:"", items1:[]},
    {title: "Услуги", items:[
      {title: "Внедрение",  items:[
        {title: "Внедрение 1С:Отель", href:"services/integration/hotel", items:[], programming:[0,1,2]},
        {title: "Внедрение 1С:Трактиръ", href:"services/integration/traktir", items:[]},
        {title: "Внедрение iiKo", href:"/", items:[]},
        {title: "Внедрение Битрикс 24", href:"services/integration/bitrix24", items:[]},
      ]},
      {title: "Сопровождение", items:[
        {title: "Программа 1С:Отель", href:"services/escort-1c-hotel",items:[]},
        {title: "Программа 1С:Трактиръ", href:"/",items:[]},
        {title: "Программа iiKo", href:"/",items:[]},
        
      ]},
      {title: "Программирование", items:[
        {title: "Печатные формы", href:"/", items:[]},
        {title: "Обработки", href:"/", items:[]},
        {title: "Расширения", href:"/", items:[]},
        {title: "Конфигурации", href:"/", items:[]},
      ]},
      {title: "Обучение", items:[
        {title: "Обучение 1С:Отель", href:"training-1c-hotel", items:[]},
        {title: "Обучение Битрикс 24", href:"/", items:[]},
       
      ]},
      //{title: "1С:ФРЕШ", href:"services/fresh", items:[]},
      //{title: "1С:ГРМ", href:"services/grm", items:[]},
      
    ]},
    {title: "Оборудование", items2:[
      {title: "Онлайн-кассы",  items2:[
        {title: "Онлайн кассы", href:"devices/cash-registers", items:[]},
        {title: "Фискальный накопитель", href:"/", items:[]},
      ]},
      {title: "Сканеры", items2:[
        {title: "Сканеры штрих-кода", href:"/",items:[]},
      ]},
      {title: "Принтеры", items2:[
        {title: "Принтеры чеков", href:"/", items:[]},
      ]},
      {title: "POS оборудование", items2:[
        {title: "POS терминал", href:"/", items:[]},
      ]},
      {title: "Материалы", items2:[
        {title: "Чековая лента", href:"/", items:[]},
      ]},
      {title: "Замки", items2:[
        {title: "Электронные замки", href:"/", items:[]},
      ]},  
    ]},
    {title: "Оборудование", items3:[
      {image:"assets/header/equipment/Dop-v2.svg",title: "Услуги для касс-онлайн",  items3:[
        {title: "Регистрация ФНС", href:"equipment/online-sales-register/registration-fns", items:[]},
        {title: "Подключение к ОФД", href:"equipment/online-sales-register/connection-ofd", items:[]},
        {title: "Настройка ККМ", href:"equipment/online-sales-register/customization-kkm", items:[]},
        {title: "Подключение к 1С", href:"equipment/online-sales-register/connection-1C", items:[]},
      ]},
    ]},
    {title: "Программы", items4:[
      {title: "Продукты 1С",  items4:[
        {title: "1С: Отель", href:"/", items:[]},
        {title: "1С: Бухгалтерия", href:"/", items:[]},
        {title: "1С: Розница", href:"/", items:[]},
        {title: "1С:Трактиръ", href:"/", items:[]},
        {title: "Внедрение iiKo", href:"/", items:[]},
      ]},
      {title: "iiKo", items4:[
        {title: "Программа автоматизации", href:"/", items:[]},
        {title: "iiKoWaiter для официантов", href:"/", items:[]},
       
      ]},
      {title: "Битрикс", items4:[
        {title: "CRM Битрикс 24", href:"/",items:[]},
        {title: "Автоматизация", href:"/",items:[]},
      ]},
      {title: "Лицензирование", items4:[
        {title: "Лицензии", href:"services/programming/licenzii", items:[]},//нужно переименовать страницу
      ]},
      
      //{title: "1С:ФРЕШ", href:"services/fresh", items:[]},
      //{title: "1С:ГРМ", href:"services/grm", items:[]},
      
    ]},
    {title: "Программы", items5:[
      {image:"assets/header/programming/Dop Pv2.svg",title: "Автоматизация питания",  items5:[
        {title: "Подключение к ЕГАИС", href:"equipment/programs/connection-egais", items:[]},
        {title: "Регистрация честный знак", href:"equipment/programs/connection-chestnui-znak", items:[]},
        {title: "Подключение к ЭДО", href:"equipment/programs/connection-edo", items:[]},
        {title: "Настройки УТМ", href:"equipment/programs/connection-utm", items:[]},
      ]},
      {image:"assets/header/programming/Dop2 Pv2.svg",title: "Сопутствующие услуги 1С: Отель",  items5:[
        {title: "Обновление программы 1С: Отель", href:"/", items:[]},
        {title: "Передача в ФМС", href:"/", items:[]},
        {title: "Обмен с 1С: Бухгалтерия", href:"/", items:[]},
        {title: "Резервное копирование FTP", href:"/", items:[]},
      ]},
    ]},
    {title: "Продукты", items6:[
      {title: "Бронирование",  items6:[
        {title: "Модуль бронирования", href:"developments/booking-module", items:[]},
        {title: "Оплата онлайн", href:"/", items:[]},
        {title: "Панель управления", href:"/", items:[]},
        {title: "Система лояльности", href:"/", items:[]},
      ]},
      {title: "Контроль доступа", items6:[
        {title: "Парковка", href:"/",items:[]},
        {title: "Персонализация карт", href:"/",items:[]},
      ]},
      {title: "Кабинет гостя", items6:[
        {title: "Единый ЛК", href:"/", items:[]},
      ]},
      {title: "Автоматизация", items6:[
        {title: "Бронив Битрикс 24", href:"/", items:[]},
        {title: "Выгрузка в 1С: Отель", href:"/", items:[]},
       
      ]},
    ]},
    {title: "Продукты", items7:[
      {image:"assets/header/product/DopPr v2.svg",title: "Расширенные возможности",  items7:[
        {title: "Менеджеры каналов", href:"/", items:[]},
        {title: "Бронирование в соц сетях", href:"/", items:[]},
        {title: "Опция переселения", href:"/", items:[]},
        {title: "Для сети отелей", href:"/", items:[]},
      ]},
    ]},
    {title: "Интеграции", items8:[
      {title: "Интеграции 1С: Отель",  items8:[
        {title: "1С: Отель/ Бухгалтерия", href:"/", items:[]},
        {title: "1С: Отель/ Трактиръ", href:"/", items:[]},
        {title: "1С: Отель/ Общепит", href:"/", items:[]},
        {title: "1С: Отель/ Автотросир", href:"/", items:[]},
        {title: "1С: Отель/ iiKo", href:"/", items:[]},
        {title: "1С: Отель/ Вектор", href:"/", items:[]},
      ]},
      {title: "Интеграции CRM", items8:[
        {title: "1С: Отель/ Битрикс 24", href:"/",items:[]},
      ]},
      {title: "Интеграции СКУД", items8:[
        {title: "1С: Отель/ Сигур", href:"/", items:[]},
        {title: "1С: Отель/ Эл. замки", href:"/", items:[]},
      ]},
    ]},
    {title: "Продукты", items9:[
      {image:"assets/header/integration/Dopi v2.svg",title: "Дополнительные интеграции",  items9:[
        {title: "Интеграция1", href:"/", items:[]},
        {title: "Интеграция2", href:"/", items:[]},
        {title: "Интеграция3", href:"/", items:[]},
        {title: "Интеграция4", href:"/", items:[]},
      ]},
    ]},
    {title: "О компании", items10:[
      {title: "В работе", items10:[
        {title: "Интеграция с Авито", href:"/",items:[]},
        {title: "Баллы лояльности в 1С: Отель", href:"/",items:[]},
        {title: "Контроль доступа по браслетам", href:"/",items:[]},
        {title: "Курсовки для пансионатов", href:"/",items:[]},
      ]},
      {title: "О компании",  items10:[
        {title: "О нас", href:"o-nas", items:[]},
        {title: "Партнерам", href:"partners", items:[]},
        {title: "Технологический стек", href:"teh-stack", items:[]},
        {title: "Вакансии", href:"/", items:[]},
      ]},
     
      {title: "Интересное", items10:[
        {title: "Наши новости", href:"/", items:[]},
      ]},
     
    ]},
    {title: "О компании", items11:[
      {image:"assets/header/company/DopKv2.svg",title: "Скачать медиа файлы",  items11:[
        {title: "Модуль бронирования", href:"/", items:[]},
        {title: "Внедрение 1С: Отель", href:"/", items:[]},
        {title: "Сопровождение 1С: Отель", href:"/", items:[]},
        {title: "Битрикс 24", href:"/", items:[]},
      ]},
      {image:"",title: "",  items11:[
        {title: "Оборудование и подключение", href:"/", items:[]},
        {title: "Автоматизация питания", href:"/", items:[]},
        {title: "Программирование", href:"/", items:[]},
        {title: "Интеграции", href:"/", items:[]},
      ]},
    ]},
  /* {title: "Оборудование", items:[
      {title: "Онлайн кассы", href:"devices/cash-registers", items:[
       // {title: "Атол 25Ф", href:"devices/cash-registers/atol-25f", items:[]},
       // {title: "Атол 30Ф", href:"devices/cash-registers/atol-30f", items:[]},
       // {title: "Атол 55Ф", href:"devices/cash-registers/atol-55f", items:[]},
      //  {title: "Атол 20Ф", href:"devices/cash-registers/atol-20f", items:[]},
       // {title: "Атол 91Ф", href:"devices/cash-registers/atol-91f", items:[]},
       // {title: "Атол 77Ф", href:"devices/cash-registers/atol-77f", items:[]},
      ]},
      
      {title: "Фискальные накопители", href:"devices/fiscal-storage", items:[]},
      {title: "RFID считыватели", items:[]},
      {title: "Сканеры", items:[]},
      {title: "Электронные замки", items:[]},
      {title: "POC терминалы", items:[]},
      {title: "Принтеры чеков", items:[]},  
      
    ]},
    {title: "Программы", items:[
      {title: "1С:Предприятие", items:[
        {title: "1С:Отель", href:"devices/hotel", items:[]},
        
        {title: "1С:Бухгалтерия", items:[]},
        {title: "1С:Управление нашей фирмой", items:[]},
        {title: "1С:Управление торговлей", items:[]},
        {title: "1С:Розница", items:[]},
        
      ]},
      
      {title: "Битрикс 24", items:[
        {title: "CRM", items:[]},
        {title: "Автоматизация", items:[]},
        {title: "Сайты", items:[]},
      ]},
      {title: "Iiko (Айко)", items:[
        {title: "Iiko (Айко) – комплексное решение", items:[]},
        {title: "Приложение для официантов iikoWaiter", items:[]},
        {title: "Модуль для 1С:Отель", items:[]},
      ]},
      
    ]},
    {title: "Разработки", items:[
      {title: "Модуль бронирования", href:"developments/booking-module", items:[]},
      {title: "Управление контролем доступа", href:"developments/ukd", items:[]}, 
    ]},
    {title: "Интеграции", items:[]},
    {title: "О компании", items:[
      {title: "О нас", items:[]},
      {title: "Партнеры", items:[]}, 
      {title: "Новости", items:[]}, 
      {title: "Вакансии", items:[]}, 
    ]},
    {title: "Контакты", items:[]},*/
  ]
  public menuMobile:any = [
    
    {title: "Оборудование",expanded:false, items:[
      {title: "Онлайн-кассы",  items:[
        {title: "Онлайн кассы", href:"devices/cash-registers", items:[]},
        {title: "Фискальный накопитель", href:"/", items:[]},
      ]},
      {title: "Сканеры", items:[
        {title: "Сканеры штрих-кода", href:"/",items:[]},
      ]},
      {title: "Принтеры", items:[
        {title: "Принтеры чеков", href:"/", items:[]},
      ]},
      {title: "POS оборудование", items:[
        {title: "POS терминал", href:"/", items:[]},
      ]},
      {title: "Материалы", items:[
        {title: "Чековая лента", href:"/", items:[]},
      ]},
      {title: "Замки", items:[
        {title: "Электронные замки", href:"/", items:[]},
      ]}, 
      {title: "Услуги для касс-онлайн",  items:[
        {title: "Регистрация ФНС", href:"equipment/online-sales-register/registration-fns", items:[]},
        {title: "Подключение к ОФД", href:"equipment/online-sales-register/connection-ofd", items:[]},
        {title: "Настройка ККМ", href:"equipment/online-sales-register/customization-kkm", items:[]},
        {title: "Подключение к 1С", href:"equipment/online-sales-register/connection-1C", items:[]},
      ]}, 
    ]},
    {title: "Программы",expanded:false, items:[
      {title: "Продукты 1С",  items:[
        {title: "1С: Отель", href:"/", items:[]},
        {title: "1С: Бухгалтерия", href:"/", items:[]},
        {title: "1С: Розница", href:"/", items:[]},
        {title: "1С: Трактиръ", href:"/", items:[]},
        {title: "Внедрение iiKo", href:"#", items:[]},
      ]},
      {title: "iiKo", items:[
        {title: "Программа автоматизации", href:"/", items:[]},
        {title: "iiKoWaiter для официантов", href:"/", items:[]},
       
      ]},
      {title: "Битрикс", items:[
        {title: "CRM Битрикс 24", href:"#",items:[]},
        {title: "Автоматизация", href:"#",items:[]},
      ]},
      {title: "Лицензирование", items:[
        {title: "Лицензии", href:"services/programming/licenzii", items:[]},//нужно переименовать страницу
      ]},
      {title: "Автоматизация питания",  items:[
        {title: "Подключение к ЕГАИС", href:"equipment/programs/connection-egais", items:[]},
        {title: "Регистрация честный знак", href:"equipment/programs/connection-chestnui-znak", items:[]},
        {title: "Подключение к ЭДО", href:"equipment/programs/connection-edo", items:[]},
        {title: "Настройки УТМ", href:"equipment/programs/connection-utm", items:[]},
      ]},
      {title: "Сопутствующие услуги 1С: Отель",  items:[
        {title: "Обновление программы 1С: Отель", href:"/", items:[]},
        {title: "Передача в ФМС", href:"/", items:[]},
        {title: "Обмен с 1С: Бухгалтерия", href:"#", items:[]},
        {title: "Резервное копирование FTP", href:"services/integration/bitrix24", items:[]},
      ]},
      
    ]},
    {title: "Услуги", expanded:false, items:[
      {title: "Внедрение",  items:[
        {title: "Внедрение 1С: Отель", href:"services/integration/hotel", items:[], programming:[0,1,2]},
        {title: "Внедрение 1С: Трактиръ", href:"services/integration/traktir", items:[]},
        {title: "Внедрение iiKo", href:"/", items:[]},
        {title: "Внедрение Битрикс 24", href:"services/integration/bitrix24", items:[]},
      ]},
      {title: "Сопровождение", items:[
        {title: "Программа 1С: Отель", href:"services/escort-1c-hotel",items:[]},
        {title: "Программа 1С: Трактиръ", href:"/",items:[]},
        {title: "Программа iiKo", href:"/",items:[]},
        
      ]},
      {title: "Программирование", items:[
        {title: "Печатные формы", href:"/", items:[]},
        {title: "Обработки", href:"/", items:[]},
        {title: "Расширения", href:"/", items:[]},
        {title: "Конфигурации", href:"/", items:[]},
      ]},
      {title: "Обучение", items:[
        {title: "Обучение 1С: Отель", href:"training-1c-hotel", items:[]},
        {title: "Обучение Битрикс 24", href:"/", items:[]},
       
      ]}, 
    ]},
    {title: "Продукты",expanded:false, items:[
      {title: "Бронирование",  items:[
        {title: "Модуль бронирования", href:"developments/booking-module", items:[]},
        {title: "Оплата онлайн", href:"/", items:[]},
        {title: "Панель управления", href:"/", items:[]},
        {title: "Система лояльности", href:"/", items:[]},
      ]},
      {title: "Контроль доступа", items:[
        {title: "Парковка", href:"/",items:[]},
        {title: "Персонализация карт", href:"#",items:[]},
      ]},
      {title: "Кабинет гостя", items:[
        {title: "Единый ЛК", href:"/", items:[]},
      ]},
      {title: "Автоматизация", items:[
        {title: "Бронив Битрикс 24", href:"/", items:[]},
        {title: "Выгрузка в 1С: Отель", href:"/", items:[]},
       
      ]},
      {title: "Расширенные возможности",  items:[
        {title: "Менеджеры каналов", href:"/", items:[]},
        {title: "Бронирование в соц сетях", href:"/", items:[]},
        {title: "Опция переселения", href:"#", items:[]},
        {title: "Для сети отелей", href:"/", items:[]},
      ]},
    ]},
    {title: "Интеграции",expanded:false, items:[
      {title: "Интеграции 1С: Отель",  items:[
        {title: "1С: Отель/ Бухгалтерия", href:"/", items:[]},
        {title: "1С: Отель/ Трактиръ", href:"/", items:[]},
        {title: "1С: Отель/ Общепит", href:"/", items:[]},
        {title: "1С: Отель/ Автотросир", href:"/", items:[]},
        {title: "1С: Отель/ iiKo", href:"/", items:[]},
        {title: "1С: Отель/ Вектор", href:"/", items:[]},
      ]},
      {title: "Интеграции CRM", items:[
        {title: "1С: Отель/ Битрикс 24", href:"/",items:[]},
      ]},
      {title: "Интеграции СКУД", items:[
        {title: "1С: Отель/ Сигур", href:"/", items:[]},
        {title: "1С: Отель/ Эл. замки", href:"/", items:[]},
      ]},
      {title: "Дополнительные интеграции",  items:[
        {title: "Интеграция1", href:"/", items:[]},
        {title: "Интеграция2", href:"/", items:[]},
        {title: "Интеграция3", href:"/", items:[]},
        {title: "Интеграция4", href:"/", items:[]},
      ]},
    ]},
    {title: "О компании",expanded:false, items:[
      {title: "В работе", items:[
        {title: "Интеграция с Авито", href:"/",items:[]},
        {title: "Баллы лояльности в 1С: Отель", href:"/",items:[]},
        {title: "Контроль доступа по браслетам", href:"/",items:[]},
        {title: "Курсовки для пансионатов", href:"/",items:[]},
      ]},
      {title: "О компании",  items:[
        {title: "О нас", href:"o-nas", items:[]},
        {title: "Партнерам", href:"partners", items:[]},
        {title: "Технологический стек", href:"teh-stack", items:[]},
        {title: "Вакансии", href:"/", items:[]},
      ]},
     
      {title: "Интересное", items:[
        {title: "Наши новости", href:"/", items:[]},
      ]},
      {title: "Скачать медиа файлы",  items:[
        {title: "Модуль бронирования", href:"/", items:[]},
        {title: "Внедрение 1С: Отель", href:"/", items:[]},
        {title: "Сопровождение 1С: Отель", href:"/", items:[]},
        {title: "Битрикс 24", href:"/", items:[]},
        {title: "Оборудование и подключение", href:"/", items:[]},
        {title: "Автоматизация питания", href:"/", items:[]},
        {title: "Программирование", href:"/", items:[]},
        {title: "Интеграции", href:"/", items:[]},
      ]},
    ]},
    {title: "Контакты",href:"#", items:[]},
   
  ]
  public cashRegisters:any = [
    {name: "Атол 25Ф", id:"atol-25f", description: ""},
    {name: "Атол 30Ф", id:"atol-30f", description: ""},
    {name: "Атол 55Ф", id:"atol-55f", description: ""},
    {name: "Атол 20Ф", id:"atol-20f", description: ""},
    {name: "Атол 91Ф", id:"atol-91f", description: ""},
    {name: "Атол 77Ф", id:"atol-77f", description: ""},
  ]


  public packages: any = {
    hotel:[
      {
        title: "Внедрение 1С:Отель",
        image:"assets/services/Group 439.png", 
        type: 'Пакет "Базовый"',
        description: 'Данное решение оптимально подходит для старта или перехода на новую платформу управления отелем',
        description_detail: "В “Базовый” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: " ",
        price: 40000,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: false, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: false, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: false, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/hotel?packet=0",
        attentions: [
          {
            description:[
              {
                text: 'Приобретение программы 1С: Отель не входит в стоимость',
             
              },
                      
            ],
          },
          {
            description:[
              {
                text: 'В данном пакете не придусмотренно сопровождение программы 1С: Отель',
              },
           
            ],
          },
          
        ],
      },
      {
        title: "Внедрение 1С:Отель",
        type: 'Пакет "Расширенный"',
        image: "assets/services/Group 378.png",
        description: 'Включает в себя расспространенные интеграции и подключения, а так-же обмен данных с CRM системами',
        description_detail: "В “Расширенный” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: "*В стоимость включен краткий аудит используемой у вас программы 1С. Вы можете самостоятельно формировать свой пакет по запросу у нашего специалиста",
        price: 70000,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: true, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: true, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: false, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/hotel?packet=1",
        attentions: [
          {
            description:[
              {
                text: 'Приобретение программы 1С: Отель не входит в стоимость',
             
              },            
            ],
          },
          {
            description:[
              {
                text: 'В данном пакете не придусмотренно сопровождение программы 1С: Отель',
              },
            ],
          },
          
        ],
      },
      {
        title: "Внедрение 1С:Отель",
        type: 'Пакет "Полный"',
        image: "assets/services/Group 380.png",
        description: 'Уникальное предложение для отелей которым требуеться собственный модуль онлайн бронирования',
        description_detail: "В “Полный” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: "*В стоимость включен краткий аудит используемой у вас программы 1С. Вы можете самостоятельно формировать свой пакет по запросу у нашего специалиста",
        price: 100000,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: true, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: true, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/hotel?packet=2",
        attentions: [
          {
            description:[
              {
                text: 'Приобретение программы 1С: Отель не входит в стоимость',
             
              },           
            ],
          },
          {
            description:[
              {
                text: 'В данном пакете не придусмотренно сопровождение программы 1С: Отель',
              },
            ],
          },
          
        ],
      },
    ],
    /*
    bp:[
      {
        title: "Внедрение 1С:Бухалтерия",
        image:"assets/services/Group 439.png",
        type: 'Пакет "Базовый"',
        description: 'Данное решение оптимально подходит для старта или перехода на новую платформу управления отелем',
        description_detail: "В “Базовый” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: "*В стоимость включен краткий аудит используемой у вас программы 1С. Вы можете самостоятельно формировать свой пакет по запросу у нашего специалиста",
        price: 9000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: true, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: false, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: false, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/bp?packet=0",
        attentions: [
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'Приобретение и установка программы',
                bold: false,
              },
              {
                text: '1С:Отель, не входит в стоимость',
                margin: "0px 28px"
              },            
            ],
          },
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'В данном пакете не придусмотренно',
                bold: false,
              },
              {
                text: 'сопровождение и обучение',
                margin: "0px 28px"
              },
            ],
          },
          
        ],
      },
      {
        title: "Внедрение 1С:Бухалтерия",
        type: 'Пакет "Расширенный"',
        image: "assets/services/Group 378.png",
        description: 'Включает в себя расспространенные интеграции и подключения, а так-же обмен данных с CRM системами',
        description_detail: "В “Расширенный” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: "*В стоимость включен краткий аудит используемой у вас программы 1С. Вы можете самостоятельно формировать свой пакет по запросу у нашего специалиста",
        price: 15000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: true, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: true, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: false, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/bp?packet=1",
        attentions: [
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'Приобретение и установка программы',
                bold: false,
              },
              {
                text: '1С:Отель, не входит в стоимость',
                margin: "0px 28px"
              },            
            ],
          },
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'В данном пакете не придусмотренно',
                bold: false,
              },
              {
                text: 'сопровождение и обучение',
                margin: "0px 28px"
              },
            ],
          },
          
        ],
      },
      {
        title: "Внедрение 1С:Бухалтерия",
        type: 'Пакет "Полный"',
        image: "assets/services/Group 380.png",
        description: 'Уникальное предложение для отелей которым требуеться собственный модуль онлайн бронирования',
        description_detail: "В “Полный” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: "*В стоимость включен краткий аудит используемой у вас программы 1С. Вы можете самостоятельно формировать свой пакет по запросу у нашего специалиста",
        price: 20000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: true, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: true, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/hotel?packet=2",
        attentions: [
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'Приобретение и установка программы',
                bold: false,
              },
              {
                text: '1С:Отель, не входит в стоимость',
                margin: "0px 28px"
              },            
            ],
          },
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'В данном пакете не придусмотренно',
                bold: false,
              },
              {
                text: 'сопровождение и обучение',
                margin: "0px 28px"
              },
            ],
          },
          
        ],
      },
    ],
    ut:[
      {
        title: "Внедрение 1С:УправлениеТорговлей",
        image:"assets/services/Group 439.png",
        type: 'Пакет "Базовый"',
        description: 'Данное решение оптимально подходит для старта или перехода на новую платформу управления отелем',
        description_detail: "В “Базовый” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: "*В стоимость включен краткий аудит используемой у вас программы 1С. Вы можете самостоятельно формировать свой пакет по запросу у нашего специалиста",
        price: 9000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: true, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: false, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: false, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/bp?packet=0",
        attentions: [
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'Приобретение и установка программы',
                bold: false,
              },
              {
                text: '1С:Отель, не входит в стоимость',
                margin: "0px 28px"
              },            
            ],
          },
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'В данном пакете не придусмотренно',
                bold: false,
              },
              {
                text: 'сопровождение и обучение',
                margin: "0px 28px"
              },
            ],
          },
          
        ],
      },
      {
        title: "Внедрение 1С:УправлениеТорговлей",
        type: 'Пакет "Расширенный"',
        image: "assets/services/Group 378.png",
        description: 'Включает в себя расспространенные интеграции и подключения, а так-же обмен данных с CRM системами',
        description_detail: "В “Расширенный” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: "*В стоимость включен краткий аудит используемой у вас программы 1С. Вы можете самостоятельно формировать свой пакет по запросу у нашего специалиста",
        price: 15000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: true, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: true, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: false, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/bp?packet=1",
        attentions: [
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'Приобретение и установка программы',
                bold: false,
              },
              {
                text: '1С:Отель, не входит в стоимость',
                margin: "0px 28px"
              },            
            ],
          },
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'В данном пакете не придусмотренно',
                bold: false,
              },
              {
                text: 'сопровождение и обучение',
                margin: "0px 28px"
              },
            ],
          },
          
        ],
      },
      {
        title: "Внедрение 1С:УправлениеТорговлей",
        type: 'Пакет "Полный"',
        image: "assets/services/Group 380.png",
        description: 'Уникальное предложение для отелей которым требуеться собственный модуль онлайн бронирования',
        description_detail: "В “Полный” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: "*В стоимость включен краткий аудит используемой у вас программы 1С. Вы можете самостоятельно формировать свой пакет по запросу у нашего специалиста",
        price: 20000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: true, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: true, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/hotel?packet=2",
        attentions: [
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'Приобретение и установка программы',
                bold: false,
              },
              {
                text: '1С:Отель, не входит в стоимость',
                margin: "0px 28px"
              },            
            ],
          },
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'В данном пакете не придусмотренно',
                bold: false,
              },
              {
                text: 'сопровождение и обучение',
                margin: "0px 28px"
              },
            ],
          },
          
        ],
      },
    ],
    roznica:[
      {
        title: "Внедрение 1С:Розница",
        image:"assets/services/Group 439.png",
        type: 'Пакет "Базовый"',
        description: 'Данное решение оптимально подходит для старта или перехода на новую платформу управления отелем',
        description_detail: "В “Базовый” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: "*В стоимость включен краткий аудит используемой у вас программы 1С. Вы можете самостоятельно формировать свой пакет по запросу у нашего специалиста",
        price: 9000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: true, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: false, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: false, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/bp?packet=0",
        attentions: [
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'Приобретение и установка программы',
                bold: false,
              },
              {
                text: '1С:Отель, не входит в стоимость',
                margin: "0px 28px"
              },            
            ],
          },
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'В данном пакете не придусмотренно',
                bold: false,
              },
              {
                text: 'сопровождение и обучение',
                margin: "0px 28px"
              },
            ],
          },
          
        ],
      },
      {
        title: "Внедрение 1С:Розница",
        type: 'Пакет "Расширенный"',
        image: "assets/services/Group 378.png",
        description: 'Включает в себя расспространенные интеграции и подключения, а так-же обмен данных с CRM системами',
        description_detail: "В “Расширенный” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: "*В стоимость включен краткий аудит используемой у вас программы 1С. Вы можете самостоятельно формировать свой пакет по запросу у нашего специалиста",
        price: 15000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: true, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: true, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: false, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/bp?packet=1",
        attentions: [
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'Приобретение и установка программы',
                bold: false,
              },
              {
                text: '1С:Отель, не входит в стоимость',
                margin: "0px 28px"
              },            
            ],
          },
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'В данном пакете не придусмотренно',
                bold: false,
              },
              {
                text: 'сопровождение и обучение',
                margin: "0px 28px"
              },
            ],
          },
          
        ],
      },
      {
        title: "Внедрение 1С:Розница",
        type: 'Пакет "Полный"',
        image: "assets/services/Group 380.png",
        description: 'Уникальное предложение для отелей которым требуеться собственный модуль онлайн бронирования',
        description_detail: "В “Полный” пакет внедрения входят основные настройки системы до полностью рабочей среды программы 1С:Отель. Данное решение оптимально подходит для старта или перехода на новую платформу. Наша компания гарантирует быстрое внедрение и настройку программного обеспечения без прерывания от действующих процессов",
        footer: "*В стоимость включен краткий аудит используемой у вас программы 1С. Вы можете самостоятельно формировать свой пакет по запросу у нашего специалиста",
        price: 20000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс установки и выявить нюансы используемой у клиента конфигурации. В пакет входит анализ установленного оборудования и работы по сети"},
          { open: false, enabled: true, index: 1, name: "Административный блок", description:"Проведем работу по юридическим данным организации: название отеля, адрес, реквизиты график работы. Настроим права доступа для сотрудников и их внедрение, внешний вид форм. По типу работ в данный блок входит так же настройка APM (автоматизирование рабочего места), запрос документов, настройка цен и тарифов"},
          { open: false, enabled: true, index: 2, name: "Рабочий блок", description:"Основной блок с большим кластером информации включает в себя: создание и наполнение номерного фонда, цен, тарифов. Формирование типов номеров и их описание. Работа с квотой, видами размещения, заездом и выездом гостей. Создание взаиморасчетов для контрагентов и гостей, настройка бухгалтерской документации. Сюда так же войдет работа по загрузки классификаторов и формирование отчетности"},
          { open: false, enabled: true, index: 3, name: "Технический блок", description:"Подключение фискального регистратора Атол (Штрих), настройка банковского термина и KKM. Установка лицензии 1С и отладка сервера, установка считывателя карт, работа с пользовательскими данными. Структурируем выгрузку данных в УФМС И МВД согласно их требованиям, синхронизируем обмен с программой 1С:Бухгалтерия"},
          { open: false, enabled: true, index: 4, name: "Обучение", description:"Проведем обучение по определенным спецификам работы в программе 1С: Отель. Специалист заронит самые важные аспекты в узко направленных зонах и расскажет о самых часто распространенных ошибках. Обучение проходит онлайн, как по заранее подготовленным курсам так и индивидуально"},
          { open: false, enabled: true, index: 5, name: "Интеграции", description:"В блок включены основные и самые распространенные подключения и интеграции: подключение Channel manager (полный список в подробном описании), интеграция с программой IIKO, интеграция с Битрикс 24, интеграция со сторонними модулями бронирования (Bnova, Wubook, TravelLine)"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования", description:"Внедрим и проведем полную настройку модуля бронирования от нашей компании. Настроим синхронизацию с программой 1С: Отель и менеджерами каналов. Наполним модуль информацией о номерах, ценами и системой скидок. Интегрируем систему СПБ и другие системы онлайн оплат, установим модуль бронирования на web ресурс"},
        ],
        href:"services/integration/hotel?packet=2",
        attentions: [
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'Приобретение и установка программы',
                bold: false,
              },
              {
                text: '1С:Отель, не входит в стоимость',
                margin: "0px 28px"
              },            
            ],
          },
          {
            description:[
              {
                image: "assets/services/Group 373.png",
                text: 'В данном пакете не придусмотренно',
                bold: false,
              },
              {
                text: 'сопровождение и обучение',
                margin: "0px 28px"
              },
            ],
          },
          
        ],
      },
    ],

     */        
    bitrix24:[
      {
        title: "Внедрение Bitrix 24",
        type: 'Пакет "Старт"',
        image:"assets/services/Group 439.png",
        description: '',
        description_detail: 'Пакет “Старт”- это оптимальные решения, неоднократно протестированные на практике, которые максимально учитывают задачи каждой конкретной компании',
        footer: "",
        price: 22900,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый Блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс внедрения и выявить нюансы и потребности у клиента."},
          { open: false, enabled: true, index: 1, name: "Рабочий блок", description:"Регистрация облачной версии, либо установка и настройка коробочный версии. Импорт клиентской базы из Excel. Установка мобильного и десктопного приложения. Базовая настройка структуры компании. Настройка графика работы, рабочее время компании, логотип и другое"},
          { open: false, enabled: true, index: 2, name: "Подключение и регистрация: до 5-ти рабочих мест ", description:"Настройка этапов работы с лидом и сделки – да", description2:"Регистрация сотрудников на корпоративном портале - 5 рабочих мест", description3:"Подключение форм обратной связи сайта - 1", description4:"Подключение электронной почты – 5", description5:"Настройка шаблонов типовых документов – 1", description6:"Настройка и подключение виджетов для связки Битрикс24 и сайта компании"},
          { open: false, enabled: false, index: 3, name: "Настройка этапов", description:"Оптимизация полей карточек лида, контакта, сделки – нет", description2:"Настройка типовых фильтров лидов, сделок, дел – нет", description3:"Настройка прав доступа для структуры компании – нет", description4:"Создание дополнительных направлений сделок - мультиворонка - нет"},
          { open: false, enabled: false, index: 4, name: "Интеграции",description:"Подключение штатной или внешней SIP телефонии – нет", description2:"Настройка контакт-центра - нет", description3:"Подключение к Битрикс24 CRM соцсетей и мессенджеров – нет", description4:"Стандартная интеграция Интернет-магазина на 1С-Битрикс с Битрикс24 - нет", description5:"Выгрузка заказов в сделки – нет", description6:"Интеграция с программой 1С: Отель – нет", description7:"Интеграция с модулем бронирования - нет"}
        ],
        href:"services/integration/bitrix24?packet=0",
        attentions: [
          {
            description:[
              {
                text: 'Лицензия Битрикс24 приобретается отдельно',
             
              },
                      
            ],
          },
          {
            description:[
              {
                text: 'Обучение сотрудников не входит в стоимость',
             
              },
                      
            ],
          },
          
        ],
      },
      {
        title: "Внедрение Bitrix 24",
        type: 'Пакет "Стандарт"',
        image: "assets/services/Group 378.png",
        description: 'Пакет включает в себя детальную настройку сделок по шаблонам и создание контакт центра',
        description_detail: 'Пакет "Стандарт" -подойдет для компаний, где требуется настроить большее количество рабочих мест, настроить дополнительнын права доступа, формы ',
        footer: "",
        price: 30000,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый Блок (Аудит)",description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс внедрения и выявить нюансы и потребности у клиента."},
          { open: false, enabled: true, index: 1, name: "Рабочий блок", description:"Регистрация облачной версии, либо установка и настройка коробочный версии. Импорт клиентской базы из Excel. Установка мобильного и десктопного приложения. Базовая настройка структуры компании. Настройка графика работы, рабочее время компании, логотип и другое"},
          { open: false, enabled: true, index: 2, name: "Подключение и регистрация: до 30-ти рабочих мест ", description:"Настройка этапов работы с лидом и сделки – да", description2:"Регистрация сотрудников на корпоративном портале - 30 рабочих мест", description3:"Подключение форм обратной связи сайта - 3 ", description4:"Подключение электронной почты – 30", description5:"Настройка шаблонов типовых документов – 2", description6:"Настройка и подключение виджетов для связки Битрикс24 и сайта компании"},
          { open: false, enabled: true, index: 3, name: "Настройка этапов", description:"Оптимизация полей карточек лида, контакта, сделки – да", description2:"Настройка типовых фильтров лидов, сделок, дел – да", description3:"Настройка прав доступа для структуры компании – 2", description4:"Создание дополнительных направлений сделок - мультиворонка - 1"},
          { open: false, enabled: false, index: 4, name: "Интеграции", description:"Подключение штатной или внешней SIP телефонии – нет", description2:"Настройка контакт-центра - нет", description3:"Подключение к Битрикс24 CRM соцсетей и мессенджеров – нет", description4:"Стандартная интеграция Интернет-магазина на 1С-Битрикс с Битрикс24. -нет", description5:"Выгрузка заказов в сделки – нет", description6:"Интеграция с программой 1С: Отель – нет", description7:"Интеграция с модулем бронирования - нет"}
        ],
        href:"services/integration/bitrix24?packet=0",
        attentions: [
          {
            description:[
              {
                text: 'Лицензия Битрикс24 приобретается отдельно',
             
              },
                         
            ],
          },
          {
            description:[
              {
                text: 'Обучение сотрудников не входит в стоимость',
             
              }, 
            ],
          },
          
        ],
      },
      {
        title: "Внедрение Bitrix 24",
        type: 'Пакет "Бизнес"',
        image: "assets/services/Group 380.png",
        description: '',
        description_detail: 'Пакет подойдет для компаний, где требуется большее количество рабочих мест, а так - же интеграции с другими пнрограмными продуктами, настройка телефонии',
        footer: "",
        price: 50000,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый Блок (Аудит)",description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс внедрения и выявить нюансы и потребности у клиента."},
          { open: false, enabled: true, index: 1, name: "Рабочий блок", description:"Регистрация облачной версии, либо установка и настройка коробочный версии. Импорт клиентской базы из Excel. Установка мобильного и десктопного приложения. Базовая настройка структуры компании. Настройка графика работы, рабочее время компании, логотип и другое"},
          { open: false, enabled: true, index: 2, name: "Подключение и регистрация: до 50-ти рабочих мест ", description:"Настройка этапов работы с лидом и сделки – да", description2:"Регистрация сотрудников на корпоративном портале - 50 рабочих мест", description3:"Подключение форм обратной связи сайта - 5 ", description4:"Подключение электронной почты – 50", description5:"Настройка шаблонов типовых документов – 4", description6:"Настройка и подключение виджетов для связки Битрикс24 и сайта компании"},
          { open: false, enabled: true, index: 3, name: "Настройка этапов", description:"Оптимизация полей карточек лида, контакта, сделки – да", description2:"Настройка типовых фильтров лидов, сделок, дел – да", description3:"Настройка прав доступа для структуры компании – 4", description4:"Создание дополнительных направлений сделок - мультиворонка - 2"},
          { open: false, enabled: true, index: 4, name: "Интеграции", description:"Подключение штатной или внешней SIP телефонии – да", description2:"Настройка контакт-центра - да", description3:"Подключение к Битрикс24 CRM соцсетей и мессенджеров – да", description4:"Стандартная интеграция Интернет-магазина на 1С-Битрикс с Битрикс24 - да", description5:"Выгрузка заказов в сделки – да", description6:"Интеграция с программой 1С: Отель - да", description7:"Интеграция с модулем бронирования - да"}
        ],
        href:"services/integration/bitrix24?packet=0",
        attentions: [
          {
            description:[
              {
                text: 'Лицензия Битрикс24 приобретается отдельно',
             
              },          
            ],
          },
          {
            description:[
              {
                text: 'Обучение сотрудников не входит в стоимость',
             
              }, 
            ],
          },
          
        ],
      }
    ],
   
    module:[
      {
        title: "Модуль бронирования",
        image:"", 
        type: 'Пакет "Мини"',
        description: '',
        description_detail: "Данный пакет подойдет для небольших отелей, хостелов или любых других обьектов для размещения гостей. В пакет входит весь заложенный функционал",
        footer: "",
        price: 4500,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый Блок (Аудит) ", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс внедрения и установки и выявить нюансы, потребности клиента"},
          { open: false, enabled: true, index: 1, name: "Настройка и установка модуля бронирования ", description:"Полностью возьмем на себя настройку и устаноку модуля бронирования от загрузки фотографий номеров, описания тарифов, условий оплаты, акций. Установим модуль на ваш сайт - проведем отладку ", description2:"Сопоставление тарифных планов", description3:"Создание типов номеров", description4:"Создание дополнительных услуг", description5:"Формирование календарных цен", description6:"Формирование номерной квоты"},
          { open: false, enabled: true, index: 2, name: "Оплата - интернет эквайринг", description:"Подключим и настроим банковскую систему онлайн оплат с вашего сайта в приеме платежей и переводе средств продовцу с помощью самых распространненых интернет-эквайрингов в Р.Ф", description2:"Некоторые из них:", description3:"Сбербанк", description4:"Альфа-Банк", description5:"Система быстрых платежей (СПБ)", description6:"Юкасса", description7:"PayKeeper"},
          { open: false, enabled: true, index: 3, name: "Номерной фонд - до 20ти", description:"В данном тарифе предусмотрено ограничение по максимальному количеству номеров в отеле или любом другом объекте для размещения гостей, которое составляет не более 20"},
          { open: false, enabled: true, index: 4, name: "Интеграции", description:"В функционале модуля бронирования заложены базовые интеграции:", description2: "Программ 1С: Отель ", description3: "Интеграция с двухсторонним обменом данных между модулем и PMS системой управления отелем. Единая система расчетов, актуальности свободных номеров, данные по гостям и многое другое. Система отчетности с и передача данных в бухгалтерию ", description4:"Битрикс 24 ", description5:"Выгрузка броней напрямую с модуля бронирования в CRM Систему Битрикс 24. Формирование сделки для дальнейшего пути по воронкам продаж отдела бронирования"},
          { open: false, enabled: true, index: 5, name: "Менеджеры каналов", description:"Буквально в пару кликов в панели управления модулем бронирования -осуществляется взаимно обратная синхронизация по обмену данными с самыми популярными менеджерами каналов как: Яндекс Путешествия, Броневик, Островок, 101 отель и другими.", description2:"Моментальная выгрузка броней для специалистов отдела бронирования и оповещений для клиентов"},
          { open: false, enabled: true, index: 6, name: "Коимиссия - 0%", description:"Мы не берем комиссии за любое количество броней через наш модуль бронирования, у нас нет скрытых платежей или дополнительных взносов"},
        ],
        href:"services/integration/hotel?packet=0",
        attentions: [
          {
            description:[
              {
                text: 'Для сети отелей с множеством объектов размещения на одном сайте - действует уникальное предложение',
              },            
            ],
          },
          
        ],
      },
      {
        title: "Модуль бронирования",
        type: 'Пакет "Стандарт"',
        image: "",
        description: '',
        description_detail: "Пакет “Стандарт”- это оптимальные решения для средних отелей,где номерной фонд составляет не более 100 номеров. В пакет входит весь заложенный функционал",
        footer: "",
        price: 7500,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый Блок (Аудит) ", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс внедрения и установки и выявить нюансы, потребности клиента"},
          { open: false, enabled: true, index: 1, name: "Настройка и установка модуля бронирования ", description:"Полностью возьмем на себя настройку и устаноку модуля бронирования от загрузки фотографий номеров, описания тарифов, условий оплаты, акций. Установим модуль на ваш сайт - проведем отладку ", description2:"Сопоставление тарифных планов", description3:"Создание типов номеров", description4:"Создание дополнительных услуг", description5:"Формирование календарных цен", description6:"Формирование номерной квоты"},
          { open: false, enabled: true, index: 2, name: "Оплата - интернет эквайринг", description:"Подключим и настроим банковскую систему онлайн оплат с вашего сайта в приеме платежей и переводе средств продовцу с помощью самых распространненых интернет-эквайрингов в Р.Ф", description2:"Некоторые из них:", description3:"Сбербанк", description4:"Альфа-Банк", description5:"Система быстрых платежей (СПБ)", description6:"Юкасса", description7:"PayKeeper"},
          { open: false, enabled: true, index: 3, name: "Номерной фонд - до 20 - 100", description:"В данном тарифе предусмотрено ограничение по максимальному количеству номеров в отеле или любом другом объекте для размещения гостей, которое составляет не более 100"},
          { open: false, enabled: true, index: 4, name: "Интеграции", description:"В функционале модуля бронирования заложены базовые интеграции:", description2: "Программ 1С: Отель ", description3: "Интеграция с двухсторонним обменом данных между модулем и PMS системой управления отелем. Единая система расчетов, актуальности свободных номеров, данные по гостям и многое другое. Система отчетности с и передача данных в бухгалтерию ", description4:"Битрикс 24 ", description5:"Выгрузка броней напрямую с модуля бронирования в CRM Систему Битрикс 24. Формирование сделки для дальнейшего пути по воронкам продаж отдела бронирования"},
          { open: false, enabled: true, index: 5, name: "Менеджеры каналов", description:"Буквально в пару кликов в панели управления модулем бронирования -осуществляется взаимно обратная синхронизация по обмену данными с самыми популярными менеджерами каналов как: Яндекс Путешествия, Броневик, Островок, 101 отель и другими.", description2:"Моментальная выгрузка броней для специалистов отдела бронирования и оповещений для клиентов"},
          { open: false, enabled: true, index: 6, name: "Коимиссия - 0%", description:"Мы не берем комиссии за любое количество броней через наш модуль бронирования, у нас нет скрытых платежей или дополнительных взносов"},
        ],
        href:"services/integration/hotel?packet=1",
        attentions: [
          {
            description:[
              {
                text: 'Для сети отелей с множеством объектов размещения на одном сайте - действует уникальное предложение',
              },            
            ],
          },
          
        ],
      },
      {
        title: "Модуль бронирования",
        type: 'Пакет "Максимальный"',
        image: "",
        description: '',
        description_detail: "Отличное решение для крупных отелей с большим номерным фондом и интенсивным прямым трафиком на сайт объекта. В пакет входит весь заложенный функционал",
        footer: "",
        price: 9500,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый Блок (Аудит) ", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс внедрения и установки и выявить нюансы, потребности клиента"},
          { open: false, enabled: true, index: 1, name: "Настройка и установка модуля бронирования ", description:"Полностью возьмем на себя настройку и устаноку модуля бронирования от загрузки фотографий номеров, описания тарифов, условий оплаты, акций. Установим модуль на ваш сайт - проведем отладку ", description2:"Сопоставление тарифных планов", description3:"Создание типов номеров", description4:"Создание дополнительных услуг", description5:"Формирование календарных цен", description6:"Формирование номерной квоты"},
          { open: false, enabled: true, index: 2, name: "Оплата - интернет эквайринг", description:"Подключим и настроим банковскую систему онлайн оплат с вашего сайта в приеме платежей и переводе средств продовцу с помощью самых распространненых интернет-эквайрингов в Р.Ф", description2:"Некоторые из них:", description3:"Сбербанк", description4:"Альфа-Банк", description5:"Система быстрых платежей (СПБ)", description6:"Юкасса", description7:"PayKeeper"},
          { open: false, enabled: true, index: 3, name: "Номерной фонд - Без ограничений", description:"В данном тарифе нет ограничений по максимальному количеству номеров в отеле или любом другом объекте для размещения гостей."},
          { open: false, enabled: true, index: 4, name: "Интеграции", description:"В функционале модуля бронирования заложены базовые интеграции:", description2: "Программ 1С: Отель ", description3: "Интеграция с двухсторонним обменом данных между модулем и PMS системой управления отелем. Единая система расчетов, актуальности свободных номеров, данные по гостям и многое другое. Система отчетности с и передача данных в бухгалтерию ", description4:"Битрикс 24 ", description5:"Выгрузка броней напрямую с модуля бронирования в CRM Систему Битрикс 24. Формирование сделки для дальнейшего пути по воронкам продаж отдела бронирования"},
          { open: false, enabled: true, index: 5, name: "Менеджеры каналов", description:"Буквально в пару кликов в панели управления модулем бронирования -осуществляется взаимно обратная синхронизация по обмену данными с самыми популярными менеджерами каналов как: Яндекс Путешествия, Броневик, Островок, 101 отель и другими.", description2:"Моментальная выгрузка броней для специалистов отдела бронирования и оповещений для клиентов"},
          { open: false, enabled: true, index: 6, name: "Коимиссия - 0%", description:"Мы не берем комиссии за любое количество броней через наш модуль бронирования, у нас нет скрытых платежей или дополнительных взносов"},
        ],
        href:"services/integration/hotel?packet=2",
        attentions: [
          {
            description:[
              {
                text: 'Для сети отелей с множеством объектов размещения на одном сайте - действует уникальное предложение',
              },            
            ],
          },
          
        ],
      },
    ],
    module2:[
      {
        title: "Модуль бронирования",
        type: 'Пакет "Месяц"',
        description: 'Типовое решение с базовыми настройками и конфигурациями',
        price: 4000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок"},
          { open: false, enabled: true, index: 1, name: "Административный блок"},
          { open: false, enabled: true, index: 2, name: "Технический блок"},
          { open: false, enabled: true, index: 3, name: "Рабочий блок"},
          { open: false, enabled: true, index: 4, name: "Интеграции"},
          { open: false, enabled: true, index: 5, name: "Инфо блок"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования"}
        ]
      },
      {
        title: "Модуль бронирования",
        type: 'Пакет "Пол года"',
        description: 'Типовое решение с базовыми настройками и конфигурациями',
        price: 15000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок"},
          { open: false, enabled: true, index: 1, name: "Административный блок"},
          { open: false, enabled: true, index: 2, name: "Технический блок"},
          { open: false, enabled: true, index: 3, name: "Рабочий блок"},
          { open: false, enabled: true, index: 4, name: "Интеграции"},
          { open: false, enabled: true, index: 5, name: "Инфо блок"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования"}
        ]
      },
      {
        title: "Модуль бронирования",
        type: 'Пакет "Год"',
        description: 'Типовое решение с базовыми настройками и конфигурациями',
        price: 20000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок"},
          { open: false, enabled: true, index: 1, name: "Административный блок"},
          { open: false, enabled: true, index: 2, name: "Технический блок"},
          { open: false, enabled: true, index: 3, name: "Рабочий блок"},
          { open: false, enabled: true, index: 4, name: "Интеграции"},
          { open: false, enabled: true, index: 5, name: "Инфо блок"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования"}
        ]
      }
    ],
    kassa:[
      {
        title: "Модуль бронирования",
        type: 'Пакет "Месяц"',
        description: 'Типовое решение с базовыми настройками и конфигурациями',
        price: 4000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок"},
          { open: false, enabled: true, index: 1, name: "Административный блок"},
          { open: false, enabled: true, index: 2, name: "Технический блок"},
          { open: false, enabled: true, index: 3, name: "Рабочий блок"},
          { open: false, enabled: true, index: 4, name: "Интеграции"},
          { open: false, enabled: true, index: 5, name: "Инфо блок"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования"}
        ]
      },
      {
        title: "Модуль бронирования",
        type: 'Пакет "Пол года"',
        description: 'Типовое решение с базовыми настройками и конфигурациями',
        price: 15000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок"},
          { open: false, enabled: true, index: 1, name: "Административный блок"},
          { open: false, enabled: true, index: 2, name: "Технический блок"},
          { open: false, enabled: true, index: 3, name: "Рабочий блок"},
          { open: false, enabled: true, index: 4, name: "Интеграции"},
          { open: false, enabled: true, index: 5, name: "Инфо блок"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования"}
        ]
      },
      {
        title: "Модуль бронирования",
        type: 'Пакет "Год"',
        description: 'Типовое решение с базовыми настройками и конфигурациями',
        price: 20000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок"},
          { open: false, enabled: true, index: 1, name: "Административный блок"},
          { open: false, enabled: true, index: 2, name: "Технический блок"},
          { open: false, enabled: true, index: 3, name: "Рабочий блок"},
          { open: false, enabled: true, index: 4, name: "Интеграции"},
          { open: false, enabled: true, index: 5, name: "Инфо блок"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования"}
        ]
      }
    ],
    integration:[
      {
        title: "Модуль бронирования",
        type: 'Пакет "Месяц"',
        description: 'Типовое решение с базовыми настройками и конфигурациями',
        price: 4000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок"},
          { open: false, enabled: true, index: 1, name: "Административный блок"},
          { open: false, enabled: true, index: 2, name: "Технический блок"},
          { open: false, enabled: true, index: 3, name: "Рабочий блок"},
          { open: false, enabled: true, index: 4, name: "Интеграции"},
          { open: false, enabled: true, index: 5, name: "Инфо блок"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования"}
        ]
      },
      {
        title: "Модуль бронирования",
        type: 'Пакет "Пол года"',
        description: 'Типовое решение с базовыми настройками и конфигурациями',
        price: 15000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок"},
          { open: false, enabled: true, index: 1, name: "Административный блок"},
          { open: false, enabled: true, index: 2, name: "Технический блок"},
          { open: false, enabled: true, index: 3, name: "Рабочий блок"},
          { open: false, enabled: true, index: 4, name: "Интеграции"},
          { open: false, enabled: true, index: 5, name: "Инфо блок"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования"}
        ]
      },
      {
        title: "Модуль бронирования",
        type: 'Пакет "Год"',
        description: 'Типовое решение с базовыми настройками и конфигурациями',
        price: 20000,
        blocks: [
          { open: false, enabled: true, index: 0, name: "Базовый блок"},
          { open: false, enabled: true, index: 1, name: "Административный блок"},
          { open: false, enabled: true, index: 2, name: "Технический блок"},
          { open: false, enabled: true, index: 3, name: "Рабочий блок"},
          { open: false, enabled: true, index: 4, name: "Интеграции"},
          { open: false, enabled: true, index: 5, name: "Инфо блок"},
          { open: false, enabled: true, index: 6, name: "Модуль бронирования"}
        ]
      }
    ],
    traktir:[
      {
        title: "Внедрение 1С:Трактиръ",
        image:"assets/services/Group 439.png",
        type: 'Пакет "Базовый"',
        description: '',
        description_detail: "Данное решение оптимально подходит для старта или перехода на новую платформу управления и автоматизации питания ",
        footer: "",
        price: 15000,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс внедрения и выявить нюансы и потребности у клиента. В пакет входит анализ установленного оборудования и работы по сети "},
          { open: false, enabled: true, index: 1, name: "Рабочий блок: до 2х-фронтов", description:"Back-Office / Front-Office. Автоматизация всего процесса обслуживания гостей, от разработки плана зала и формирования меню заведения, до получения всей необходимой отчетности по расходу блюд, выручке и занятости персонала. Ведение бухгалтерского учета на предприятиях общественного питания ", description2:"Подключение до 2х-фронтов (рабочих мест)"},
          { open: false, enabled: true, index: 2, name: "Оборудование ", description:"Настройка и подключение оборудования и режима работы по сети", description2:"Настройка ККМ - Услуга подключения и дальнейшего обслуживания контрольно-кассового оборудования для осуществления корректной эксплуатации", description3:"Настройка Сканеров - Подключение 2D сканера штрихкодов в Трактиръ с использованием драйвера штрихкода 1С"},
          { open: false, enabled: true, index: 3, name: "Подключение и регистрация", 
            description:"Учет крепкого алкогольной продукции в розничных магазинах и общепите. Создание алкогольных диклораций, сканирование штрих кодов и акцизных марок", 
            description2:"Подключение к ЕГАИС", 
            description3:"Единая государственная автоматизированная информационная система, для контроля оборота спиртосодержащей продукции в Р.Ф.",
            description4:"Регистрация Честного знака",
            description5:"Зарегистрируем во всех системах для работы с маркировкой, оформим электронную подпись, настроим кассу и зарегистрируем её в налоговой",
            description6:"Подключение к ЭДО",
            description7:"Поможем подготовить локальные нормативные акты и соглашения с сотрудниками. Поддерживаем все типы электронных подписей: УКЭП, УНЭП, «Госключ» и «Госуслуги». Настроим интеграцию с вашей учетной системой",
          },
          { open: false, enabled: false, index: 4, name: "Обучение", 
            description:"Проводем полный цикл обучения сотрудников и затроним самые часто задаваемые вопросы. Выявив нюансы в повторяющихся ошибках опираясь на большой опыт ныших специалистов", 
            description2:"Обучение по работе с системой маркировки.",
            description3:"Обучение сотрудников ЭДО",
            description4:"Ообучение по работе с системой ЕГАИС",
          },
          { open: false, enabled: false, index: 5, name: "Сопровождение", 
            description:"Возмем на себя все сложные рабочие процессы и окажаем полную техническую подержку, как в работе самого програмного продукта, так и работу оборудования. Выполним обновления и устраним дефекты оперционных частей",
            description2:"Служба поддержки на сайте - да",
            description3:"Поддержка по телефону - да",
            description4:"Поддержка в чате: 1сКоннект, AnyDesk, Битрикс, Telegramm, WhatsApp - да",
            description5:"Поддержка по e-mail - да",
            description6:"Удаленная поддержка - да",
            description7:"Время сопровождения с 9 до 18 часов - (5/7) Рабочие дни.",
            description8:"Рабочие дни. Дополнительные работы - 2500",
          },
          
        ],
        href:"services/integration/bp?packet=0",
        attentions: [
          {
            description:[
              {
                text: 'Программа Трактиръ не входит в стоимость',
              },           
            ],
          },
          {
            description:[
              {
                text: 'Дополнительные лицензии приобретаются отдельно',
              },  
            ],
          },
          
        ],
      },
      {
        title: "Внедрение 1С:Трактиръ",
        type: 'Пакет "Расширенный"',
        image: "",
        description: '',
        description_detail: "Пакет подойдет для компаний, где требуется большее количество рабочих мест, атак - же обучить сотрудников работе в продукте. В пакет входит базовое техническое сопровождение",
        footer: "",
        price: 30000,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс внедрения и выявить нюансы и потребности у клиента. В пакет входит анализ установленного оборудования и работы по сети "},
          { open: false, enabled: true, index: 1, name: "Рабочий блок: до 5-ти фронтов", description:"Back-Office / Front-Office. Автоматизация всего процесса обслуживания гостей, от разработки плана зала и формирования меню заведения, до получения всей необходимой отчетности по расходу блюд, выручке и занятости персонала. Ведение бухгалтерского учета на предприятиях общественного питания ", description2:"Подключение до 2х-фронтов (рабочих мест)"},
          { open: false, enabled: true, index: 2, name: "Оборудование ", description:"Настройка и подключение оборудования и режима работы по сети", description2:"Настройка ККМ - Услуга подключения и дальнейшего обслуживания контрольно-кассового оборудования для осуществления корректной эксплуатации", description3:"Настройка Сканеров - Подключение 2D сканера штрихкодов в Трактиръ с использованием драйвера штрихкода 1С"},
          { open: false, enabled: true, index: 3, name: "Подключение и регистрация", 
            description:"Учет крепкого алкогольной продукции в розничных магазинах и общепите. Создание алкогольных диклораций, сканирование штрих кодов и акцизных марок", 
            description2:"Подключение к ЕГАИС", 
            description3:"Единая государственная автоматизированная информационная система, для контроля оборота спиртосодержащей продукции в Р.Ф.",
            description4:"Регистрация Честного знака",
            description5:"Зарегистрируем во всех системах для работы с маркировкой, оформим электронную подпись, настроим кассу и зарегистрируем её в налоговой",
            description6:"Подключение к ЭДО",
            description7:"Поможем подготовить локальные нормативные акты и соглашения с сотрудниками. Поддерживаем все типы электронных подписей: УКЭП, УНЭП, «Госключ» и «Госуслуги». Настроим интеграцию с вашей учетной системой",
          },
          { open: false, enabled: true, index: 4, name: "Обучение", 
            description:"Проводем полный цикл обучения сотрудников и затроним самые часто задаваемые вопросы. Выявив нюансы в повторяющихся ошибках опираясь на большой опыт ныших специалистов", 
            description2:"Обучение по работе с системой маркировки.",
            description3:"Обучение сотрудников ЭДО",
            description4:"Ообучение по работе с системой ЕГАИС",
          },
          { open: false, enabled: true, index: 5, name: "Сопровождение 14 дней", 
            description:"Возмем на себя все сложные рабочие процессы и окажаем полную техническую подержку, как в работе самого програмного продукта, так и работу оборудования. Выполним обновления и устраним дефекты оперционных частей",
            description2:"Служба поддержки на сайте - да",
            description3:"Поддержка по телефону - да",
            description4:"Поддержка в чате: 1сКоннект, AnyDesk, Битрикс, Telegramm, WhatsApp - да",
            description5:"Поддержка по e-mail - да",
            description6:"Удаленная поддержка - да",
            description7:"Время сопровождения с 9 до 18 часов - (5/7) Рабочие дни.",
            description8:"Рабочие дни. Дополнительные работы - 2500",
          },
          
        ],
        href:"services/integration/bp?packet=1",
        attentions: [
          {
            description:[
              {
                text: 'Программа Трактиръ не входит в стоимость',
              },           
            ],
          },
          {
            description:[
              {
                text: 'Дополнительные лицензии приобретаются отдельно',
              },  
            ],
          },
          
        ],
      },
      {
        title: "Внедрение 1С:Трактиръ",
        type: 'Пакет "Полный"',
        image: "assets/services/Group 380.png",
        description: '',
        description_detail: "Пакет “полный” - включает в себя все основные настройки продукта и подключения оборудования. В пакет входит расширенное техническое сопровождение",
        footer: "",
        price: 45000,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Базовый блок (Аудит)", description:"Первый этап работы который включает в себя некоторое количество мероприятий, которые позволяют оптимизировать процесс внедрения и выявить нюансы и потребности у клиента. В пакет входит анализ установленного оборудования и работы по сети "},
          { open: false, enabled: true, index: 1, name: "Рабочий блок: до 5-ти фронтов", description:"Back-Office / Front-Office. Автоматизация всего процесса обслуживания гостей, от разработки плана зала и формирования меню заведения, до получения всей необходимой отчетности по расходу блюд, выручке и занятости персонала. Ведение бухгалтерского учета на предприятиях общественного питания ", description2:"Подключение до 2х-фронтов (рабочих мест)"},
          { open: false, enabled: true, index: 2, name: "Оборудование ", description:"Настройка и подключение оборудования и режима работы по сети", description2:"Настройка ККМ - Услуга подключения и дальнейшего обслуживания контрольно-кассового оборудования для осуществления корректной эксплуатации", description3:"Настройка Сканеров - Подключение 2D сканера штрихкодов в Трактиръ с использованием драйвера штрихкода 1С"},
          { open: false, enabled: true, index: 3, name: "Подключение и регистрация", 
            description:"Учет крепкого алкогольной продукции в розничных магазинах и общепите. Создание алкогольных диклораций, сканирование штрих кодов и акцизных марок", 
            description2:"Подключение к ЕГАИС", 
            description3:"Единая государственная автоматизированная информационная система, для контроля оборота спиртосодержащей продукции в Р.Ф.",
            description4:"Регистрация Честного знака",
            description5:"Зарегистрируем во всех системах для работы с маркировкой, оформим электронную подпись, настроим кассу и зарегистрируем её в налоговой",
            description6:"Подключение к ЭДО",
            description7:"Поможем подготовить локальные нормативные акты и соглашения с сотрудниками. Поддерживаем все типы электронных подписей: УКЭП, УНЭП, «Госключ» и «Госуслуги». Настроим интеграцию с вашей учетной системой",
          },
          { open: false, enabled: true, index: 4, name: "Обучение", 
            description:"Проводем полный цикл обучения сотрудников и затроним самые часто задаваемые вопросы. Выявив нюансы в повторяющихся ошибках опираясь на большой опыт ныших специалистов", 
            description2:"Обучение по работе с системой маркировки.",
            description3:"Обучение сотрудников ЭДО",
            description4:"Ообучение по работе с системой ЕГАИС",
          },
          { open: false, enabled: true, index: 5, name: "Сопровождение 30 дней", 
            description:"Возмем на себя все сложные рабочие процессы и окажаем полную техническую подержку, как в работе самого програмного продукта, так и работу оборудования. Выполним обновления и устраним дефекты оперционных частей",
            description2:"Служба поддержки на сайте - да",
            description3:"Поддержка по телефону - да",
            description4:"Поддержка в чате: 1сКоннект, AnyDesk, Битрикс, Telegramm, WhatsApp - да",
            description5:"Поддержка по e-mail - да",
            description6:"Удаленная поддержка - да",
            description7:"Время сопровождения с 9 до 18 часов - (5/7) Рабочие дни.",
            description8:"Рабочие дни. Дополнительные работы - 2500",
          },
          
        ],
        href:"services/integration/hotel?packet=2",
        attentions: [
          {
            description:[
              {
                text: 'Программа Трактиръ не входит в стоимость',
              },           
            ],
          },
          {
            description:[
              {
                text: 'Дополнительные лицензии приобретаются отдельно',
              },  
            ],
          },
          
        ],
      },
    ],
    training:[
      {
        title: "Обучение 1С: Отель",
        image:"assets/services/Group 439.png",
        type: 'День 1 “Введение”',
        description: '',
        description_detail: "В данном курсе мы знакомим пользователя с программой 1С:Отель, затрагиваем темы основного меню и работы с “Фронт-Офис”. Говорим о свободных номерах, журнале бронирования и управление бронями. Подводим итоги и разбираем вопросы",
        footer: "",
        price: 13500,
        blocks: [
          { open: true, enabled: true, index: 0, name: "Введение в интерфейс программы", 
            description:"Запуск программы 1С: Отель",
            description2:"Обзор основного окна программы",
            description3:"Основные области экрана",
            description4:"Начальная страница",
            description5:'Как вернуться на начальную страницу из любого места программы (закладка "Начальная страница")',
            description6:'Главное меню',
            description7:'Пункты меню, доступные сотрудникам службы приема и размещения: "Главное" и "Фронт-офис"',
            description8:'Блок "Загрузка"',
            description9:'Отображение уровня загрузки отеля',
          },
          { open: false, enabled: true, index: 1, name: 'Раздел "Фронт-офис"', 
            description:"Основные инструменты для сотрудников ресепшен",
            description2:"Обзор функций, с которыми предстоит познакомиться в дальнейшем обучении",
            description3:'Навигация по меню "Фронт-офис"',
          },
          { open: false, enabled: true, index: 2, name: "Проверка наличия свободных номеров", 
            description:'Использование формы "Справка о наличии свободных номеров"',
            description2:'Открытие формы: "Фронт-офис" - "Что свободно?"',
            description3:"Возможности формы",
            description4:"Узнать наличие свободных номеров на конкретные даты",
            description5:'Узнать стоимость проживания в номерах',
            description6:'Создать бронь на выбранные даты',
            description7:'Практическое задание',
            description8:'Поиск свободных номеров на заданные даты',
            description9:'Определение стоимости проживания',
          },
          { open: false, enabled: true, index: 3, name: 'Работа с "Журналом брони"', 
            description:'Открытие "Журнала брони"', 
            description2:'Навигация: "Фронт-офис" - "Журнал брони"', 
            description3:"Создание брони",
            description4:'Из "Журнала брони"',
            description5:'Из формы "Что свободно"',
            description6:'Из "Карты номерного фонда"',
            description7:"Алгоритм ввода гостя",
            description9:"Особенности заполнения основных параметров брони",
          },
          { open: false, enabled: true, index: 4, name: "Управление бронями", 
            description:"Поиск брони", 
            description2:"Использование фильтров и критериев поиска",
            description3:"Изменение статуса брони",
            description4:"Возможные статусы и их назначение",
            description5:"Варианты создания брони",
            description6:"На физическое лицо",
            description7:"На контрагента",
            description8:"Групповая бронь",
            description9:"Практическое задание",
            description10:"Создание разных типов броней",
            description11:"Изменение статусов существующих броней",
          },
          { open: false, enabled: true, index: 5, name: "Финансовые операции по брони", 
            description:"Формирование счета",
            description2:"Печать и отправка счета клиенту",
            description3:"Подтверждение брони",
            description4:"Процедура подтверждения и необходимые документы",
            description5:"Оплата по брони",
            description6:"Прием оплаты",
            description7:"Отражение оплаты в системе",
          },
          { open: false, enabled: true, index: 6, name: "Подведение итогов и вопросы", 
            description:"Обсуждение пройденного материала",
            description2:"Ответы на вопросы слушателей",
            description3:"Домашнее задание",
            description4:"Повторить пройденные темы",
            description5:"Подготовить вопросы для следующего занятия",
          },
          
        ],
        href:"services/integration/bp?packet=0",
        attentions: [
          {
            description:[
              {
                text: 'Курс проводится в режиме онлайн, длится 3 часа без учета времени на перерыв - вопрос/ответ',
             
              },           
            ],
          },
          {
            description:[
              {
                text: 'Полный курс обуения состоящий из 3 частей',
                text1: 'Стоимость : ',
                text2: '36000 ₽',
                text3: '5500',
                text4: '41500',
              },  
            ],
          },
          
        ],
      },
      {
        title: "Обучение 1С: Отель",
        type: 'День 2 Размещение',
        image: "",
        description: '',
        description_detail: "В этом курсе детально разбираем систему размещения гостей, фильтрацию и поиск. Говорим о шаблонах и формах. Выполняем поселение и размещаем гостей, детально изучаем все виды поселения и работу с ними, а так же продление проживания и выселение. Подводим итоги и разбираем вопросы",
        footer: "",
        price: 13500,
        blocks: [
          { open: true, enabled: true, index: 0, name: 'Работа с размещениями и журналом "Фронт-офис"', 
            description:'Обзор журнала "Фронт-офис"',
            description2:"Разделы",
            description3:"Заезд - отображает брони с заездом на сегодня",
            description4:"Выезд - показывает размещения с выездом на сегодня",
            description5:'Проживающие - список текущих гостей',
            description6:'Архив - содержит информацию о выехавших гостях и аннуляциях',
          },
          { open: false, enabled: true, index: 1, name: 'Поиск и фильтрация', 
            description:"Использование различных фильтров для поиска (клиент, контрагент, телефон, группа, номер комнаты, тип номера и др.)",
            description2:"Расширенный поиск и его возможности",
          },
          { open: false, enabled: true, index: 2, name: "Настройка отображения", 
            description:'Кнопка "Итоги" - получение сводной информации',
            description2:'Флаг "Показать только переселения" - отображение только переселений на текущий день',
            description3:"Отображение по номерам и по гостям",
            description4:"Важность заполнения шаблона у основного гостя в номере",
            description5:'Сортировка по колонкам (например, по дате заезда)',
            description6:'Настройка формы',
            description7:'Опция "Еще" - "Изменить форму"',
            description8:'Перемещение и настройка колонок',
          },
          { open: false, enabled: true, index: 3, name: 'Процессы поселения и размещения гостей', 
            description:'Заполнение данных гостя', 
            description2:'Паспортные данные и их корректное внесение', 
            description3:"Прописка с использованием классификатора",
            description4:'Дата рождения и её влияние на курортный сбор',
          },
          { open: false, enabled: true, index: 4, name: "Типы поселения", 
            description:"Поселение по брони", 
            description2:'Поселение "с улицы"',
            description3:"Процедура поселения без предварительной брони",
            description4:"Поселение от контрагента",
            description5:"Особенности размещения гостей без брони от контрагентов",
            description6:"Выбор вида размещения при поселении одного гостя",
            description7:"Разделение доходов по проживанию дополнительных гостей",
            description8:"Мастер переселения",
            description9:"Переселение в другой номер той же или другой категории",
            description10:"Групповое переселение",
            description11:"Пример с переселением 4 гостей в разные номера",
            description12:"Автоматическая корректировка цены программой",
          },
          { open: false, enabled: true, index: 5, name: "Продление срока проживания", 
            description:"Процедура изменения даты выезда",
          },
          { open: false, enabled: true, index: 6, name: "Выселение гостей", 
            description:"Частичное выселение",
            description2:"Выселение одного гостя из номера",
            description3:"Особенности выселения",
            description4:"Для контрагентов - создание отдельной брони",
            description5:"Для физических лиц - изменение даты выезда и прием оплаты",
          },
          
        ],
        href:"services/integration/bp?packet=1",
        attentions: [
          {
            description:[
              {
                text: 'Курс проводится в режиме онлайн, длится 3 часа без учета времени на перерыв - вопрос/ответ',
             
              },           
            ],
          },
          {
            description:[
              {
                text: 'Полный курс обуения состоящий из 3 частей',
                text1: 'Стоимость : ',
                text2: '36000 ₽',
                text3: '5500',
                text4: '41500',
              },  
            ],
          },
          
        ],
      },
      {
        title: "Обучение 1С: Отель",
        type: 'День 3 “Гости и услуги”',
        image: "assets/services/Group 380.png",
        description: '',
        description_detail: "В заключительном курсе проработаем все тонкости с карточкой гостя, затронем тему счетов и начислений. Научимся создавать услуги и управлять ими. Обговорим дополнительные возможности с работой филио. Пройдем полный путь работы с платежными и кассовыми операциями. Подводим итоги и разбираем вопросы",
        footer: "",
        price: 13500,
        blocks: [
          { open: true, enabled: true, index: 0, name: 'Работа с карточкой гостя и курортным сбором ', 
            description:'Карточка гостя',
            description2:"Присвоение индивидуальной скидки (например, постоянному гостю)",
            description3:"Маркетинговые направления",
            description4:'Установка флагов "Постоянный гость", "Черный список", "Отказ от рассылки"',
            description5:'История изменений',
            description6:'Просмотр и анализ изменений в данных гостя',
            description7:'Открытие сканов документов непосредственно из карточки',
            description8:'Курортный сбор',
            description9:'Автоматическое освобождение от курортного сбора по возрасту и прописке',
            description10:'Освобождение индивидуально на гостя',
            description11:'Через открытие индивидуальной карточки гостя',
            description12:'Отчеты по курортному сбору',
            description13:'Основной и детальный отчеты',
          },
          { open: false, enabled: true, index: 1, name: 'Управление размещениями и фолио', 
            description:"Поиск и открытие размещений",
            description2:"Поиск размещения по различным критериям",
            description3:"Открытие карточки группы из информационного раздела",
            description4:"Работа с фолио и лицевыми счетами",
            description5:"Переход в фолио без открытия документа размещения",
            description6:"Начисление услуг",
            description7:"Одной услуги",
            description8:"Нескольких услуг на одну дату",
            description9:"Сторнирование услуг",
            description10:"Процедура отмены начисленных услуг",
            description11:"Перенос услуг:",
            description12:"Перетаскивание мышью между фолио",
            description13:"Перенос депозита и зачет аванса",
            description14:"Оплаты и возвраты",
            description15:"Прием оплаты и внесение аванса",
            description16:"Возврат денежных средств",
          },
          { open: false, enabled: true, index: 2, name: "Дополнительные возможности", 
            description:'Работа с двумя окнами фолио для удобства управления',
            description2:'Создание дополнительных фолио для гостей',
            description3:"Печать документов из фолио:",
            description4:"Счета, акты и другие печатные формы",
          },
          { open: false, enabled: true, index: 3, name: 'Продвинутые функции размещения ', 
            description:'Автоматическое назначение номеров комнат', 
            description2:'Раздел "Заезд" - "Действия" - "Назначить номера комнат"', 
            description3:"Переселения и продление",
            description4:'Переселение в другой номер',
            description5:'Процедура и особенности',
            description6:'Продление проживания и его отражение в системе',
            description7:'Специальные случаи',
            description8:'Ранний заезд и поздний выезд',
            description9:'Автоматические настройки и их применение',
            description10:'Строго по брони',
            description11:'Особенности размещения при этом условии',
            description12:'Мастер переселения',
            description13:'Выселение одного гостя из номера, когда другие остаются',
            description14:'Создание дополнительной брони на ту же группу через "Действия"',
          },
          { open: false, enabled: true, index: 4, name: "Работа с платежами и кассовыми операциями", 
            description:'Раздел "Платежи и возвраты"', 
            description2:'Навигация через "Фронт-офис"',
            description3:"Отложенные чеки",
            description4:"Обработка непроведенных документов",
            description5:"Проведение и фискализация чеков",
            description6:"Кассовые операции",
            description7:"Внесение наличных и инкассация",
            description8:"Закрытие кассовой смены",
            description9:"Печать X-отчета по фискальному регистратору и программе",
            description10:"Сверка итогов и закрытие смены",
            description11:"Регистрация иностранных граждан",
            description12:"Выгрузка данных в Контур ФМС",
            description14:"Обработка ошибок непосредственно из отчета",
          },
          { open: false, enabled: true, index: 5, name: "Создание и настройка услуг", 
            description:"Создание услуг",
            description2:"С нуля и путем копирования существующих",
            description3:"Дополнительные параметры услуг",
            description4:"Учетные параметры",
            description5:"Тип, секция, правила вычисления",
            description6:"Флаги и их назначение",
            description7:"Разбор всех доступных опций",
            description8:"Доступность количества в день",
            description9:"Регистрация услуг",
            description10:"Значение и применение",
            description11:"Печать счетов и чеков",
            description12:"Настройки печати",
            description13:"Сворачивание услуг (например, завтрак или обед в проживание)",
            description14:"Примеры печати счета на оплату",
          },
          { open: false, enabled: true, index: 6, name: "Подведение итогов и ответы на вопросы", 
            description:"Обсуждение сложных моментов и нюансов работы",
            description2:"Ответы на вопросы слушателей",
            description3:"Домашнее задание",
            description4:"Повторить пройденные темы",
            description5:"Подготовить вопросы или ситуации для обсуждения на следующем занятии",
          },
          
        ],
        href:"services/integration/hotel?packet=2",
        attentions: [
          {
            description:[
              {
                text: 'Курс проводится в режиме онлайн, длится 3 часа без учета времени на перерыв - вопрос/ответ',
             
              },           
            ],
          },
          {
            description:[
              {
                text: 'Полный курс обуения состоящий из 3 частей',
                text1: 'Стоимость : ',
                text2: '36000 ₽',
                text3: '5500',
                text4: '41500',
              },  
            ],
          },
          
        ],
      },
    ],
  }

  public elements: any = {
    
    // Желтый квадратный блок
    yellow: [
      {title:"ПЕЧАТНЫЕ ФОРМЫ", type:"Создание", href:"services/programming/extensions", 
        description: "Разработаем и внедрим индвидуальные макеты печатных форм"},
      {title:"НАСТРОЙКА ОБМЕНА 1С", type:"Создание и управление", href:"services/programming/processing", 
        description: "Создадим контейнер обмена данных по вашей структуре"},
      {title:"ОБРАБОТКИ", type:"Внешние и внутренние", href:"services/programming/processing",
        description: "Прикладные решения без изменения структуры программы"},
    ],

    // Заголовок и текст с кнопкой подробнее
    minimize: [
      {title:"Интеграция между 1С: Отель и Битрикс 24 Двухсторонний обмен бронированием", new: false, href:"", 
        description:"Наша разработка позволяет автоматизировать сложные процессы в единый организм, значительно повышая эффективность продаж и управление отелем в целом"},
     
      {title:"Интеграция 1С:Отель и Контролем доступа Электронные замки и шлагбаумы", new: false, href:"", 
        description:"Для упрощения работы с системами контроля доступа на территории отеля, нами разработана интеграция между программой 1С:Отель, электронными замками и шлагбаумами"},
      
      {title:"Система Быстрых Платежей “СПБ” Интеграция для модуля", new: true, href:"", 
        description:'Гости наших клиентов уже используют систему СПБ при оплате онлайн через модуль бронирования, что значительно упрощает рабочий процесс и исключает человеческий фактор Аттестованы франчайзи фирмы "1С"'},
        
      {title:"Разработка фактического учета питания в 1С:Отель", new: true, href:"services/integration/hotel", 
        description:'Нетиповое решение, для учета фактического питания '},
        
      {title:"Формирование сделки в Битрикс 24, выгрузка в 1С", new: false, href:"services/integration/bitrix24", 
        description:'Создание сделки бронирования напрямую через форму в CRM'},
        
      {title:"Формирование сделки в Битрикс 24, выгрузка в 1С", new: false, href:"services/integration/bitrix24", 
        description:'test'},

      {title:"1С:Отель + контроль доступа гостей", new: true, href:"services/integration/hotel", 
        description:'Интеграция с картами гостя, замками и шлагбаумами на территории отеля'},
        
      {title:"Формирование сделки в Битрикс 24", new: false, href:"services/integration/bitrix24", 
        description:'Создание сделки бронирования напрямую в CRM, а так же изменение статуса брони'},
         
    ],

    dicisions:[
      {
        title:"ВНЕДРЕНИЕ И СОПРОВОЖДЕНИЕ",
        description:"Комплексная поддержка клиентов",
        image:"/assets/main/product1.png",
        text:"Качественное сопровождение и обучение персонала.Внедрение программы на любом этапе работ",
        href:"services/integration/hotel",
        type:"Услуги",
      },
      {
        title:"БИТРИКС 24 ИНТЕГРАЦИЯ CRM",
        description:"Автоматизация и структурирование бизнес-процессов",
        image:"/assets/main/product2.png",
        text:"Индивидуальный сценарий всех этапов. Формирование воронок продаж, работа с данными",
        href:"services/integration/bitrix24",
        type:"Интеграции",
      },
      {
        title:"ПРОГРАММНЫЕ ПРОДУКТЫ",
        description:"1С:Отель, 1С:Розница, 1С:Трактиръ, Битрикс 24, liko",
        image:"/assets/main/product3.png",
        text:"Комплекс продуктов 1С. Типовые и индивидуальные конфигурации. Популярные CRM-системы",
        href:"services/integration/hotel",
        type:"Программы",
      },
      {
        title:"ОБОРУДОВАНИЕ И ПОДКЛЮЧЕНИЕ",
        description:"Кассы-онлайн, сканеры штрих кодов, терминалы",
        image:"/assets/main/product4.png",
        text:"Подберем оборудование по вашим требованиям, настроем работу по сети, поможем с регистрацией",
        href:"services/integration/hotel",
        type:"Оборудование",
      },
      {
        title:"СИСТЕМЫ КОНТРОЛЯ ДОСТУПА",
        description:"Электронные замки, турникеты, карты гостя",
        image:"/assets/main/product5.png",
        text:"Интеграция между системами контроля доступа и 1С: Отель. Программирование карт гостей",
        href:"services/integration/hotel",
        type:"Интеграции",
      },
      {
        title:"МОДУЛЬ БРОНИРОВАНИЯ",
        description:"Бронирование онлайн, менеджеры каналов + 1С:Отель",
        image:"/assets/main/product6.png",
        text:"Прямое бронирование с сайта без комиссии. Гибкая система оплаты, встроенные инструменты продаж",
        href:"services/integration/hotel",
        type:"Интеграции",
      },
    ],

    presents: [
      {
        title: "1C:ОТЕЛЬ",
        type: "ИНТЕГРАЦИИ",
        image: "/assets/main/1.png",
        description: "Прямые интеграции 1С:Отель + кассы, замки, менеджеры каналов, битрикс 24, модуль бронирования",
        href: "services/integration/hotel",  
      },
      {
        title: "ПРОГРАММИРОВАНИЕ",
        type: "РАЗРАБОТКА",
        image: "/assets/main/2.png",
        description: "Индивидуальная разработка нетиповых решений под нужды клиента, от печатных форм до собственных конфигураций",
        href: "services/integration/hotel",  
      },
      {
        title: "ОБОРУДОВАНИЕ",
        type: "ПОДКЛЮЧЕНИЕ",
        image: "/assets/main/3.png",
        description: "Регистрация и подключение онлайн-касс. Маркировка 1С. Настройка сетевого режима и Web сервиса",
        href: "services/integration/hotel",  
      },
      {
        title: "1С:ОТЕЛЬ",
        type: "МОДУЛЬ БРОНИРОВАНИЯ",
        image: "/assets/main/4.png",
        description: "Прямые бронирования с сайта, без комиссии и ограничений. Интеграция с 1С:Отель и менеджерами каналов",
        href: "services/integration/hotel",  
      },
    ],

    chapters: [
      {
        title: [
          "Синхронизация с картами гостя",
          "Онлайн-контроль посещаемости",
          "Интеграция с программой 1С:Отель"
        ],
        description: [
          "Электронные замки должны обеспечивать высокий уровень безопасности и удобства. Даже дети должны иметь возможность легко использовать оборудование. Умные замковые системы такие как должны быть надежными и устойчивыми к взлому и другим видам атак. Высокий показатель совместимости с различными системами управления гостиницей, чтобы обеспечить простоту интеграции",
          "Реализованные нами интеграции с системами:"
        ] ,
        links: [
          {text: "Norweq VingCard", href:""},
          {text: "HuneLock", href:""},
          {text: "Davinci", href:""},
          {text: "ProRFL", href:""},
          {text: "Kaba Ilco", href:""},
          {text: "OmniTec", href:""},
          {text: "IronLogic", href:""},
        ],
        link:{
          text: "ПЕРЕЙТИ В РАЗДЕЛ ИНТЕГРАЦИИ", href:""
        },
        image: "assets/main/chapter-hotel.png",
      },
      {
        title: [
          "Синхронизация с картами гостя",
          "Онлайн-контроль посещаемости",
          "Интеграция с программой 1С:Отель"
        ],
        description: [
          "Электронные замки должны обеспечивать высокий уровень безопасности и удобства. Даже дети должны иметь возможность легко использовать оборудование. Умные замковые системы такие как должны быть надежными и устойчивыми к взлому и другим видам атак. Высокий показатель совместимости с различными системами управления гостиницей, чтобы обеспечить простоту интеграции",
          "Реализованные нами интеграции с системами:"
        ] ,
        links: [
          {text: "Norweq VingCard", href:""},
          {text: "HuneLock", href:""},
          {text: "Davinci", href:""},
          {text: "ProRFL", href:""},
          {text: "Kaba Ilco", href:""},
          {text: "OmniTec", href:""},
          {text: "IronLogic", href:""},
        ],
        link:{
          text: "ПЕРЕЙТИ В РАЗДЕЛ ИНТЕГРАЦИИ", href:""
        },
        image: "assets/main/chapter-hotel.png",
      },
      

    ],
    bannerAnimation: [
      {
        title: [
          "СКУД - СИСТЕМА УПРАВЛЕНИЯ КОНТРОЛЕМ ДОСТУПА",
        
        ],
        description: [
          "Электронные замки должны обеспечивать высокий уровень безопасности и удобства. Даже дети должны иметь возможность легко использовать оборудование. Умные замковые системы такие как должны быть надежными и устойчивыми к взлому и другим видам атак. Высокий показатель совместимости с различными системами управления гостиницей, чтобы обеспечить простоту интеграции",
          "Реализованные нами интеграции с системами:"
        ] ,
        links: [
          {text: "Norweq VingCard", href:""},
          {text: "HuneLock", href:""},
          {text: "Davinci", href:""},
          {text: "ProRFL", href:""},
          {text: "Kaba Ilco", href:""},
          {text: "OmniTec", href:""},
          {text: "IronLogic", href:""},
        ],
        link:{
          text: "ПЕРЕЙТИ В РАЗДЕЛ ИНТЕГРАЦИИ", href:""
        },
        image: "assets/main/chapter-hotel.png",
      },
      {
        title: [
          "Синхронизация с картами гостя",
          "Онлайн-контроль посещаемости",
          "Интеграция с программой 1С:Отель"
        ],
        description: [
          "Электронные замки должны обеспечивать высокий уровень безопасности и удобства. Даже дети должны иметь возможность легко использовать оборудование. Умные замковые системы такие как должны быть надежными и устойчивыми к взлому и другим видам атак. Высокий показатель совместимости с различными системами управления гостиницей, чтобы обеспечить простоту интеграции",
          "Реализованные нами интеграции с системами:"
        ] ,
        links: [
          {text: "Norweq VingCard", href:""},
          {text: "HuneLock", href:""},
          {text: "Davinci", href:""},
          {text: "ProRFL", href:""},
          {text: "Kaba Ilco", href:""},
          {text: "OmniTec", href:""},
          {text: "IronLogic", href:""},
        ],
        link:{
          text: "ПЕРЕЙТИ В РАЗДЕЛ ИНТЕГРАЦИИ", href:""
        },
        image: "assets/main/chapter-hotel.png",
      },
      

    ],
    photos: [
      "assets/main/Rectangle 71.png",
      "assets/main/bann.png",
      "assets/main/Rectangle 71.png",
      "assets/main/bann.png",
      
      
    ],

    cardsForSlider:[
      {
        title:"Парковка в 1С: Отель (автоматизация)",
        description:"Комплексная поддержка клиентов",
        image:"assets/pages/o-nas/New1.jpg",
        text:"Разработанное нами расширение для программы 1С: Отель, уже помогает нашим клиентам в автоматическом контроле, бронированию и учету парк. мест в отелях",
        href:"",
        type:"Услуги",
      },
      {
        title:"Интеграция 1С: Отель и CRM",
        description:"Автоматизация и структурирование бизнес-процессов",
        image:"assets/pages/o-nas/New2.jpg",
        text:"Работая в программе 1С: Отель, у специалистов есть возможность отправить данные сразу в Битрикс 24 и уже в CRM системе управлять сделкой по воронкам продаж",
        href:"",
        type:"Интеграции",
      },
      {
        title:"Большое обновление - ноябрь 2024",
        description:"1С:Отель, 1С:Розница, 1С:Трактиръ, Битрикс 24, liko",
        image:"assets/pages/o-nas/New3.jpg",
        text:"Близится большое запланированное обновление (v2.1) к своему завершению. С небольшим запозданием, но уже совсем скоро - новый дизайн и возможности модуля бронирования",
        href:"",
        type:"Программы",
      },
      {
        title:"Персонализация гостя (карты)",
        description:"Кассы-онлайн, сканеры штрих кодов, терминалы",
        image:"assets/pages/o-nas/New4.jpg",
        text:"Проделана большая работа по разработке и внедрению расширения для назначения индивидуальных данных для каждого гостя для системы УКД в 1С: Отель",
        href:"",
        type:"Оборудование",
      },
     
    ],
    elFullWithSlider:[
      {
        image:"assets/pages/partners/логоП1.png",
        title:"Решения для автоматизации ресторанного бизнеса",
        text:"iiko - специализированная система ERP-класса,предназначенная для автоматизации ресторанного бизнесаКасса, склад, персонал, кухня, финансы, отчетность - все в единой системе.",
        text2:"Для решения ваших ежедневных задач и планирования бизнеса в iiko используется искусственный интеллект. Теперь у вас будет еще больше времени для гостей и развития бизнеса!",
        href:"https://iiko.ru/",
        linkText:"Официальный сайт",
      },
      {
        image:"assets/pages/partners/логоП2.png",
        title:"Сбер — помогает человеку, бизнесу и стране ",
        text:"СберБанк — крупнейший банк в России, Центральной и Восточной Европе, один из ведущих международных финансовых институтов ",
        text2:"Предлагает клиентам продукты и услуги Сбера, помогает с их оформлением и совершает транзакционные операции в рамках оформления продаж",
        href:"http://www.sberbank.ru/ru/person",
        linkText:"Официальный сайт",
      },
      {
        image:"assets/pages/partners/логоП3.png",
        title:"Битрикс — одна излучших CRM систем",
        text:"«Битрикс24» — это CRM-система на основе облачного сервиса. Разработан специально для того, чтобы максимально оптимизировать работу в компании. И не просто оптимизировать, а связать воедино различные процессы в разных отделах, устранить несогласованность и разнобой.",
        text2:"Функции CRM позволяют отслеживать прохождение рабочих процессов, анализировать сделки, получать срез целевой аудитории.",
        href:"https://www.bitrix24.ru/",
        linkText:"Официальный сайт",
      },
      {
        image:"assets/pages/partners/логоП4.png",
        title:"«1С» — это группа программ для автоматизации бизнес-процессов",
        text:"Конфигурации — это прикладные программы. Каждая из них решает те или иные задачи бизнеса. Есть конфигурации для бухгалтерии, учёта кадров, управления производством и множество других",
        text2:"Примеры типовых конфигураций: «1С:Бухгалтерия», «1С:Зарплата и управление персоналом», «1С:Касса». Типовых конфигураций — десятки",
        href:"https://1c.ru/",
        linkText:"Официальный сайт",
      },
     
    ],
    partnersSlider:[
      "assets/pages/partners/Logo1.png",
     "assets/pages/partners/Logo2.png",
     "assets/pages/partners/Logo3.png",
     "assets/pages/partners/Logo4.png",
     "assets/pages/partners/Logo5.png",
     "assets/pages/partners/Logo6.png",
     "assets/pages/partners/Logo7.png"
    ],
  }
   //блок табов
  //для каждой новой страницы свой вывод массива с продуктами
  public productLic:any = [
    {
      button:"Дополнительная поставка клиенской лицензии",
      products:["lic-mn-polyzivatelskaya-1", "lic-mn-polyzivatelskaya-2","lic-mn-polyzivatelskaya-3","lic-mn-polyzivatelskaya-4","lic-mn-polyzivatelskaya-5","lic-mn-polyzivatelskaya-6","lic-mn-polyzivatelskaya-7","lic-mn-polyzivatelskaya-8",]
    },
    {
      button:"Основная поставка клиенские лицензии",
      products:["lic-1-polyzivatelskaya"]
    },
   
    {
      button:"Лицензии КОРП",
      products:["lic-korp-1","lic-korp-2","lic-korp-3","lic-korp-4","lic-korp-5","lic-korp-6","lic-korp-7","lic-korp-8","lic-korp-9",]
    },
    {
      button:"Сервер МИНИ на 5 подключений",
      products:["lic-mini-server-1", "lic-mini-server-2"]
    },
    {
      button:"Серверная лицензия на 32-разрядный сервер",
      products:["lic-server32-1"]
    },
    {
      button:"Серверная лицензия на 64-разрядный сервер",
      products:["lic-server64-1", "lic-server64-2"]
    },
    {
      button:"Лицензии для мобильных рабочих мест",
      products:["lic-mobile-1", "lic-mobile-2","lic-mobile-3", "lic-mobile-4", "lic-mobile-5"]
    },
  ];
  
  //для каждой новой страницы свой вывод массива с продуктами
  public product1c:any = [
    {
      button:"button1",
      products:["123", "2900002083934"]
    },
    {
      button:"button2",
      products:["123", "2900002083934"]
    },
    {
      button:"button3",
      products:["123", "2900002083958"]
    },
  ]

  public products: any = [
    //---------------------услуги по кассам-------------------
    {
      id:"registration-kassa-fns",
      href:"/",
      image:"assets/pages/registration-fns/2.jpg",
      name:"РЕГИСТРАЦИЯ КАССЫ В НАЛОГОВУЮ СЛУЖБУ ФНС",
      description:"Регистрация кассы в ФНС — услуга постановки контрольно-кассового оборудования на учет в налоговой службе. Предоставляется удаленно, при наличии у клиента ЭЦП, стабильной интернет-связи, возможности подключения онлайн-кассы к своему компьютеру. Обязательное условие — действующий договор с ОФД. Действие услуги распространяется на все регионы России.",
      price: 3000,
    
    },
    {
      id:"registration-kassa-kkm",
      href:"/",
      image:"assets/pages/registration-fns/4.jpg",
      name:"НАСТРОЙКА ККМ",
      description:"Настройка ККМ —  услуга подключения и дальнейшего обслуживания контрольно-кассового оборудования для осуществления корректной эксплуатации. Настройка онлайн-кассы позволяет повысить эффективность работы кассира по обслуживанию клиентов в розничных магазинах, организациях общественного питания, салонах красоты и других организациях. Процедура расширяет возможности использования торгового устройства",
      price: 3000,
    
    },
    {
      id:"registration-kassa-1C",
      href:"/",
      image:"assets/pages/registration-fns/5.jpg",
      name:"ПОДКЛЮЧЕНИЕ К ПРОГРАММЕ  1С",
      description:"Подключение фискального регистратора",
      description2:"Установка драйвера",
      description3:"Подключение к программе 1С",
      description4:"Настройка 1С для работы с фискальным регистратором",
      price: 3000,
    
    },
    {
      id:"registration-kassa-ofd",
      href:"/",
      image:"assets/pages/registration-fns/3.jpg",
      name:"ПОДКЛЮЧЕНИЕ КАССЫ ОНЛАЙН К ОФД",
      description:"Подключение к ОФД — услуга, которую предоставляют специалисты нашей компании, по подключению всех типов ККТ к оператору фискальных данных. В соответствии с 54-ФЗ каждый кассовый аппарат обязан передавать данные о чеках и другой документации исключительно через ОФД",
      price: 3000,
    
    },

     //---------------------услуги по питанию-------------------
     {
      id:"connection-egais",
      href:"/",
      image:"assets/pages/programs/3.jpg",
      name:"ПОДКЛЮЧЕНИЕ К ЕГАИС",
      description:"Специалисты нашей компании помогут вам выбрать и установить программное обеспечение, приобрести необходимое оборудование. Помимо этого к нам вы можете обратиться за помощью в подготовке алкогольных и пивных деклараций в ФСРАР. Упростите прохождение регистрации в ЕГАИС: Оформить КЭП - Регистрация в ЛК - Записать RSA - Настроить учетную систему с УТМ ЕГАИС и другое",
      price: 5000,
    
    },
    {
      id:"connection-chestyi-znak",
      href:"/",
      image:"assets/pages/programs/1.jpg",
      name:"РЕГИСТРАЦИЯ ЧЕСТНЫЙ ЗНАК",
      description:"Консультируем всех участников маркировки товаров, поможем преодолеть трудности на начальном этапе: от настройки электронной подписи (КЭП) до фиксации остатков. ",
      description2:"Настроим: Получение квалифицированной электронной подписи",
      description3:"Заполнение профиля в Честном знаке - Настройка прав пользователя Честного знака - Получение номера GLN - Обучение работе с Честным знаком- Настройка электронного документооборота - Передача кодов маркировки",
      price: 7000,
    
    },
    {
      id:"connection-edo",
      href:"/",
      image:"assets/pages/programs/2.jpg",
      name:"ПОДКЛЮЧЕНИЕ К ЭДО",
      description:"Электронный документооборот работает с помощью специальных онлайн-сервисов — операторов ЭДО. Наиболее популярные системы: «Контур.Диадок», «СФЕРА.Курьер» («СберКорус») и «СБИС». Во всех сервисах ЭДО можно:",
      description2:"создавать, отправлять и получать электронные документы;",
      description3:"отслеживать статус подписания бумаг;",
      description4:"видеть всю историю согласования документа.",
      price: 5000,
    
    },
    {
      id:"connection-utm",
      href:"/",
      image:"assets/pages/programs/4.jpg",
      name:"НАСТРОЙКА УТМ",
      description:"Наши специалисты произведут качественную установку и настройку УТМ на Вашем ПК, проверят его работоспособность",
      description2:"Подключение по средствам удаленного администрирования к рабочему месту",
      description3:"-Установка и настройка CryptoPRO (лицензия приобретается отдельно)",
      description4:"-Настройка товароучетной системы для работы в системе ЕГАИС",
      description5:"-Проверка работоспособности",
      price: 5000,
    
    },
    //---------------------лицензии-------------------
    {
      id:"lic-1-polyzivatelskaya",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 1 рабочее место",
      
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 7700,
      attentions: [
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        
      ],
      kit:[
        "2900002084054",
        "2900002084061",  
        "MB0000876390",
        "MB0000876391",
      ],
      parameters:[
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        
      ]
    },
     //------------------------------лицензии многопользовательские---------------------------------------
    {
      id:"lic-mn-polyzivatelskaya-1",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 5 рабочих мест",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 26200,
      attentions: [
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        
      ],
      kit:[
        "2900002084054",
        "2900002084061",  
        "MB0000876390",
        "MB0000876391",
      ],
      parameters:[
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        
      ]
    },
    {
      id:"lic-mn-polyzivatelskaya-2",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 10 рабочих мест",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 50200,
     
    },
    {
      id:"lic-mn-polyzivatelskaya-3",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 20 рабочих мест",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 94400,
     
    },
    {
      id:"lic-mn-polyzivatelskaya-4",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 50 рабочих мест",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 226600,
     
    },
    {
      id:"lic-mn-polyzivatelskaya-5",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 100 рабочих мест",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 435600,
     
    },
    {
      id:"lic-mn-polyzivatelskaya-6",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 300 рабочих мест",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 1292300,
     
    },
    {
      id:"lic-mn-polyzivatelskaya-7",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 500 рабочих мест",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 2149000,
     
    },
    {
      id:"lic-mn-polyzivatelskaya-8",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Расширение для карманных компьютеров (включает лицензию на 5 КПК)",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 24600,
     
    },
//онлайн кассы    
    {
      id:"cash-registers-25F",
      href:"devices/hotel?id=cash-registers-25F",
      image:"assets/products/Атол-25Ф.jpg",
      name:"Онлайн-Касса Атол 25 Ф",
      linkText:"Официальный сайт",
      //ссылки в шапке продукта
      hrefs:[
        {
          image:"assets/icons/Akciya.svg",
          title:"Подключение бесплатно!",
          href:"devices/cash-register-services",
          akciya:{
            title: "Выгодное предложение",
          }
        },
        {
          image:"assets/icons/Dostavka.svg",
          title:"Доставка / Оплата",
          href:"#",
        },
        {
          image:"assets/icons/Podkluchenie.svg",
          title:"Услуги подключения",
          href:"#",
        },
        {
          image:"assets/icons/Obslujivanie.svg",
          title:"Тех. обслуживание",
          href:"#",
        },
      ],
       //изображения в шапке продукта
      galleryImage:[
        "assets/products/Атол-25Ф-1.jpg",
       ],
       galleryImage2:[
         "assets/products/Атол-25Ф-2.jpg",
        ],
      //описание и характеристики текст продукта
      description: [
        "Атол 25Ф — фискальный регистратор, соответствующий новому 54-ФЗ, печатающий чеки. Данная модель фискального регистратора подходит практически для любого бизнеса, за исключением терминалов для автоматической оплаты. Высокая скорость печати ККТ 25Ф и надёжность, замена ФН без разбора корпуса и простота в обслуживании, совместимость с основным ПО, а также готовность к работе с ЕГАИС — основные критерии в пользу приобретения данной ККТ.",
      ],
     
     //цена продукта
      price: 34500,

    //список продуктов для добавления в корзину
      kit:[
    
        "cash-registers-22F",
        "cash-registers-30F",
        "cash-registers-50F",
        "cash-registers-55F",
        "cash-registers-91F",
        "cash-registers-92F"
      ],
    //  параметры продукта характеристика+значение
      parameters:[
        {
          title: "Скорость печати",
          value: "до 250 мм/с",
        },
        {
          title: "Ресурс печатной головки",
          value: "150 км",
        },
        {
          title: "Автоотрез чека",
          value: "Да, до 2 000 000 отрезов",
        }, 
        {
          title: "Длина намотки чековой ленты",
          value: "80 м",
        }, 
        {
          title: "Диаметр рулона чековой ленты",
          value: "80 мм",
        }, 
        {
          title: "Ширина чека",
          value: "80/58 мм",
        }, 
        {
          title: "Передача данных",
          value: "USB, Ethernet",
        },
        {
          title: "Беспроводная передача данных",
          value: "2G/3G, Bluetooth/Wi-Fi(через доп. модуль)",
        },
        {
          title: "Подключение к денежному ящику",
          value: "RJ 12",
        },
        {
          title: "Подключение к дисплею покупателя",
          value: "RS232",
        },
        {
          title: "Подключение к питанию",
          value: "24В от блока питания",
        },
        {
          title: "Размеры ДЛхШРхВС",
          value: "144х130х174 мм",
        },
        {
          title: "Вес без чековой ленты",
          value: "1,2 кг",
        },
        {
          title: "Цвет корпуса",
          value: "Черный",
        },
        {
          title: "Гарантия",
          value: "12 месяцев",
        },
      ],
      download:[
        {
          href:"#",
        }
      ]
    },
    {
      id:"cash-registers-22F",
      href:"devices/hotel?id=cash-registers-22F",
      image:"assets/products/Атол-22Ф.png",
      name:"Онлайн-Касса Атол 22 Ф",
      //ссылки в шапке продукта
      hrefs:[
        {
          image:"assets/icons/Akciya.svg",
          title:"Подключение бесплатно!",
          href:"#",
          akciya:{
            title: "Выгодное предложение",
          }
        },
        {
          image:"assets/icons/Dostavka.svg",
          title:"Доставка / Оплата",
          href:"#",
        },
        {
          image:"assets/icons/Podkluchenie.svg",
          title:"Услуги подключения",
          href:"#",
        },
        {
          image:"assets/icons/Obslujivanie.svg",
          title:"Тех. обслуживание",
          href:"#",
        },
      ],
       //изображения в шапке продукта
      galleryImage:[
        "assets/products/Атол-22Ф-1.png",
       ],
       galleryImage2:[
         "assets/products/Атол-22Ф-2.png",
        ],
      //описание и характеристики текст продукта
      description: [
        "АТОЛ 22  Ф — компактное устройство для автоматизации кассовых расчетов в магазинах у дома, минимаркетах, кафе и других торговых точках со средней проходимостью. Фискальный регистратор оснащен принтером японского производства и автоотрезчиком с функцией защиты от заклинивания.",
      ],
     
     //цена продукта
      price: 47600,

    //список продуктов для добавления в корзину
      kit:[
        "cash-registers-25F",
        "cash-registers-30F",
        "cash-registers-50F",
        "cash-registers-55F",
        "cash-registers-91F",
        "cash-registers-92F"
       
      ],
    //  параметры продукта характеристика+значение
      parameters:[
        {
          title: "Скорость печати",
          value: "до 250 мм/с",
        },
        {
          title: "Ресурс печатной головки",
          value: "150 км",
        },
        {
          title: "Автоотрез чека",
          value: "Да, до 1 500 000 отрезов",
        }, 
        {
          title: "Длина намотки чековой ленты",
          value: "80 м",
        }, 
        {
          title: "Диаметр рулона чековой ленты",
          value: "83 мм",
        }, 
        {
          title: "Ширина чека",
          value: "80/58 мм",
        }, 
        {
          title: "Передача данных",
          value: "USB, Ethernet",
        },
        {
          title: "Беспроводная передача данных",
          value: "2G/3G, Bluetooth/Wi-Fi(через доп. модуль)",
        },
        {
          title: "Подключение к денежному ящику",
          value: "RJ 12",
        },
        {
          title: "Подключение к дисплею покупателя",
          value: "RS 232C",
        },
        {
          title: "Подключение к питанию",
          value: "24В от блока питания",
        },
        {
          title: "Размеры ДЛхШРхВС",
          value: "145х142х119 мм",
        },
        {
          title: "Вес без чековой ленты",
          value: "0,75 кг",
        },
        {
          title: "Цвет корпуса",
          value: "Черный",
        },
        {
          title: "Гарантия",
          value: "12 месяцев",
        },
      ],
      download:[
        {
          href:"#",
        }
      ]
    },
    {
      id:"cash-registers-30F",
      href:"devices/hotel?id=cash-registers-30F",
      image:"assets/products/Atol-30F.jpg",
      name:"Онлайн-Касса Атол 30 Ф",
      //ссылки в шапке продукта
      hrefs:[
        {
          image:"assets/icons/Akciya.svg",
          title:"Подключение бесплатно!",
          href:"#",
          akciya:{
            title: "Выгодное предложение",
          }
        },
        {
          image:"assets/icons/Dostavka.svg",
          title:"Доставка / Оплата",
          href:"#",
        },
        {
          image:"assets/icons/Podkluchenie.svg",
          title:"Услуги подключения",
          href:"#",
        },
        {
          image:"assets/icons/Obslujivanie.svg",
          title:"Тех. обслуживание",
          href:"#",
        },
      ],
       //изображения в шапке продукта
      galleryImage:[
        "assets/products/Atol-30F-2.jpg",
       ],
       galleryImage2:[
         "assets/products/Atol-30F-3.jpg",
        ],
      //описание и характеристики текст продукта
      description: [
        "Атол 30Ф — ККТ нового поколения для предпринимателей малого и микробизнеса, не требующая больших затрат на приобретение и эксплуатацию. Атол 30Ф подходит для установки в местах с невысокой покупательской активностью. Небольшие размеры, простота обслуживания, готовность к работе с ЕГАИС, генерация и печать QR-кодов – основные достоинства данной модели",
      ],
     
     //цена продукта
      price: 38300,

    //список продуктов для добавления в корзину
      kit:[
        "cash-registers-25F",
        "cash-registers-22F",
        "cash-registers-50F",
        "cash-registers-55F",
        "cash-registers-91F",
        "cash-registers-92F"
      ],
    //  параметры продукта характеристика+значение
      parameters:[
        {
          title: "Скорость печати",
          value: "до 75 мм/с",
        },
        {
          title: "Ресурс печатной головки",
          value: "50 км",
        },
        {
          title: "Автоотрез чека",
          value: "Нет, гребенка",
        }, 
        {
          title: "Длина намотки чековой ленты",
          value: "35 м",
        }, 
        {
          title: "Диаметр рулона чековой ленты",
          value: "47 мм",
        }, 
        {
          title: "Ширина чека",
          value: "58/44 мм",
        }, 
        {
          title: "Передача данных",
          value: "USB",
        },
        {
          title: "Беспроводная передача данных",
          value: "2G/3G, Bluetooth/Wi-Fi(через доп. модуль)",
        },
        {
          title: "Подключение к денежному ящику",
          value: "Нет, доступно в АТОЛ 30Ф+",
        },
        {
          title: "Подключение к дисплею покупателя",
          value: "Нет",
        },
        {
          title: "Подключение к питанию",
          value: "9В от блока питания",
        },
        {
          title: "Размеры ДЛхШРхВС",
          value: "160х85х79 мм",
        },
        {
          title: "Вес без чековой ленты",
          value: "0,36 кг",
        },
        {
          title: "Цвет корпуса",
          value: "Темно-серый",
        },
        {
          title: "Гарантия",
          value: "12 месяцев",
        },
      ],
      download:[
        {
          href:"#",
        }
      ]
    },
    {
      id:"cash-registers-50F",
      href:"devices/hotel?id=cash-registers-50F",
      image:"assets/products/Атол-55Ф.jpg",
      name:"Онлайн-Касса Атол 50 Ф",
      //ссылки в шапке продукта
      hrefs:[
        {
          image:"assets/icons/Akciya.svg",
          title:"Подключение бесплатно!",
          href:"#",
          akciya:{
            title: "Выгодное предложение",
          }
        },
        {
          image:"assets/icons/Dostavka.svg",
          title:"Доставка / Оплата",
          href:"#",
        },
        {
          image:"assets/icons/Podkluchenie.svg",
          title:"Услуги подключения",
          href:"#",
        },
        {
          image:"assets/icons/Obslujivanie.svg",
          title:"Тех. обслуживание",
          href:"#",
        },
      ],
       //изображения в шапке продукта
      galleryImage:[
        "assets/products/Атол-55Ф-1.jpg",
       ],
       galleryImage2:[
         "assets/products/Атол-55Ф-2.jpg",
        ],
      //описание и характеристики текст продукта
      description: [
        "Атол 50Ф — фискальный регистратор, новинка и логичное продолжение успешной и популярной модели предыдущей серии. Аппарат полностью готов к работе по 54ФЗ, а также в системе ЕГАИС. Надежность, компактность, производительность и удобство использования — вот основные характеристики ККТ нового поколения Атол 50Ф. Устройство отлично подойдет для предприятий любой сферы деятельности с низкой и средней проходимостью. Автоотрезчик на 500 тысяч отрезов и печатающая головка на 100 километров чеков, все необходимые интерфейсы для подключения периферии.",
      ],
     
     //цена продукта
      price: 29500,

    //список продуктов для добавления в корзину
      kit:[
        "cash-registers-25F",
        "cash-registers-22F",
        "cash-registers-30F",
        "cash-registers-55F",
        "cash-registers-91F",
        "cash-registers-92F"
      ],
    //  параметры продукта характеристика+значение
      parameters:[
        {
          title: "Скорость печати",
          value: "до 100 мм/с",
        },
        {
          title: "Ресурс печатной головки",
          value: "50 км",
        },
        {
          title: "Автоотрез чека",
          value: "Да, до 500 000 отрезов",
        }, 
        {
          title: "Длина намотки чековой ленты",
          value: "80 м",
        }, 
        {
          title: "Диаметр рулона чековой ленты",
          value: "80 мм",
        }, 
        {
          title: "Ширина чека",
          value: "58/44 мм",
        }, 
        {
          title: "Передача данных",
          value: "USB",
        },
        {
          title: "Беспроводная передача данных",
          value: "2G/3G, Bluetooth/Wi-Fi(через доп. модуль)",
        },
        {
          title: "Подключение к денежному ящику",
          value: "RJ 12",
        },
        {
          title: "Подключение к дисплею покупателя",
          value: "Нет",
        },
        {
          title: "Подключение к питанию",
          value: "9В от блока питания",
        },
        {
          title: "Размеры ДЛхШРхВС",
          value: "200х115х135 мм",
        },
        {
          title: "Вес без чековой ленты",
          value: "0,9 кг",
        },
        {
          title: "Цвет корпуса",
          value: "Темно-серый",
        },
        {
          title: "Гарантия",
          value: "12 месяцев",
        },
      ],
      download:[
        {
          href:"#",
        }
      ]
    },
    {
      id:"cash-registers-55F",
      href:"devices/hotel?id=cash-registers-55F",
      image:"assets/products/atol555.png",
      name:"Онлайн-Касса Атол 55 Ф",
      //ссылки в шапке продукта
      hrefs:[
        {
          image:"assets/icons/Akciya.svg",
          title:"Подключение бесплатно!",
          href:"#",
          akciya:{
            title: "Выгодное предложение",
          }
        },
        {
          image:"assets/icons/Dostavka.svg",
          title:"Доставка / Оплата",
          href:"#",
        },
        {
          image:"assets/icons/Podkluchenie.svg",
          title:"Услуги подключения",
          href:"#",
        },
        {
          image:"assets/icons/Obslujivanie.svg",
          title:"Тех. обслуживание",
          href:"#",
        },
      ],
       //изображения в шапке продукта
      galleryImage:[
        "assets/products/atol-55f.jpg",
       ],
       galleryImage2:[
         "assets/products/atol-55f-1.jpg",
        ],
      //описание и характеристики текст продукта
      description: [
        "АТОЛ 55Ф — фискальный регистратор, работающий с узким чеком (58 мм) и рассчитанный на средний поток клиентов. Онлайн-касса использует автоматический отрезчик чековой ленты японского производства. Надежный механизм защищает от заклинивания ножа.",
      ],
     
     //цена продукта
      price: 37200,

    //список продуктов для добавления в корзину
      kit:[
        "cash-registers-25F",
        "cash-registers-22F",
        "cash-registers-30F",
        "cash-registers-50F",
        "cash-registers-91F",
        "cash-registers-92F"
      ],
    //  параметры продукта характеристика+значение
      parameters:[
        {
          title: "Скорость печати",
          value: "до 200 мм/с",
        },
        {
          title: "Ресурс печатной головки",
          value: "100 км",
        },
        {
          title: "Автоотрез чека",
          value: "Да, до 1 000 000  отрезов",
        }, 
        {
          title: "Длина намотки чековой ленты",
          value: "80 м",
        }, 
        {
          title: "Диаметр рулона чековой ленты",
          value: "83 мм",
        }, 
        {
          title: "Ширина чека",
          value: "58/44 мм",
        }, 
        {
          title: "Передача данных",
          value: "USB, Ethernet",
        },
        {
          title: "Беспроводная передача данных",
          value: "2G/3G, Bluetooth/Wi-Fi(через доп. модуль)",
        },
        {
          title: "Подключение к денежному ящику",
          value: "RJ 12",
        },
        {
          title: "Подключение к дисплею покупателя",
          value: "RS 232C",
        },
        {
          title: "Подключение к питанию",
          value: "24В от блока питания",
        },
        {
          title: "Размеры ДЛхШРхВС",
          value: "200х120х135 мм",
        },
        {
          title: "Вес без чековой ленты",
          value: "1,2 кг",
        },
        {
          title: "Цвет корпуса",
          value: "Белый/Черный",
        },
        {
          title: "Гарантия",
          value: "12 месяцев",
        },
      ],
      download:[
        {
          href:"#",
        }
      ]
    },
    {
      id:"cash-registers-91F",
      href:"devices/hotel?id=cash-registers-91F",
      image:"assets/products/Атол-91Ф.png",
      name:"Атол-91Ф",
      //ссылки в шапке продукта
      hrefs:[
        {
          image:"assets/icons/Akciya.svg",
          title:"Подключение бесплатно!",
          href:"#",
          akciya:{
            title: "Выгодное предложение",
          }
        },
        {
          image:"assets/icons/Dostavka.svg",
          title:"Доставка / Оплата",
          href:"#",
        },
        {
          image:"assets/icons/Podkluchenie.svg",
          title:"Услуги подключения",
          href:"#",
        },
        {
          image:"assets/icons/Obslujivanie.svg",
          title:"Тех. обслуживание",
          href:"#",
        },
      ],
       //изображения в шапке продукта
      galleryImage:[
        "assets/products/Атол-91Ф-1.png",
       ],
       galleryImage2:[
         "assets/products/Атол-91Ф-2.png",
        ],
      //описание и характеристики текст продукта
      description: [
        "Оптимальное решение среди ньюджеров, если на торговой точке нет специальной кассовой зоны и есть возможность расположить только одно дополнительное устройство. Передача данных в ОФД через Ethernet, Wi-FI, 2G, Bluetooth.",
      ],
     
     //цена продукта
      price: 11950,

    //список продуктов для добавления в корзину
      kit:[
        "cash-registers-25F",
        "cash-registers-22F",
        "cash-registers-30F",
        "cash-registers-50F",
        "cash-registers-55F",
        "cash-registers-92F"
      ],
    //  параметры продукта характеристика+значение
      parameters:[
        {
          title: "Ресурс печатной головки",
          value: "50 км",
        },
        {
          title: "Автоотрез чека",
          value: "Нет, гребенка",
        }, 
        {
          title: "Длина намотки чековой ленты",
          value: "30 м",
        }, 
        {
          title: "Диаметр рулона чековой ленты",
          value: "43 мм",
        }, 
        {
          title: "Ширина чека",
          value: "58 мм",
        }, 
        {
          title: "Количество USB",
          value: "1 шт",
        }, 
        {
          title: "Передача данных",
          value: "Ethernet, Wi-Fi, 3G, Bluetooth",
        },
        {
          title: "Экран в дюймах",
          value: "2,7",
        },
        {
          title: "Разрешение экрана",
          value: "6 строк",
        },
        {
          title: "Беспроводная передача данных",
          value: "Wi-Fi, 2G, Bluetooth",
        },
        {
          title: "Подключение к денежному ящику",
          value: "Нет",
        },
        {
          title: "Подключение к дисплею покупателя",
          value: "Нет",
        },
        {
          title: "Подключение сканера штрихкодов",
          value: "Да",
        },
        {
          title: "Подключение банковского пин-пада",
          value: "Нет",
        },
        {
          title: "Подключение к питанию",
          value: "Micro-USB, 5В от блока питания, АКБ типа 18650",
        },
        {
          title: "Время работы от аккумклятора",
          value: "до 8 часов",
        },
        {
          title: "Температурный режим",
          value: "от - 10С до +45С",
        },
        {
          title: "Размеры ДЛхШРхВС",
          value: "187х85х66 мм",
        },
        {
          title: "Вес без чековой ленты",
          value: "0,39 кг",
        },
        {
          title: "Гарантия",
          value: "12 месяцев",
        },
      ],
      download:[
        {
          href:"#",
        }
      ]
    },
    {
      id:"cash-registers-92F",
      href:"devices/hotel?id=cash-registers-92F",
      image:"assets/products/Атол-92Ф.jpg",
      name:"Атол-92Ф",
      //ссылки в шапке продукта
      hrefs:[
        {
          image:"assets/icons/Akciya.svg",
          title:"Подключение бесплатно!",
          href:"#",
          akciya:{
            title: "Выгодное предложение",
          }
        },
        {
          image:"assets/icons/Dostavka.svg",
          title:"Доставка / Оплата",
          href:"#",
        },
        {
          image:"assets/icons/Podkluchenie.svg",
          title:"Услуги подключения",
          href:"#",
        },
        {
          image:"assets/icons/Obslujivanie.svg",
          title:"Тех. обслуживание",
          href:"#",
        },
      ],
       //изображения в шапке продукта
      galleryImage:[
        "assets/products/Атол-92Ф-1.png",
       ],
       galleryImage2:[
         "assets/products/Атол-91Ф-2.png",
        ],
      //описание и характеристики текст продукта
      description: [
        "Расширенное решение среди ньюджеров. АТОЛ 92Ф обладает информативным графическим экраном и удобной клавиатурой. Передача данных в ОФД через Ethernet, Wi-FI, 2G, Bluetooth.",
      ],
     
     //цена продукта
      price: 12500,

    //список продуктов для добавления в корзину
      kit:[
        "cash-registers-25F",
        "cash-registers-22F",
        "cash-registers-30F",
        "cash-registers-50F",
        "cash-registers-55F",
        "cash-registers-91F"
      ],
    //  параметры продукта характеристика+значение
      parameters:[
        {
          title: "Скорость печати",
          value: "до 50 мм/с",
        },
        {
          title: "Ресурс печатной головки",
          value: "50 км",
        },
        {
          title: "Автоотрез чека",
          value: "Нет, гребенка",
        }, 
        {
          title: "Длина намотки чековой ленты",
          value: "30 м",
        }, 
        {
          title: "Диаметр рулона чековой ленты",
          value: "43 мм",
        }, 
        {
          title: "Ширина чека",
          value: "58 мм",
        }, 
        {
          title: "Количество USB",
          value: "2 шт",
        }, 
        {
          title: "Передача данных",
          value: "Ethernet, Wi-Fi, 3G, Bluetooth",
        },
        {
          title: "Экран в дюймах",
          value: "2,7",
        },
        {
          title: "Разрешение экрана",
          value: "6 строк",
        },
        {
          title: "Беспроводная передача данных",
          value: "Wi-Fi, 2G, Bluetooth",
        },
        {
          title: "Подключение к денежному ящику",
          value: "Нет",
        },
        {
          title: "Подключение к дисплею покупателя",
          value: "Нет",
        },
        {
          title: "Подключение сканера штрихкодов",
          value: "Да",
        },
        {
          title: "Подключение банковского пин-пада",
          value: "Нет",
        },
        {
          title: "Подключение к питанию",
          value: "Micro-USB, 5В от блока питания, АКБ типа 18650",
        },
        {
          title: "Время работы от аккумклятора",
          value: "до 8 часов",
        },
        {
          title: "Температурный режим",
          value: "от - 10С до +45С",
        },
        {
          title: "Размеры ДЛхШРхВС",
          value: "212х103х67 мм",
        },
        {
          title: "Вес без чековой ленты",
          value: "0,36 кг",
        },
        {
          title: "Гарантия",
          value: "12 месяцев",
        },
      ],
      download:[
        {
          href:"#",
        }
      ]
    },
    //------------------------------лицензии КОРП---------------------------------------
    {
      id:"lic-korp-1",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8 КОРП. Клиентская лицензия на 1 рабочее место",
      price: 14100,
    },
    {
      id:"lic-korp-2",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8 КОРП. Клиентская лицензия на 5 р.м. Электронная поставка",
      price: 48500,
    },
    {
      id:"lic-korp-3",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8 КОРП. Клиентская лицензия на 10 р.м.",
      price: 93000,
    },
    {
      id:"lic-korp-4",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8 КОРП. Клиентская лицензия на 20 р.м",
      price: 174900,
    },
    {
      id:"lic-korp-5",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8 КОРП. Клиентская лицензия на 50 р.м.",
      price: 419800,
    },
    {
      id:"lic-korp-6",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8 КОРП. Клиентская лицензия на 100 р.м.",
      price: 807200,
    },
    {
      id:"lic-korp-7",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8 КОРП. Клиентская лицензия на 300 р.м.",
      price: 2394500,
    },
    {
      id:"lic-korp-8",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8 КОРП. Клиентская лицензия на 500 р.м.",
      price: 3981800,
    },
    {
      id:"lic-korp-9",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8 КОРП. Клиентская лицензия на 1000 р.м.",
      price: 7945800,
    },
    //------------------------------лицензии сервер мини---------------------------------------
    {
      id:"lic-mini-server-1",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8.3. Сервер МИНИ на 5 подключений",
      price: 17500,
    },
    {
      id:"lic-mini-server-2",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8.3. Сервер МИНИ на 5 подключений. Электронная поставка",
      price: 17500,
    },
     //------------------------------лицензии сервер 32 разрядный сервер---------------------------------------
     {
      id:"lic-server32-1",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Лицензия на сервер (программная защита)",
      price: 61100,
    },
    //------------------------------лицензии сервер 64 разрядный сервер---------------------------------------
    {
      id:"lic-server64-1",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8.Лицензия на сервер (х86-64) (программная защита)",
      price: 104700,
    },
    {
      id:"lic-server64-2",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8.3 КОРП. Лицензия на сервер (x86-64). Электронная поставка",
      price: 242200,
    },
    //------------------------------лицензии  для мобильных рабочих мест---------------------------------------
    {
      id:"lic-mobile-1",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 1 мобильное рабочее место. Электронная поставка",
      price: 1600,
    },
    {
      id:"lic-mobile-2",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 5 мобильных рабочих мест. Электронная поставка",
      price: 5100,
    },
    {
      id:"lic-mobile-3",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 10 мобильных рабочих мест. Электронная поставка",
      price: 9600,
    },
    {
      id:"lic-mobile-4",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 20 мобильных рабочих мест. Электронная поставка",
      price: 18100,
    },
    {
      id:"lic-mobile-5",
      href:"/",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Клиентская лицензия на 50 мобильных рабочих мест. Электронная поставка",
      price: 43300,
    },
    //-------------------------------------------
    {
      id:"2900002083934",
      href:"devices/hotel?id=2900002083934",
      image:"assets/products/hotel.png",
      name:"1С:Предприяте 8. Отель. Электронная поставка",
      
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 65000,
      attentions: [
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        
      ],
      kit:[
        "2900002084054",
        "2900002084061",  
        "MB0000876390",
        "MB0000876391",
      ],
      parameters:[
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        
      ]
    },
    {
      id:"123",
      href:"devices/hotel?id=123",
      image:"assets/products/hotel.png",
      name:"Касса Атол",
      
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 65000,
      attentions: [
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        
      ],
      kit:[
        "2900002084054",
        "2900002084061",  
        "MB0000876390",
        "MB0000876391",
      ],
      parameters:[
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        {
          title: "Тип лицензии",
          value: "Стандартная",
          description: "отраслевое решение для повышения эффективности работы гостиниц",
        },
        
      ]
    }, 
    {
      id:"2900002083958",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Отель для 5 пользователей. Электронная поставка",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      
      ],
      price: 130000,
      attentions: [
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        
      ],
      kit:[
        "2900002084054",
        "2900002084061",  
      ],
    },
    {
      id:"2900002084054",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Отель Доп. лицензия на 5 р.м. Электронная поставка",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      
      ],
      price: 117000,
      attentions: [
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        
      ]
    },
    {
      id:"2900002084061",
      image:"assets/products/hotel.png",
      name:"1С:Предприятие 8. Отель Доп. лицензия на 10 р.м. Электронная поставка",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      
      ],
      price: 214500,
      attentions: [
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        
      ]
    },
    {
      id:"MB0000876390",
      image:"assets/main/product6.png",
      name:"Модуль бронирования на 1 год",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 40800,
      attentions: [
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
      ]
    },
    {
      id:"MB0000876390",
      image:"assets/main/product6.png",
      name:"Модуль бронирования на 1 год",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 40800,
      attentions: [
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        
      ]
    },
    {
      id:"MB0000876391",
      image:"assets/main/product6.png",
      name:"Модуль бронирования на 2 года",
      description: [
        "1С:Отель – отраслевое решение для повышения эффективности работы гостиниц любого формата, от хостелов, мини-отелей до бизнес-отелей, курортных отелей и гостиничных сетей. Внедрение системы позволит оптимизировать штат сотрудников, зарплатный фонд и работу бухгалтерии, а также предоставит инструменты для того, чтобы повысить прямые продажи, средний чек на гостя и сократить издержки в работе отеля.",
      ],
      price: 81600,
      attentions: [
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
        {
          description:[
            {
              image: "assets/services/Group 373.png",
              text: 'Установка программы',
              bold: false,
            },
            {
              text: '1С:Предприятие "Отель",',
              href: "",
              isRow: true,  
              bold: false,
            },
            {
              text: 'не входит в стомость',
              isRow: false,
              margin: "0px 28px",
            }
          ],
        },
      ]
    },
  ]

  public pages:any = [
//О нас
    {
      url:"o-nas",
      title:"О нас",
      description: 'Онлайн-касса - это устройство, которое используется для приема платежей от покупателей. Она позволяет автоматизировать процесс продажи товаров и услуг, а также обеспечивает безопасность транзакций. Онлайн-кассы поддерживают множество видов платежей. Они также могут отправлять электронные чеки покупателям и предоставлять информацию о продажах в налоговые службы в реальном времени.',
      descriptionMobaileVisible:"Онлайн-касса - это устройство, которое используется для приема платежей от покупателей.",
      descriptionMobileHidden:"скрытый текст", 
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "О нас",
          href: "o-nas",
        },
        
      ],  
      photosGallerey: [
        {
         image: "url('../../../assets/pages/o-nas/Mi2.jpg')",
        },
        {
          image2: "url('../../../assets/pages/o-nas/Mi2.jpg')",
         },
       
      ], 
     
    },
//тех стек
{
  url:"teh-stack",
  title:"Технологический стек",

  breadcrumbs: [
    {
      title: "Главная",
      href: "/",
    },
    {
      title: "Технологический стек",
      href: "teh-stack",
    },
    
  ],  
  photosGallerey: [
    {
     image: "url('../../../assets/pages/o-nas/Mi2.jpg')",
    },
    {
      image2: "url('../../../assets/pages/o-nas/Mi2.jpg')",
     },
   
  ], 
 
},    
//Партнерам
{
  url:"partners",
  title:"Партнерам",
  breadcrumbs: [
    {
      title: "Главная",
      href: "/",
    },
    {
      title: "Партнерам",
      href: "partners",
    },
     
  ], 
  banerTop:[
    { 
      title: "Современный подход в управление отелем", 
      description:"Программа 1С: Отель на сегодняшний день является одной из самых популярных PMS систем для управления отелем в России. Структура программы постоянно дописывается с помощью расширений и интеграций", 
      href:"/", expanded:true, 
      image: "url('../../../assets/pages/1.png')"},
  ],
  text1:{
    subtitle: "НАМ ДОВЕРЯЮТ",
    description:[
      {
        text:"Умение взаимодействовать с парнтерами, понимая их уникальность и потребности клиентов, определяют успешность. Именно эта способность превращать любые задачи в авангард современных решений",
      },
      
    ]
   
  },
  marketingCards:{
    cards:[
      {
        image:"assets/pages/contacts/3Svg.svg",
        text:"Запрос партнера",
        discription:"Наша команда открыта для новых парнтеров и интересных совместных решений",
      },
     
    ]
  },
  slderFullW:{
    cards:[
      {
        image:"assets/pages/contacts/3Svg.svg",
        text:"Запрос партнера",
        discription:"Наша команда открыта для новых парнтеров и интересных совместных решений",
      },
     
    ]
  },
  banerOne: {

    imageBg: "url('../../../../assets/pages/partners/Fon2.jpg')",
    imgChild: "url('../../../assets/pages/partners/Baner1.png')",
    title: "ВМЕСТЕ С КОМАНДОЙ TRIPADVANCE",
    description: "Результат любой деятельности находится в зависимости от степени эффективности делового партнёрства. Tripadvance открыта к плодотворному сотрудничеству на долговременной основе. Успех нашей деятельности невозможен без участия ведущих лидеров в сфере размещения гостей. Мы готовы к партнёрству в области продвижения программного продукта и сервисов для отелей по всему миру",
    titleLink:'',
    link:'',
    linkDiscription:'',
  },
},  

//Политика конф
  {
    url:"politics-confendiality",
    breadcrumbs: [
      {
        title: "Главная",
        href: "/",
      },
      {
        title: "Политика конфиденциальности",
        href: "politics-confendiality",
      },
       
    ], 
  },  
//Контакты
    {
      url:"contacts",
      title:"О нас",
      description: 'Онлайн-касса - это устройство, которое используется для приема платежей от покупателей. Она позволяет автоматизировать процесс продажи товаров и услуг, а также обеспечивает безопасность транзакций. Онлайн-кассы поддерживают множество видов платежей. Они также могут отправлять электронные чеки покупателям и предоставлять информацию о продажах в налоговые службы в реальном времени.',
      descriptionMobaileVisible:"Онлайн-касса - это устройство, которое используется для приема платежей от покупателей.",
      descriptionMobileHidden:"скрытый текст", 
      breadcrumbs: [
       {
         title: "Главная",
         href: "/",
       },
       {
         title: "Контакты",
         href: "contacts",
       },
    
      ],  
      photosGallerey: [
       {
         image: "url('../../../assets/pages/o-nas/Mi2.jpg')",
       },
       {
         image2: "url('../../../assets/pages/o-nas/Mi2.jpg')",
       },
   
      ], 
      text1:{
        title: "Мы работаем для вас!",
        description:[
          {
            text:"Мы стремимся к сотрудничеству с ведущими поставщиками решений в сфере путешествий и технологий объединив разработки и продукты, чтобы расширить возможности отельеров по всему миру",
          },
          {
            text:"Закажите бесплатную демонстрацию продукта или задайте интересующий вас вопрос нашему специалисту Свяжитесь с нами здесь для получения информации о возможностях и трудоустройстве"
          },
        ]
       
      },
      marketingCards:{
        cards:[
          {
            image:"assets/pages/contacts/1Svg.svg",
            text:"Запрос консультанта",
            discription:"Есть вопрос? Запланируйте чат с нашим специалистом, чтобы узнать больше",
          },
          {
            image:"assets/pages/contacts/2Svg.svg",
            text:"Поддержка клиентов",
            discription:"Наша быстрая помощь с поддержкой 24/7. Вы также можете позвонить нам по бесплатному телефону",
          },
          {
            image:"assets/pages/contacts/3Svg.svg",
            text:"Запрос партнера",
            discription:"Наша команда открыта для новых парнтеров и интересных совместных решений",
          },
        ]
      },
    
    }, 
//Онлайн кассы   
    {
      url:"devices/cash-registers",
      title:"Онлайн-кассы",
      description: 'Онлайн-касса - это устройство, которое используется для приема платежей от покупателей. Она позволяет автоматизировать процесс продажи товаров и услуг, а также обеспечивает безопасность транзакций. Онлайн-кассы поддерживают множество видов платежей. Они также могут отправлять электронные чеки покупателям и предоставлять информацию о продажах в налоговые службы в реальном времени.',
      descriptionMobaileVisible:"Онлайн-касса - это устройство, которое используется для приема платежей от покупателей.Она позволяет автоматизировать процесс продажи товаров и услуг, а также обеспечивает",
      descriptionMobileHidden:"безопасность транзакций. Онлайн-кассы поддерживают множество видов платежей. Они также могут отправлять электронные чеки покупателям и предоставлять информацию о продажах в налоговые службы в реальном времени.",    
      packages: this.packages.hotel,
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Оборудование",
        },
        {
          title: "Онлайн-кассы",
          href: "devices/cash-registers",
        },
        
      ],
      baner: {
        //image: "assets/services/bannercash-registers.png",
        image: "url('../../../assets/services/bannercash-registers.png')",
        texts:{
          titleBanner1: "Онлайн-касса",
          titleBanner2: "Атол 55 Ф",
          subtitleBanner1:"Полная настройка Бесплатно!",
          subtitleBanner2:"Лучший выбор покупателей в 2025",
          priceBanner:"от 33 500 р",
          href: "",
          image1: "assets/products/1.png",
        },
        imageObj:{
          back:"",
          text:"",
        },

        right: {
          title: "Подключение к вашей 1С",
          description: "Настройка и подключение кассы-онлайн к программе 1С, как для базовых так и индивидуальных конфигураций",
          image: "assets/icons/Kass-onlai.png",
          href: "",
        },
        left: {
          title: "Услуги регистрации онлайн-касс",
          description: "Список услуг по подключению онлайн касс. Активация и полная настройка оборудования: ФНС, ОФД и другое",
          image: "assets/icons/Kass-onlai.png",
          href: "",
        },
        
      },
      baner2: {
        image:"assets/services/1.png",
        texts:{
          title:"ПРОСТО И",
          title2:"ВЫГОДНО !",
          iconTitle: "assets/services/2.png",
          subtitle:'Полный пакет подключения "Лайт"',
          iconSubtitle:"assets/icons/arrow-text-content.svg",
          list:"Регистрация в налоговую ФНС",
          list2:"Активация кабинета ОФД",
          list3:"Настройка ККМ",
          list4:"Подключение 1С",
          price:"8 500",
          priceDiscount:"5000",
          priceNew:"12 500",
          href: "",
          footerText: 'При покупке пакета "Лайт" - вы получаете полную настройку вашей онлайн-кассы под ключ',
          footerText2: "оборудование будет готово к работе уже завтра. Без сложностей и переплат!",
 
          //это вынести в отдельный блок 
          contentText: "Фискальный регистратор — это устройство, выполняющее необходимые кассовые операции. Основное отличие от обычного кассового аппарата — обязательное подключение к ПК с программой для учета. Регистрирует информацию в ФН, передает данные оператору фискальных данных, отличается быстрой печатью чеков (в отличие от кассовых аппаратов). Имеется возможность коррекции изменений в БД во время работы",
          contentTitle: "Обзор фискальных регистраторов",
          contentText2: "Фискальный регистратор — вид контрольно-кассовой техники (ККТ), который фиксирует и сохраняет сведения о продажах (фискальные данные) в фискальном накопителе (ФН), а также передает их оператору фискальных данных (ОФД) для дальнейшей обработки и отправки в ФНС. Устройство работает только в связке с управляющим оборудованием: кассовым компьютером, смартфоном или ПК. ФР соответствует требованиям закона № 54-ФЗ и подходит для всех видов торговой деятельности:",
          // contentlistText в этом списке есть еще элементы, их бы в массив
          contentlistText: "супермаркеты, магазины, минимаркеты;",
          contentTitle2: "Функциональные возможности",
          contentText3: "Фискальный регистратор выполняет следующие функции:",
           // contentlistText2 в этом списке есть еще элементы, их бы в массив
           contentlistText2: "сохранение фискальных данных (ФД) в фискальном накопителе и их создание в электронном виде;",
           contentText4: "Аппарат выполняет стандартные торговые операции: проведение продаж, возврат товара, расчет скидок и надбавок, программирование товаров и другие.",
        },
        
      },
      content:{

      },
      forms: {
        imageBackgraund:"assets/services/backgraund-form-1.png",
        image:"assets/services/backgraund-form-comp.png",
        image2:"assets/services/backgraund-form-phone.png",
        image2Desktop:"assets/services/backgraund-form-phone.png",
        title:'Бесплатно',
        title2:'проконсультируем Вас',
        subTitle:'Оставьте заявку и получите консультацию в течение пяти минут',
        texts:{
          text:"Не знаете какую кассу",
          text2:"выбрать? Как настроить",
          text3:"полное подключение?",
        },
        
      },
      text1:{
        title: "НА ЧТО СТОИТ ОБРАТИТЬ ВНИМАНИЕ ПРИ ВЫБОРЕ ОНЛАЙН-КАССЫ",
        description:[
          {
            text:"Одним из основных критериев для правельного выбора подобного рода оборудования служит: Способ подключения к интернету (Wifi, кабель или сим карта), скорость печати чека и наличие аккамулятора. Следует понимать какой фискальный накопитель (память кассы) подходит именно вам, на 15 или 36 месяцев",
          }
        ]
      },
      text2:{
        description:[
          {
            html: "<p>Для техподдержки и настройки уже установленного у вас оборудования, обратитесь в раздел:<a class='link' href='/'> техподдержка</a></p>",
          },
          
        ]
      },
      text3:{
        description:[
          {
            image: "assets/services/Group 373.png",
            text: "Обращаем Ваше внимание, что для некоторых видов товара требуется маркировка и ЕГАИС",
            bold: true,
          },
         // {
          //  text: "программирование",
          //  href: "",
           // isRow: true,  
           // bold: true,
         // }
        ]
      },
   //текст под вторым баннером   
      text4:{
        description:[
          {
            text: "Фискальный регистратор — это устройство, выполняющее необходимые кассовые операции. Основное отличие от обычного кассового аппарата — обязательное подключение к ПК с программой для учета. Регистрирует информацию в ФН, передает данные оператору фискальных данных, отличается быстрой печатью чеков (в отличие от кассовых аппаратов). Имеется возможность коррекции изменений в БД во время работы",
            title: "Обзор фискальных регистраторов",
            text2: "Фискальный регистратор — вид контрольно-кассовой техники (ККТ), который фиксирует и сохраняет сведения о продажах (фискальные данные) в фискальном накопителе (ФН), а также передает их оператору фискальных данных (ОФД) для дальнейшей обработки и отправки в ФНС. Устройство работает только в связке с управляющим оборудованием: кассовым компьютером, смартфоном или ПК. ФР соответствует требованиям закона № 54-ФЗ и подходит для всех видов торговой деятельности:",
            // список выводится через массив
            textList:"супермаркеты, магазины, минимаркеты;",
            title2: "Функциональные возможности",
            text3:"Фискальный регистратор выполняет следующие функции:",
            // список выводится через массив
            textList2:"сохранение фискальных данных (ФД) в фискальном накопителе и их создание в электронном виде;",
            text4:"Аппарат выполняет стандартные торговые операции: проведение продаж, возврат товара, расчет скидок и надбавок, программирование товаров и другие.",
          },
        ]
      },
      text5:{
        title:"ПРАВИЛЬНОЕ ИСПОЛЬЗОВАНИЕ CHANNEL MANAGER",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      products:[
        "cash-registers-25F",
        "cash-registers-22F",
        "cash-registers-30F",
        "cash-registers-50F",
        "cash-registers-55F",
        "cash-registers-91F",
        "cash-registers-92F"
      ]
      
    },
//Внедрение 1С отель
    {
      url:"services/integration/hotel",
      title:"1С:Отель - Внедрение",
      description: "Проведем аудит и предложим правильное программное обеспечивание, как в базовой конфигурации, так и в индивидуальной. Внедрим и настроим программу на любом этапе, обучим персонал и закроем белые пятна в работе. Настроим сложные интеграции и создадим структуру по обмену данных. Разработаем новые печатные формы и отчеты. Синхронизируем кассы-онлайн, интернет эквайринг. Осуществляем базовую и расширенную техническую поддержку. Подробнее о программе 1С:Отель Предприятие 8",
      packages: this.packages.hotel,
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Услуги", 
         
        },
        {
          title: "Внедрение 1С Отель",
          href: "/",
        }
      ],
      accordion:[
        { title: "Современный подход в управление отелем", description:"Программа 1С: Отель на сегодняшний день является одной из самых популярных PMS систем для управления отелем в России. Структура программы постоянно дописывается с помощью расширений и интеграций", href:"/", expanded:true, image: "url('../../../assets/pages/1.png')", img:'/assets/pages/1.png'},
        { title: "Кол-во рабочих мест (Лицензии)", description:"При приобретении “основной поставки 1С” - вы получаете лицензию для одного рабочего места, дополнительные лицензии для увеличения рабочих мест для ваших сотрудников приобретаются отдельно ", expanded:false, image: "url('../../../assets/pages/2.png')", img:'/assets/pages/2.png'},
        { title: "Подбор и настройка оборудования", description:"Проектируем решения любого масштаба и предложим оборудование под индивидуальные задачи. Настроим работу по сети и интеграции. Зарегистрируем в налоговой службе, подключим к необходимым сервисам", expanded:false, image: "url('../../../assets/pages/3-1.png')", img:'/assets/pages/3-1.png'},
        { title: "Полный цикл сопровождения и тех поддрежки", description:"Находим и устраняем многие проблемы еще до их появления. Понимаем наших клиентов с полуслова, оперативно решаем проблемы. Ваш проект получает лучшее из возможных технических решений учитывая все тонкости в работе", expanded:false, image: "url('../../../assets/pages/4.png')", img:'/assets/pages/4.png'}, 
      ],
      accordion2:[
        { title: "Без комиссии и ограничений. Модуль бронирования", description:"Прямое бронирование на вашем сайте в удобном и понятном визуальном исполнении для поиска свободных номеров. Модуль отвечает всем современным требованиям для комфортной работы отдела бронирования", href:"/", expanded:true, image: "url('../../../assets/pages/K1.png')"},
        { title: "CRM Битрикс 24 для отеля", description:"Создавая или редактируя бронирование в программе 1С: Отель, у специалистов есть возможность отправить данные сразу в Битрикс 24 и уже в CRM системе управлять сделкой по воронкам продаж используя доступные инструменты программы", expanded:false, image: "url('../../../assets/pages/K2.png')"},
        { title: "Обучение работы сотрудников в 1С: Отель", description:"Специалист по типовым и нетиповым конфигурациям 1С: Отель, разберет все направления и наглядно проведет обучение, затронув основные аспекты. Обучение проходит поэтапно в онлайн формате в удобное время", expanded:false, image: "url('../../../assets/pages/K3.png')"},
        { title: "Расширения и обработки для программы 1С: Отель", description:"Наши специалисты готовы решить любого рода задачу, касаемо программирования программы 1С: Отель. Большой опыт работы позволяет закрывать не только базовые вопросы, как: печатные формы или отчеты, но так же разрабатывать отдельные расширения и обработки", expanded:false, image: "url('../../../assets/pages/K4.png')"}, 
      ],
      baner: {
        image: "assets/services/Group 435.png",
        left: {
          title: "Купить программу 1С:Отель",
          description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
          image: "assets/services/Group 437.png",
          href: "",
        },
        right: {
          title: "Обучение программе 1С:Отель",
          description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
          image: "assets/services/Group 438.png",
          href: "",
        },
        
      },
      text1:{
        title: "Внедрение программы 1С:Отель ",
        description:[
          {
            text:"Проведем аудит и предложим правильное программное обеспечивание, как в базовой конфигурации, так и в индивидуальной. Внедрим и настроим программу на любом этапе, обучим персонал. Настроим сложные интеграции и создадим структуру по обмену данных. Разработаем новые печатные формы и отчеты. Синхронизируем кассы-онлайн, интернет эквайринг. Осуществляем базовую и расширенную техническую поддержку ",
          }
        ]
      },
      text2:{
        description:[
          {
            html: "<p>Для техподдержки программы 1С: Предприятие “Отель”, перейдите в раздел <a class='link' href='/'>сопровождение</a></p>",
          },
          
        ]
      },
      text3:{
        description:[
          {
            image: "assets/services/Group 373.png",
            html: "<p>Разработка и внедрение нетиповых решений, в разделе <a class='link' href='/'>программирование</a></p>",
            bold: true,
          }
        ]
      },
      text4:{
        title:"РЕКОМЕНДАЦИИ ПО НАСТРОЙКИ 1С:ОТЕЛЬ",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      text5:{
        title:"ПРАВИЛЬНОЕ ИСПОЛЬЗОВАНИЕ CHANNEL MANAGER",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
    },

    {
      url:"services/integration/bp",
      title:"1С:Бухгалтерия - Внедрение",
      description: "Проведем аудит и предложим правильное программное обеспечивание, как в базовой конфигурации, так и в индивидуальной. Внедрим и настроим программу на любом этапе, обучим персонал и закроем белые пятна в работе. Настроим сложные интеграции и создадим структуру по обмену данных. Разработаем новые печатные формы и отчеты. Синхронизируем кассы-онлайн, интернет эквайринг. Осуществляем базовую и расширенную техническую поддержку. Подробнее о программе 1С:Отель Предприятие 8",
      packages: this.packages.bp,
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Услуги",
          href: "services/integration/bp",
        },
        {
          title: "Внедрение",
          href: "services/integration/bp",
        },
        {
          title: "1С:Бухалтерия",
          href: "services/integration/bp",
        },
      ],
      baner: {
        image: "assets/services/Group 435.png",
        left: {
          title: "Купить программу 1С:Отель",
          description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
          image: "assets/services/Group 437.png",
          href: "",
        },
        right: {
          title: "Обучение программе 1С:Отель",
          description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
          image: "assets/services/Group 438.png",
          href: "",
        },
        
      },
      text1:{
        title: "ВНЕДРЕНИЕ НА ОСНОВЕ БАЗОВОЙ КОНФИГУРАЦИИ 1С:ПРЕДПРИЯТИЕ “ОТЕЛЬ”",
        description:[
          {
            text:"В программе управления отелем, как и в сложном механизме, есть множество аспектов на которых строиться вся работа. Для простоты понимания, мы разделили этапы внедрения на блоки, от регистрации юридических данных компании, настройки тарифов, подключение терминалов, до сложных интеграций",
          }
        ]
      },
      text2:{
        description:[
          {
            text: "Для техподдержки программы 1С: Предприятие “Отель”, перейдите в раздел",
          },
          {
            text: "сопровождение",
            href: "",
            isRow: true,  
          }
        ]
      },
      text3:{
        description:[
          {
            image: "assets/services/Group 373.png",
            text: "Разработка и внедрение нетиповых решений, в разделе",
            bold: true,
          },
          {
            text: "программирование",
            href: "",
            isRow: true,  
            bold: true,
          }
        ]
      },
      text4:{
        title:"РЕКОМЕНДАЦИИ ПО НАСТРОЙКИ 1С:ОТЕЛЬ",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      text5:{
        title:"ПРАВИЛЬНОЕ ИСПОЛЬЗОВАНИЕ CHANNEL MANAGER",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
    },

    {
      url:"services/integration/ut",
      title:"1С:УправлениеТорговлей - Внедрение",
      description: "Проведем аудит и предложим правильное программное обеспечивание, как в базовой конфигурации, так и в индивидуальной. Внедрим и настроим программу на любом этапе, обучим персонал и закроем белые пятна в работе. Настроим сложные интеграции и создадим структуру по обмену данных. Разработаем новые печатные формы и отчеты. Синхронизируем кассы-онлайн, интернет эквайринг. Осуществляем базовую и расширенную техническую поддержку. Подробнее о программе 1С:Отель Предприятие 8",
      packages: this.packages.ut,
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Услуги",
          href: "services/integration/ut",
        },
        {
          title: "Внедрение",
          href: "services/integration/ut",
        },
        {
          title: "1С:Управление торговлей",
          href: "services/integration/ut",
        },
      ],
      baner: {
        image: "assets/services/Group 435.png",
        left: {
          title: "Купить программу 1С:Отель",
          description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
          image: "assets/services/Group 437.png",
          href: "",
        },
        right: {
          title: "Обучение программе 1С:Отель",
          description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
          image: "assets/services/Group 438.png",
          href: "",
        },
        
      },
      text1:{
        title: "ВНЕДРЕНИЕ НА ОСНОВЕ БАЗОВОЙ КОНФИГУРАЦИИ 1С:ПРЕДПРИЯТИЕ “ОТЕЛЬ”",
        description:[
          {
            text:"В программе управления отелем, как и в сложном механизме, есть множество аспектов на которых строиться вся работа. Для простоты понимания, мы разделили этапы внедрения на блоки, от регистрации юридических данных компании, настройки тарифов, подключение терминалов, до сложных интеграций",
          }
        ]
      },
      text2:{
        description:[
          {
            text: "Для техподдержки программы 1С: Предприятие “Отель”, перейдите в раздел",
          },
          {
            text: "сопровождение",
            href: "",
            isRow: true,  
          }
        ]
      },
      text3:{
        description:[
          {
            image: "assets/services/Group 373.png",
            text: "Разработка и внедрение нетиповых решений, в разделе",
            bold: true,
          },
          {
            text: "программирование",
            href: "",
            isRow: true,  
            bold: true,
          }
        ]
      },
      text4:{
        title:"РЕКОМЕНДАЦИИ ПО НАСТРОЙКИ 1С:ОТЕЛЬ",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      text5:{
        title:"ПРАВИЛЬНОЕ ИСПОЛЬЗОВАНИЕ CHANNEL MANAGER",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
    },

    {
      url:"services/integration/roznica",
      title:"1С:Розница - Внедрение",
      description: "Проведем аудит и предложим правильное программное обеспечивание, как в базовой конфигурации, так и в индивидуальной. Внедрим и настроим программу на любом этапе, обучим персонал и закроем белые пятна в работе. Настроим сложные интеграции и создадим структуру по обмену данных. Разработаем новые печатные формы и отчеты. Синхронизируем кассы-онлайн, интернет эквайринг. Осуществляем базовую и расширенную техническую поддержку. Подробнее о программе 1С:Отель Предприятие 8",
      packages: this.packages.roznica,
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Услуги",
          href: "services/integration/roznica",
        },
        {
          title: "Внедрение",
          href: "services/integration/roznica",
        },
        {
          title: "1С:Розница",
          href: "services/integration/roznica",
        },
      ],
      baner: {
        image: "assets/services/Group 435.png",
        left: {
          title: "Купить программу 1С:Отель",
          description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
          image: "assets/services/Group 437.png",
          href: "",
        },
        right: {
          title: "Обучение программе 1С:Отель",
          description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
          image: "assets/services/Group 438.png",
          href: "",
        },
        
      },
      text1:{
        title: "ВНЕДРЕНИЕ НА ОСНОВЕ БАЗОВОЙ КОНФИГУРАЦИИ 1С:ПРЕДПРИЯТИЕ “ОТЕЛЬ”",
        description:[
          {
            text:"В программе управления отелем, как и в сложном механизме, есть множество аспектов на которых строиться вся работа. Для простоты понимания, мы разделили этапы внедрения на блоки, от регистрации юридических данных компании, настройки тарифов, подключение терминалов, до сложных интеграций",
          }
        ]
      },
      text2:{
        description:[
          {
            text: "Для техподдержки программы 1С: Предприятие “Отель”, перейдите в раздел",
          },
          {
            text: "сопровождение",
            href: "",
            isRow: true,  
          }
        ]
      },
      text3:{
        description:[
          {
            image: "assets/services/Group 373.png",
            text: "Разработка и внедрение нетиповых решений, в разделе",
            bold: true,
          },
          {
            text: "программирование",
            href: "",
            isRow: true,  
            bold: true,
          }
        ]
      },
      text4:{
        title:"РЕКОМЕНДАЦИИ ПО НАСТРОЙКИ 1С:ОТЕЛЬ",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      text5:{
        title:"ПРАВИЛЬНОЕ ИСПОЛЬЗОВАНИЕ CHANNEL MANAGER",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
    },
//Трактир услуги
    {
      url:"services/integration/traktir",
      title:"1С:Трактиръ - Внедрение",
      description: "Проведем аудит и предложим правильное программное обеспечивание, как в базовой конфигурации, так и в индивидуальной. Внедрим и настроим программу на любом этапе, обучим персонал и закроем белые пятна в работе. Настроим сложные интеграции и создадим структуру по обмену данных. Разработаем новые печатные формы и отчеты. Синхронизируем кассы-онлайн, интернет эквайринг. Осуществляем базовую и расширенную техническую поддержку. Подробнее о программе 1С:Отель Предприятие 8",
      packages: this.packages.traktir,
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Услуги",
        },
        {
          title: "1С:Трактиръ",
          href: "services/integration/traktir",
        },
      ],
      baner: {
        image: "assets/services/Group 435.png",
        left: {
          title: "Купить программу 1С:Отель",
          description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
          image: "assets/services/Group 437.png",
          href: "",
        },
        right: {
          title: "Обучение программе 1С:Отель",
          description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
          image: "assets/services/Group 438.png",
          href: "",
        },
        
      },
      text1:{
        title: "Внедрение программы Трактиръ - автоматизация питания",
        description:[
          {
            text:"Наша компания обеспечивает полный цикл работ для автоматизации сложных процессов в программе Трактиръ. После внедрения клиенты получают полностью готовую к работе программу и настроенное оборудование в кротчайшие сроки, так-же мы проводим обучение сотрудников и помогаем с регистрацией в необходимых сервисах и службах",
          }
        ]
      },
      text2:{
        description:[
          {
            text: "Для техподдержки программы 1С: Предприятие “Отель”, перейдите в раздел",
          },
          {
            text: "сопровождение",
            href: "",
            isRow: true,  
          }
        ]
      },
      text3:{
        description:[
          {
            image: "assets/services/Group 373.png",
            text: "Разработка и внедрение нетиповых решений, в разделе",
            bold: true,
          },
          {
            text: "программирование",
            href: "",
            isRow: true,  
            bold: true,
          }
        ]
      },
      text4:{
        title:"РЕКОМЕНДАЦИИ ПО НАСТРОЙКИ 1С:ОТЕЛЬ",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      text5:{
        title:"ПРАВИЛЬНОЕ ИСПОЛЬЗОВАНИЕ CHANNEL MANAGER",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      accordion:[
        { title: "Внедрение популярной CRM Системы Битрикс 24", description:"Автоматизация сложных бизнес процессов и протепирование всех этапов и возможных сценариев. Формирование воронок продаж, работа с базами данных. Внедрение возможно на любом этапе работ в кофмортной и знакомой среде для специалистов, проводим обучение", href:"/", expanded:true, image: "url('../../../assets/pages/services-integration-bitrix24/B1.png')"},
        { title: "Выгрузка сделок (бронирования) в CRM", description:"Создавая или редактируя бронирование в программе 1С: Отель, у специалистов есть возможность отправить данные сразу в Битрикс 24 и уже в CRM системе управлять сделкой по воронкам продаж ", expanded:false, image: "url('../../../assets/pages/services-integration-bitrix24/B2.png')"},
        { title: "Интеграция Битрикс 24 с программой 1С: Отель", description:"Для удобства работы отеда бронирования нами разработана интеграция не только по обмену данными между программой управления отелем и CRM, но и возможность управлять бронями", expanded:false, image: "url('../../../assets/pages/services-integration-bitrix24/B3.png')"},
        { title: "Модуль бронирования и Битрикс 24 ", description:"Используя модуль бронирования, как истоичник прямых продаж с сайта отеля, у специалистов появляется возможность автоматически видеть все брони прямо в CRM системе, что значительно упращает и автоматизирует работу", expanded:false, image: "url('../../../assets/pages/services-integration-bitrix24/B4.png')"}, 
      ],
    },
//обучение 1с отель
    {
      url:"training-1c-hotel",
      title:"Обучение 1С: Отель",
      description: "Полный цикл обучения состоящий из 3 частей для сотрудников службы приема и размещения отеля в программе 1С-Отель, ред.9 (тонкий клиент). Рассмотрены все операции работы фронт-офис - от бронирования до выселения и закрытия дня. Данный курс предназначен для базовой подготовки пользователей программы1С-Отель 9. Изучение курса позволяет освоить реализацию основных функций отдела ресепшн средствами программы1С-Отель.",
      packages: this.packages.training,
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Услуги",
        },
        {
          title: "Обучение 1С: Отель",
          href: "training-1c-hotel",
        },
      ],
      baner: {
        image: "assets/services/Group 435.png",
        left: {
          title: "Купить программу 1С:Отель",
          description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
          image: "assets/services/Group 437.png",
          href: "",
        },
        right: {
          title: "Обучение программе 1С:Отель",
          description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
          image: "assets/services/Group 438.png",
          href: "",
        },
        
      },
      text1:{
        title: "Обучение программе 1С: Отель",
        description:[
          {
            text:"Полный цикл обучения состоящий из 3 частей для сотрудников службы приема и размещения отеля в программе 1С-Отель, ред.9 (тонкий клиент). Рассмотрены все операции работы фронт-офис - от бронирования до выселения и закрытия дня. Данный курс предназначен для базовой подготовки пользователей программы1С-Отель 9. Изучение курса позволяет освоить реализацию основных функций отдела ресепшн средствами программы1С-Отель.",
          }
        ]
      },
      text2:{
        description:[
          {
            text: "Для техподдержки программы 1С: Предприятие “Отель”, перейдите в раздел",
          },
          {
            text: "сопровождение",
            href: "",
            isRow: true,  
          }
        ]
      },
      text3:{
        description:[
          {
            image: "assets/services/Group 373.png",
            text: "Разработка и внедрение нетиповых решений, в разделе",
            bold: true,
          },
          {
            text: "программирование",
            href: "",
            isRow: true,  
            bold: true,
          }
        ]
      },
      text4:{
        title:"РЕКОМЕНДАЦИИ ПО НАСТРОЙКИ 1С:ОТЕЛЬ",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      text5:{
        title:"ПРАВИЛЬНОЕ ИСПОЛЬЗОВАНИЕ CHANNEL MANAGER",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      accordion:[
        { title: "Современный подход в управление отелем", description:"Программа 1С: Отель на сегодняшний день является одной из самых популярных PMS систем для управления отелем в России. Структура программы постоянно дописывается с помощью расширений и интеграций", href:"/", expanded:true, image: "url('../../../assets/pages/1.png')",img:'/assets/pages/1.png'},
        { title: "Кол-во рабочих мест (Лицензии)", description:"При приобретении “основной поставки 1С” - вы получаете лицензию для одного рабочего места, дополнительные лицензии для увеличения рабочих мест для ваших сотрудников приобретаются отдельно ", expanded:false, image: "url('../../../assets/pages/2.png')", img:'/assets/pages/2.png'},
        { title: "Подбор и настройка оборудования", description:"Проектируем решения любого масштаба и предложим оборудование под индивидуальные задачи. Настроим работу по сети и интеграции. Зарегистрируем в налоговой службе, подключим к необходимым сервисам", expanded:false, image: "url('../../../assets/pages/3-1.png')", img:'/assets/pages/3-1.png'},
        { title: "Полный цикл сопровождения и тех поддрежки", description:"Находим и устраняем многие проблемы еще до их появления. Понимаем наших клиентов с полуслова, оперативно решаем проблемы. Ваш проект получает лучшее из возможных технических решений учитывая все тонкости в работе", expanded:false, image: "url('../../../assets/pages/4.png')", img:'/assets/pages/4.png'}, 
      ],
      accordion2:[
        { title: "Без комиссии и ограничений. Модуль бронирования", description:"Прямое бронирование на вашем сайте в удобном и понятном визуальном исполнении для поиска свободных номеров. Модуль отвечает всем современным требованиям для комфортной работы отдела бронирования", href:"/", expanded:true, image: "url('../../../assets/pages/K1.png')", },
        { title: "CRM Битрикс 24 для отеля", description:"Создавая или редактируя бронирование в программе 1С: Отель, у специалистов есть возможность отправить данные сразу в Битрикс 24 и уже в CRM системе управлять сделкой по воронкам продаж используя доступные инструменты программы", expanded:false, image: "url('../../../assets/pages/K2.png')"},
        { title: "Обучение работы сотрудников в 1С: Отель", description:"Специалист по типовым и нетиповым конфигурациям 1С: Отель, разберет все направления и наглядно проведет обучение, затронув основные аспекты. Обучение проходит поэтапно в онлайн формате в удобное время", expanded:false, image: "url('../../../assets/pages/K3.png')"},
        { title: "Расширения и обработки для программы 1С: Отель", description:"Наши специалисты готовы решить любого рода задачу, касаемо программирования программы 1С: Отель. Большой опыт работы позволяет закрывать не только базовые вопросы, как: печатные формы или отчеты, но так же разрабатывать отдельные расширения и обработки", expanded:false, image: "url('../../../assets/pages/K4.png')"}, 
      ],
    },
//сопровождение 1с отель 
{
  url:"services/escort-1c-hotel",
  title:"Обучение 1С: Отель",
  description: "Полный цикл обучения состоящий из 3 частей для сотрудников службы приема и размещения отеля в программе 1С-Отель, ред.9 (тонкий клиент). Рассмотрены все операции работы фронт-офис - от бронирования до выселения и закрытия дня. Данный курс предназначен для базовой подготовки пользователей программы1С-Отель 9. Изучение курса позволяет освоить реализацию основных функций отдела ресепшн средствами программы1С-Отель.",

  breadcrumbs: [
    {
      title: "Главная",
      href: "/",
    },
    {
      title: "Услуги",
    },
    {
      title: "Сопровождение 1С: Отель",
      href: "services/escort-1c-hotel",
    },
  ],
  banerOne: {
  
    imageBg: "",
    imgChild: "url('../../../assets/pages/escort-one-c-hotel/1.png')",
    title: "СОВРЕМЕННЫЙ ПОДХОД В СОПРОВОЖДЕНИИ 1С: ОТЕЛЬ",
    description: "Понимаем специфику работы отельеров, более 10 лет в сфере размещения гостей. Оперативно решаем проблемы. Наши клиенты получают полную техническую поддержку на любом этапе, учитывая все тонкости в работе",
    titleLink:'БЕСПЛАТНЫЙ АУДИТ',
    link:'',
    linkDiscription:'Проведем бесплатный аудит вашего объекта размещения гостей в программе 1С:Отель',
  },
  baner: {
    image: "assets/services/Group 435.png",
    left: {
      title: "Купить программу 1С:Отель",
      description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
      image: "assets/services/Group 437.png",
      href: "",
    },
    right: {
      title: "Обучение программе 1С:Отель",
      description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
      image: "assets/services/Group 438.png",
      href: "",
    },
    
  },
  text1:{
    title: "Сопровождение программе 1С:Отель",
    description:[
      {
        text:"Возьмём любой объект размещения гостей на профессиональное сопровождение. Наш опыт работы позволяет вести клиентов с любым номерным фондом и внутренними нетиповыми конфигурациями. Понимаем специфику работы разных отделов и оперативно решаем задачи. Настраиваем сложные интеграции, помогаем с системами онлайн оплат и с сервисами бронирования. Предложим собственные продукты и проводим обучение сотрудников в программе 1С: Отель",
      }
    ]
  },
  contentImgRiht:{
      img:'assets/pages/escort-one-c-hotel/2.png', 
      leftTitle:'Ознакомим с самыми важными аспектами  работы в PMS системе 1С: Отель ',
      discription:'Основной этап работы который включает в себя некоторое количество мероприятий, которые позволяют выявить нюансы используемой у клиента конфигурации и автоматизировать процессы. Проведем анализ установленного оборудования и режима работы по сети, а так же тестирование баз данных',
      list:[
        { listTitle: "Анализ действующих бизнес процессов и их автоматизация "},
        { listTitle: "Комплексное тестирование баз данных и оптимизация структуры "},
        { listTitle: "Проведем обучение сотрудников и затроним необходимые темы"},
      ],    
  },
  contentImgRiht2:{
    img:'assets/pages/escort-one-c-hotel/4.png', 
    leftTitle:'Внедрим самые современные возможности и решения на базе 1С: Отель. Настроим интеграции и CRM сервисы',
    discription:'Одно из самых важных направлений внутренних компонентов в программе 1С: Отель. Создание новых конфигураций таких как: парковка, переселение, новые способы оплат. Программирование печатных форм, сводок и отчетов. Интеграции с популярными CRM как: Битрикс 24, Сигур. Подключение популярных систем для бронирования онлайн',
    list:[
      { listTitle: "Обновление конфигураций, работа с лицензиями"},
      { listTitle: "Внутренняя отчетность. CRM системы. Бонусы лояльности"},
      { listTitle: "Модуль бронирования и работа с менеджерами каналов"},
    ],    
},
contentImgLeft:{
  img:'assets/pages/escort-one-c-hotel/3.png', 
  leftTitle:'Настройка системы СПИР - планирование и распределения бронирований',
  discription:'Затронем все аспекты в работе по поселению и размещения гостей в программе 1С:Отель. Создадим правильно настроенный “свободный” номерной фонд, рассмотрим варианты шаблонов размещения, прогноз загрузки и настроим права доступа для каждого сотрудника',
  list:[
    { listTitle: "Настройка уровней доступа сотрудников, безопасность данных"},
    { listTitle: "Управление номерным фондом, прогнозирование загрузки"},
    { listTitle: "Создание шаблонов и правил: бронирование, заселение, переселение"},
  ],    
},
  marketingCards:{
    cards:[
      {
        image:"assets/pages/escort-one-c-hotel/Icon 1.png",
        text:"Оборудование и сервисы",
        discription:"Полный цикл настройки оборудования (кассы- онлайн, сканеры, терминалы). Регистрации / снятие ККМ, ФНС. Работа с сервисами ОФД ",
      },
      {
        image:"assets/pages/escort-one-c-hotel/Icon 2.png",
        text:"Онлайн бронирование и способы оплаты",
        discription:"Полная интеграция с самыми популярными банками для системы онлайн транзакций. Формирование  индивидуальных ссылок оплат",
      },
      {
        image:"assets/pages/escort-one-c-hotel/Icon 3.png",
        text:"Расширения и конфигурации",
        discription:"Индивидуальная разработка по ТЗ. Программирование нетиповых печатных форм, разработка разноплановых отчетов",
      },
    ]
  },
},

//оборудование-регистрация ФНС
{
  url:"equipment/online-sales-register/registration-fns",
  title:"Обучение 1С: Отель",
  description: "Полный цикл обучения состоящий из 3 частей для сотрудников службы приема и размещения отеля в программе 1С-Отель, ред.9 (тонкий клиент). Рассмотрены все операции работы фронт-офис - от бронирования до выселения и закрытия дня. Данный курс предназначен для базовой подготовки пользователей программы1С-Отель 9. Изучение курса позволяет освоить реализацию основных функций отдела ресепшн средствами программы1С-Отель.",

  breadcrumbs: [
    {
      title: "Главная",
      href: "/",
    },
    {
      title: "Оборудование",
    },
    {
      title: "Регистрация кассы-онлайн в ФНС",
      href: "equipment/online-sales-register/registration-fns",
    },
  ],
  banerOne: {
  
    imageBg: "",
    imgChild: "url('../../../assets/pages/registration-fns/1.png')",
    title: "ПРОСТО И БЫСТРО ПОЛНЫЙ ПАКЕТ ПОДКЛЮЧЕНИЯ",
    description: "",
    titleLink:'ОФОРМИТЬ ПАКЕТ',
    link:'',
    linkDiscription:'',

    list:[
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Регистрация в налоговую ФНС",
      },
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Активация кабинета ОФД",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Настройка ККМ",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к 1С",
      },
    ], 
      
    prise:[
      { 
        title: "Стоимость : ",
        priseNew: "10000 ₽",
        priseOld: "12000 ₽",
        sale: "2000 ₽",
      },
    ],
  },
 
  panelInformation:{
    img:"url('../../../assets/services/warnin.svg')", 
    discription:'Для техподдержки и настройки уже установленного у вас оборудования, обратитесь в раздел', 
    titleLink:'техподдержка Обращаем Ваше внимание, что для некоторых видов товаров требуется маркировка и ЕГАИС',
    link:'',
  },

  baner: {
    image: "assets/services/Group 435.png",
    left: {
      title: "Купить программу 1С:Отель",
      description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
      image: "assets/services/Group 437.png",
      href: "",
    },
    right: {
      title: "Обучение программе 1С:Отель",
      description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
      image: "assets/services/Group 438.png",
      href: "",
    },
    
  },
  text1:{
    title: "Регистрация кассы - онлайн в ФНС",
    description:[
      {
        text:"Регистрация кассы в налоговой — это обязательная процедура для предпринимателей и организаций, которые напрямую работают с физлицами. Во время регистрации касса получает регистрационный номер машины — уникальный, 16-значный, идентификатор устройства. РНМ помогает налоговой отследить, с какой кассы пришла информация о продажах",
      }
    ]
  },
  serviseKassa:[
    {
      button:"Регистрация кассы в налоговую службу ФНС",
      products:["registration-kassa-fns"]
    },
    {
      button:"Настройка ККМ ",
      products:["registration-kassa-kkm"]
    },
    {
      button:"Подключение к программе 1С",
      products:["registration-kassa-1C"]
    },
    {
      button:"Подключение кассы онлайн к ОФД",
      products:["registration-kassa-ofd"]
    },
  
  ],
 
}, 
//оборудование Подключение кассы- онлайн к ОФД
{
  url:"equipment/online-sales-register/connection-ofd",
  title:"Подключение кассы - онлайн к ОФД",
  description: "По 54-ФЗ онлайн-кассы должны передавать данные о продажах в налоговую в режиме реального времени. Для этого нужен посредник, который следит за тем, чтобы чеки передавались без перебоев, информация была корректной. Им является оператор фискальных данных. ОФД передает данные не только о кассовых чеках, но и регистрации/перерегистрации онлайн-кассы, замене фискального накопителя, открытии и закрытии кассовой смены",

  breadcrumbs: [
    {
      title: "Главная",
      href: "/",
    },
    {
      title: "Оборудование",
    },
    {
      title: "Подключение кассы-онлайн к ОФД",
      href: "equipment/online-sales-register/connection-ofd",
    },
  ],
  banerOne: {
  
    imageBg: "",
    imgChild: "url('../../../assets/pages/registration-fns/1.png')",
    title: "ПРОСТО И БЫСТРО ПОЛНЫЙ ПАКЕТ ПОДКЛЮЧЕНИЯ",
    description: "",
    titleLink:'ОФОРМИТЬ ПАКЕТ',
    link:'',
    linkDiscription:'',

    list:[
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Регистрация в налоговую ФНС",
      },
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Активация кабинета ОФД",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Настройка ККМ",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к 1С",
      },
    ], 
      
    prise:[
      { 
        title: "Стоимость : ",
        priseNew: "10000 ₽",
        priseOld: "12000 ₽",
        sale: "2000 ₽",
      },
    ],
  },
 
  panelInformation:{
    img:"url('../../../assets/services/warnin.svg')", 
    discription:'Для техподдержки и настройки уже установленного у вас оборудования, обратитесь в раздел', 
    titleLink:'техподдержка Обращаем Ваше внимание, что для некоторых видов товаров требуется маркировка и ЕГАИС',
    link:'',
  },

  baner: {
    image: "assets/services/Group 435.png",
    left: {
      title: "Купить программу 1С:Отель",
      description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
      image: "assets/services/Group 437.png",
      href: "",
    },
    right: {
      title: "Обучение программе 1С:Отель",
      description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
      image: "assets/services/Group 438.png",
      href: "",
    },
    
  },
  text1:{
    title: "Подключение кассы - онлайн к ОФД",
    description:[
      {
        text:"По 54-ФЗ онлайн-кассы должны передавать данные о продажах в налоговую в режиме реального времени. Для этого нужен посредник, который следит за тем, чтобы чеки передавались без перебоев, информация была корректной. Им является оператор фискальных данных. ОФД передает данные не только о кассовых чеках, но и регистрации/перерегистрации онлайн-кассы, замене фискального накопителя, открытии и закрытии кассовой смены",
      }
    ]
  },
  serviseKassa:[
    {
      button:"Подключение кассы онлайн к ОФД",
      products:["registration-kassa-ofd"]
    },
    {
      button:"Регистрация кассы в налоговую службу ФНС",
      products:["registration-kassa-fns"]
    },
    {
      button:"Подключение к программе 1С",
      products:["registration-kassa-1C"]
    },
    {
      button:"Настройка ККМ ",
      products:["registration-kassa-kkm"]
    },
  
  ],
 
},   
//Настройка кассы- онлайн ККМ 
{
  url:"equipment/online-sales-register/customization-kkm",
  title:"Подключение кассы - онлайн к ОФД",
  description: "По 54-ФЗ онлайн-кассы должны передавать данные о продажах в налоговую в режиме реального времени. Для этого нужен посредник, который следит за тем, чтобы чеки передавались без перебоев, информация была корректной. Им является оператор фискальных данных. ОФД передает данные не только о кассовых чеках, но и регистрации/перерегистрации онлайн-кассы, замене фискального накопителя, открытии и закрытии кассовой смены",

  breadcrumbs: [
    {
      title: "Главная",
      href: "/",
    },
    {
      title: "Оборудование",
    },
    {
      title: "Настройка кассы-онлайн ККМ ",
      href: "equipment/online-sales-register/customization-kkm",
    },
  ],
  banerOne: {
  
    imageBg: "",
    imgChild: "url('../../../assets/pages/registration-fns/1.png')",
    title: "ПРОСТО И БЫСТРО ПОЛНЫЙ ПАКЕТ ПОДКЛЮЧЕНИЯ",
    description: "",
    titleLink:'ОФОРМИТЬ ПАКЕТ',
    link:'',
    linkDiscription:'',

    list:[
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Регистрация в налоговую ФНС",
      },
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Активация кабинета ОФД",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Настройка ККМ",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к 1С",
      },
    ], 
      
    prise:[
      { 
        title: "Стоимость : ",
        priseNew: "10000 ₽",
        priseOld: "12000 ₽",
        sale: "2000 ₽",
      },
    ],
  },
 
  panelInformation:{
    img:"url('../../../assets/services/warnin.svg')", 
    discription:'Для техподдержки и настройки уже установленного у вас оборудования, обратитесь в раздел', 
    titleLink:'техподдержка Обращаем Ваше внимание, что для некоторых видов товаров требуется маркировка и ЕГАИС',
    link:'',
  },

  baner: {
    image: "assets/services/Group 435.png",
    left: {
      title: "Купить программу 1С:Отель",
      description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
      image: "assets/services/Group 437.png",
      href: "",
    },
    right: {
      title: "Обучение программе 1С:Отель",
      description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
      image: "assets/services/Group 438.png",
      href: "",
    },
    
  },
  text1:{
    title: "Настройка кассы - онлайн ККМ ",
    description:[
      {
        text:"ККМ регистрирует каждую продажу, учитывает денежные поступления, формирует кассовый чек и отправляет сведения о проданном товаре или оказанной услуге в Федеральную налоговую службу. Активная системная ККМ чаще всего представляет собой POS-терминал или компьютер в кассовом корпусе с кассовой программой, поддерживающей работу в составе автоматизированных систем. Фискальный регистратор фиксирует проведенные денежные операции в специальной памяти и часто интегрируется с принтером чеков",
      }
    ]
  },
  serviseKassa:[
    {
      button:"Настройка ККМ ",
      products:["registration-kassa-kkm"]
    },
    {
      button:"Регистрация кассы в налоговую службу ФНС",
      products:["registration-kassa-fns"]
    },
    {
      button:"Подключение к программе 1С",
      products:["registration-kassa-1C"]
    },
    {
      button:"Подключение кассы онлайн к ОФД",
      products:["registration-kassa-ofd"]
    },
  
  ],
 
}, 
//Настройка кассы- онлайн ККМ 
{
  url:"equipment/online-sales-register/connection-1C",
  title:"Подключение кассы - онлайн к ОФД",
  description: "По 54-ФЗ онлайн-кассы должны передавать данные о продажах в налоговую в режиме реального времени. Для этого нужен посредник, который следит за тем, чтобы чеки передавались без перебоев, информация была корректной. Им является оператор фискальных данных. ОФД передает данные не только о кассовых чеках, но и регистрации/перерегистрации онлайн-кассы, замене фискального накопителя, открытии и закрытии кассовой смены",

  breadcrumbs: [
    {
      title: "Главная",
      href: "/",
    },
    {
      title: "Оборудование",
    },
    {
      title: "Подключение кассы-онлайн к программе 1С",
      href: "equipment/online-sales-register/connection-1C",
    },
  ],
  banerOne: {
  
    imageBg: "",
    imgChild: "url('../../../assets/pages/registration-fns/1.png')",
    title: "ПРОСТО И БЫСТРО ПОЛНЫЙ ПАКЕТ ПОДКЛЮЧЕНИЯ",
    description: "",
    titleLink:'ОФОРМИТЬ ПАКЕТ',
    link:'',
    linkDiscription:'',

    list:[
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Регистрация в налоговую ФНС",
      },
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Активация кабинета ОФД",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Настройка ККМ",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к 1С",
      },
    ], 
      
    prise:[
      { 
        title: "Стоимость : ",
        priseNew: "10000 ₽",
        priseOld: "12000 ₽",
        sale: "2000 ₽",
      },
    ],
  },
 
  panelInformation:{
    img:"url('../../../assets/services/warnin.svg')", 
    discription:'Для техподдержки и настройки уже установленного у вас оборудования, обратитесь в раздел', 
    titleLink:'техподдержка Обращаем Ваше внимание, что для некоторых видов товаров требуется маркировка и ЕГАИС',
    link:'',
  },

  baner: {
    image: "assets/services/Group 435.png",
    left: {
      title: "Купить программу 1С:Отель",
      description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
      image: "assets/services/Group 437.png",
      href: "",
    },
    right: {
      title: "Обучение программе 1С:Отель",
      description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
      image: "assets/services/Group 438.png",
      href: "",
    },
    
  },
  text1:{
    title: "Подключение кассы - онлайн к программе 1С",
    description:[
      {
        text:"Современные программы 1С представляют собой мощный инструмент для интеграции с онлайн-кассами и обеспечения эффективного управления бизнесом. Благодаря широкому распространению и универсальности продуктов 1С, они становятся незаменимым решением для компаний различных отраслей. Подключение кассового аппарата к вышеперечисленным программам предполагает как обмен данными между кассовым аппаратом и учетной программой, так и подключение фискального регистратора к кассовой программе",
      }
    ]
  },
  serviseKassa:[
    {
      button:"Подключение к программе 1С",
      products:["registration-kassa-1C"]
    },
    {
      button:"Регистрация кассы в налоговую службу ФНС",
      products:["registration-kassa-fns"]
    },
    {
      button:"Настройка ККМ ",
      products:["registration-kassa-kkm"]
    },
    {
      button:"Подключение кассы онлайн к ОФД",
      products:["registration-kassa-ofd"]
    },
  
  ],
 
}, 
//Подключение к ЕГАИС 
{
  url:"equipment/programs/connection-egais",
  title:"",
  description: "",

  breadcrumbs: [
    {
      title: "Главная",
      href: "/",
    },
    {
      title: "Программы",
    },
    {
      title: "Подключение к ЕГАИС ",
      href: "equipment/programs/connection-egais",
    },
  ],
  banerOne: {
  
    imageBg: "",
    imgChild: "url('../../../assets/pages/programs/Ver23.png')",
    title: "ПРОСТО И БЫСТРО ПОЛНЫЙ ПАКЕТ ПОДКЛЮЧЕНИЯ",
    description: "",
    titleLink:'ОФОРМИТЬ ПАКЕТ',
    link:'',
    linkDiscription:'',

    list:[
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Регистрация честный знак",
      },
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к ЭДО",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к ЕГАИС",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Настройка УТМ",
      },
    ], 
      
    prise:[
      { 
        title: "Стоимость : ",
        priseNew: "20000 ₽",
        priseOld: "22000 ₽",
        sale: "2000 ₽",
      },
    ],
  },
 
  baner: {
    image: "assets/services/Group 435.png",
    left: {
      title: "Купить программу 1С:Отель",
      description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
      image: "assets/services/Group 437.png",
      href: "",
    },
    right: {
      title: "Обучение программе 1С:Отель",
      description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
      image: "assets/services/Group 438.png",
      href: "",
    },
    
  },
  text1:{
    title: "Подключение к ЕГАИС ",
    description:[
      {
        text:"ЕГАИС — это система для отслеживания оборота алкогольной и спиртосодержащей продукции. Она позволяет государству контролировать производство и продажу алкоголя, бороться с подделками. Подключение к системе обязательно для всех, кто торгует алкоголем, в том числе пивом и слабоалкогольными напитками. Ключевым компонентом взаимодействия с ЕГАИС является программно-аппаратный комплекс «Универсальный транспортный модуль» (УТМ)",
      }
    ]
  },
  serviseKassa:[
    {
      button:"Подключение к ЕГАИС",
      products:["connection-egais"]
    },
    {
      button:"Регистрация - Честный знак ",
      products:["connection-chestyi-znak"]
    },
    {
      button:"Подключение к ЭДО ",
      products:["connection-edo"]
    },
    {
      button:"Настройка УТМ",
      products:["connection-utm"]
    },
  
  ],
 
}, 
//Регистрация - Честный знак 
{
  url:"equipment/programs/connection-chestnui-znak",
  title:"",
  description: "",

  breadcrumbs: [
    {
      title: "Главная",
      href: "/",
    },
    {
      title: "Программы",
    },
    {
      title: "Регистрация - Честный знак ",
      href: "equipment/programs/connection-chestnui-znak",
    },
  ],
  banerOne: {
  
    imageBg: "",
    imgChild: "url('../../../assets/pages/programs/Ver23.png')",
    title: "ПРОСТО И БЫСТРО ПОЛНЫЙ ПАКЕТ ПОДКЛЮЧЕНИЯ",
    description: "",
    titleLink:'ОФОРМИТЬ ПАКЕТ',
    link:'',
    linkDiscription:'',

    list:[
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Регистрация честный знак",
      },
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к ЭДО",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к ЕГАИС",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Настройка УТМ",
      },
    ], 
      
    prise:[
      { 
        title: "Стоимость : ",
        priseNew: "20000 ₽",
        priseOld: "22000 ₽",
        sale: "2000 ₽",
      },
    ],
  },
 
  baner: {
    image: "assets/services/Group 435.png",
    left: {
      title: "Купить программу 1С:Отель",
      description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
      image: "assets/services/Group 437.png",
      href: "",
    },
    right: {
      title: "Обучение программе 1С:Отель",
      description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
      image: "assets/services/Group 438.png",
      href: "",
    },
    
  },
  text1:{
    title: "Регистрация - Честный знак ",
    description:[
      {
        text:"Регистрация в системе «Честный ЗНАК» является обязательным требованием для всех участников оборота маркированной продукции: производителей, импортеров, дистрибьюторов, оптовых и розничных компаний. Маркировка предполагает нанесение на товар уникального QR-кода и цифрового кода DataMatrix, который содержит информацию о конкретном продукте. Основная задача системы — повышение уровня безопасности россиян, борьба с контрафактом и некачественными аналогами",
      }
    ]
  },
  serviseKassa:[
    {
      button:"Регистрация - Честный знак ",
      products:["connection-chestyi-znak"]
    },
    {
      button:"Подключение к ЕГАИС",
      products:["connection-egais"]
    },
    {
      button:"Подключение к ЭДО ",
      products:["connection-edo"]
    },
    {
      button:"Настройка УТМ",
      products:["connection-utm"]
    },
  
  ],
 
}, 
//Подключение к ЭДО 
{
  url:"equipment/programs/connection-edo",
  title:"",
  description: "",

  breadcrumbs: [
    {
      title: "Главная",
      href: "/",
    },
    {
      title: "Программы",
    },
    {
      title: "Подключение к ЭДО ",
      href: "equipment/programs/connection-edo",
    },
  ],
  banerOne: {
  
    imageBg: "",
    imgChild: "url('../../../assets/pages/programs/Ver23.png')",
    title: "ПРОСТО И БЫСТРО ПОЛНЫЙ ПАКЕТ ПОДКЛЮЧЕНИЯ",
    description: "",
    titleLink:'ОФОРМИТЬ ПАКЕТ',
    link:'',
    linkDiscription:'',

    list:[
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Регистрация честный знак",
      },
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к ЭДО",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к ЕГАИС",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Настройка УТМ",
      },
    ], 
      
    prise:[
      { 
        title: "Стоимость : ",
        priseNew: "20000 ₽",
        priseOld: "22000 ₽",
        sale: "2000 ₽",
      },
    ],
  },
 
  baner: {
    image: "assets/services/Group 435.png",
    left: {
      title: "Купить программу 1С:Отель",
      description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
      image: "assets/services/Group 437.png",
      href: "",
    },
    right: {
      title: "Обучение программе 1С:Отель",
      description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
      image: "assets/services/Group 438.png",
      href: "",
    },
    
  },
  text1:{
    title: "Подключение к ЭДО ",
    description:[
      {
        text:"Эта система создания, регистрации и обмена документов без применения бумажных носителей. Используя ЭДО, вы мгновенно доставляете контрагентам и получаете от них первичку, договоры и письма, сдаёте налоговые отчёты, проводите платежи, подписываете документы с любого устройства, храните документы в электронном архиве и имеете доступ к ним с любого устройств",
      }
    ]
  },
  serviseKassa:[
    {
      button:"Подключение к ЭДО ",
      products:["connection-edo"]
    },
    {
      button:"Подключение к ЕГАИС",
      products:["connection-egais"]
    },
    {
      button:"Регистрация - Честный знак ",
      products:["connection-chestyi-znak"]
    },
    {
      button:"Настройка УТМ",
      products:["connection-utm"]
    },
  
  ],
 
}, 
//Настройка УТМ
{
  url:"equipment/programs/connection-utm",
  title:"",
  description: "",

  breadcrumbs: [
    {
      title: "Главная",
      href: "/",
    },
    {
      title: "Программы",
    },
    {
      title: "Настройка УТМ",
      href: "equipment/programs/connection-utm",
    },
  ],
  banerOne: {
  
    imageBg: "",
    imgChild: "url('../../../assets/pages/programs/Ver23.png')",
    title: "ПРОСТО И БЫСТРО ПОЛНЫЙ ПАКЕТ ПОДКЛЮЧЕНИЯ",
    description: "",
    titleLink:'ОФОРМИТЬ ПАКЕТ',
    link:'',
    linkDiscription:'',

    list:[
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Регистрация честный знак",
      },
      { 
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к ЭДО",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Подключение к ЕГАИС",
      },
      {
        checkImg:"url('../../../assets/icons/arrow-text-content.svg')",
        listTitle: "Настройка УТМ",
      },
    ], 
      
    prise:[
      { 
        title: "Стоимость : ",
        priseNew: "20000 ₽",
        priseOld: "22000 ₽",
        sale: "2000 ₽",
      },
    ],
  },
 
  baner: {
    image: "assets/services/Group 435.png",
    left: {
      title: "Купить программу 1С:Отель",
      description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
      image: "assets/services/Group 437.png",
      href: "",
    },
    right: {
      title: "Обучение программе 1С:Отель",
      description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
      image: "assets/services/Group 438.png",
      href: "",
    },
    
  },
  text1:{
    title: "Настройка УТМ",
    description:[
      {
        text:"Универсальный транспортный модуль ЕГАИС (УТМ). С момента введения системы ЕГАИС у всех продавцов алкогольной продукция появилась необходимость использования УТМ в повседневной работе. От корректно настроенного универсального транспортного модуля напрямую зависит правильность документа оборота алкогольной продукции. Любая ошибка может повлечь за собой не только расхождения на складе, но крупные штрафы при проверке регулирующих органов",
      }
    ]
  },
  serviseKassa:[

    {
      button:"Настройка УТМ",
      products:["connection-utm"]
    },
    {
      button:"Подключение к ЭДО ",
      products:["connection-edo"]
    },
    {
      button:"Подключение к ЕГАИС",
      products:["connection-egais"]
    },
    {
      button:"Регистрация - Честный знак ",
      products:["connection-chestyi-znak"]
    },
    
  ],
 
}, 
//Битрикс24 услуги
    {
      url:"services/integration/bitrix24",
      title:"Битрикс24 - Внедрение",
      description: "Проведем аудит и предложим правильное программное обеспечивание, как в базовой конфигурации, так и в индивидуальной. Внедрим и настроим программу на любом этапе, обучим персонал и закроем белые пятна в работе. Настроим сложные интеграции и создадим структуру по обмену данных. Разработаем новые печатные формы и отчеты. Синхронизируем кассы-онлайн, интернет эквайринг. Осуществляем базовую и расширенную техническую поддержку. Подробнее о программе 1С:Отель Предприятие 8",
      packages: this.packages.bitrix24,
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Услуги",
          href: "services/integration/bitrix24",
        },
        {
          title: "Битрикс24",
          href: "services/integration/bitrix24",
        },
      ],
      baner: {
        image: "assets/services/Group 435.png",
        left: {
          title: "Купить программу 1С:Отель",
          description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
          image: "assets/services/Group 437.png",
          href: "",
        },
        right: {
          title: "Обучение программе 1С:Отель",
          description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
          image: "assets/services/Group 438.png",
          href: "",
        },
        
      },
      text1:{
        title: "Внедрение популярной CRM Системы Битрикс 24 ",
        description:[
          {
            text:"Пакеты внедрения Битрикс24 — это оптимальные решения, неоднократно протестированные на практике, которые максимально учитывают задачи каждой конкретной компании.В кратчайший период от 5 до 15 рабочих дней вы получаете установленный и настроенный Битрикс24, полностью готовый к работе.Мы рады представить вашему вниманию абсолютно новые пакеты внедрения Битрикс24, которые включают в себя полный комплекс услуг от регистрации корпоративного портала, загрузки текущей базы клиентов из Excel, до настроек IP-телефонии, интеграции социальных сетей и вашего сайта.",
          }
        ]
      },
      text2:{
        description:[
          {
            text: "Для техподдержки программы 1С: Предприятие “Отель”, перейдите в раздел",
          },
          {
            text: "сопровождение",
            href: "",
            isRow: true,  
          }
        ]
      },
      text3:{
        description:[
          {
            image: "assets/services/Group 373.png",
            text: "Разработка и внедрение нетиповых решений, в разделе",
            bold: true,
          },
          {
            text: "программирование",
            href: "",
            isRow: true,  
            bold: true,
          }
        ]
      },
      text4:{
        title:"РЕКОМЕНДАЦИИ ПО НАСТРОЙКИ 1С:ОТЕЛЬ",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      text5:{
        title:"ПРАВИЛЬНОЕ ИСПОЛЬЗОВАНИЕ CHANNEL MANAGER",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      accordion:[
        { title: "Внедрение популярной CRM Системы Битрикс 24", description:"Автоматизация сложных бизнес процессов и протепирование всех этапов и возможных сценариев. Формирование воронок продаж, работа с базами данных. Внедрение возможно на любом этапе работ в кофмортной и знакомой среде для специалистов, проводим обучение", href:"/", expanded:true, image: "url('../../../assets/pages/services-integration-bitrix24/B1.png')"},
        { title: "Выгрузка сделок (бронирования) в CRM", description:"Создавая или редактируя бронирование в программе 1С: Отель, у специалистов есть возможность отправить данные сразу в Битрикс 24 и уже в CRM системе управлять сделкой по воронкам продаж ", expanded:false, image: "url('../../../assets/pages/services-integration-bitrix24/B2.png')"},
        { title: "Интеграция Битрикс 24 с программой 1С: Отель", description:"Для удобства работы отеда бронирования нами разработана интеграция не только по обмену данными между программой управления отелем и CRM, но и возможность управлять бронями", expanded:false, image: "url('../../../assets/pages/services-integration-bitrix24/B3.png')"},
        { title: "Модуль бронирования и Битрикс 24 ", description:"Используя модуль бронирования, как истоичник прямых продаж с сайта отеля, у специалистов появляется возможность автоматически видеть все брони прямо в CRM системе, что значительно упращает и автоматизирует работу", expanded:false, image: "url('../../../assets/pages/services-integration-bitrix24/B4.png')"}, 
      ],
      
    },

    {
      url:"services/integration/booking-module",
      title:"Модуль бронирования - Внедрение",
      description: "Проведем аудит и предложим правильное программное обеспечивание, как в базовой конфигурации, так и в индивидуальной. Внедрим и настроим программу на любом этапе, обучим персонал и закроем белые пятна в работе. Настроим сложные интеграции и создадим структуру по обмену данных. Разработаем новые печатные формы и отчеты. Синхронизируем кассы-онлайн, интернет эквайринг. Осуществляем базовую и расширенную техническую поддержку. Подробнее о программе 1С:Отель Предприятие 8",
      packages: this.packages.module,
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Услуги",
          href: "services/integration/booking-module",
        },
        {
          title: "Внедрение",
          href: "services/integration/booking-module",
        },
        {
          title: "Модуль бронирования",
          href: "services/integration/booking-module",
        },
      ],
      baner: {
        image: "assets/services/Group 435.png",
        left: {
          title: "Купить программу 1С:Отель",
          description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
          image: "assets/services/Group 437.png",
          href: "",
        },
        right: {
          title: "Обучение программе 1С:Отель",
          description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
          image: "assets/services/Group 438.png",
          href: "",
        },
        
      },
      text1:{
        title: "ВНЕДРЕНИЕ НА ОСНОВЕ БАЗОВОЙ КОНФИГУРАЦИИ 1С:ПРЕДПРИЯТИЕ “ОТЕЛЬ”",
        description:[
          {
            text:"В программе управления отелем, как и в сложном механизме, есть множество аспектов на которых строиться вся работа. Для простоты понимания, мы разделили этапы внедрения на блоки, от регистрации юридических данных компании, настройки тарифов, подключение терминалов, до сложных интеграций",
          }
        ]
      },
      text2:{
        description:[
          {
            text: "Для техподдержки программы 1С: Предприятие “Отель”, перейдите в раздел",
          },
          {
            text: "сопровождение",
            href: "",
            isRow: true,  
          }
        ]
      },
      text3:{
        description:[
          {
            image: "assets/services/Group 373.png",
            text: "Разработка и внедрение нетиповых решений, в разделе",
            bold: true,
          },
          {
            text: "программирование",
            href: "",
            isRow: true,  
            bold: true,
          }
        ]
      },
      text4:{
        title:"РЕКОМЕНДАЦИИ ПО НАСТРОЙКИ 1С:ОТЕЛЬ",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      text5:{
        title:"ПРАВИЛЬНОЕ ИСПОЛЬЗОВАНИЕ CHANNEL MANAGER",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
    },


    //devices/hotel
    {
      url:"devices/hotel",
      title:"Программа 1С:Отель",
      description: "Проведем аудит и предложим правильное программное обеспечивание, как в базовой конфигурации, так и в индивидуальной. Внедрим и настроим программу на любом этапе, обучим персонал и закроем белые пятна в работе. Настроим сложные интеграции и создадим структуру по обмену данных. Разработаем новые печатные формы и отчеты. Синхронизируем кассы-онлайн, интернет эквайринг. Осуществляем базовую и расширенную техническую поддержку. Подробнее о программе 1С:Отель Предприятие 8",
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Программы",
          href: "devices/hotel",
        },
        {
          title: "1С:Предприятие",
          href: "devices/hotel",
        },
        {
          title: "1С:Отель",
          href: "devices/hotel",
        }
      ],

      baner: {
        image: "assets/services/Group 435.png",
        left: {
          title: "Купить программу 1С:Отель",
          description: "Система управления Отелем. 1С:Предприятие 8, электронные поставки лицензии по официальным ценам",
          image: "assets/services/Group 437.png",
          href: "",
        },
        right: {
          title: "Обучение программе 1С:Отель",
          description: "Сертифицированный специалист определенного направления проведет онлайн обучение программы 1С:Отель",
          image: "assets/services/Group 438.png",
          href: "",
        },
        
      },
      text1:{
        title: "ВНЕДРЕНИЕ НА ОСНОВЕ БАЗОВОЙ КОНФИГУРАЦИИ 1С:ПРЕДПРИЯТИЕ “ОТЕЛЬ”",
        description:[
          {
            text:"В программе управления отелем, как и в сложном механизме, есть множество аспектов на которых строиться вся работа. Для простоты понимания, мы разделили этапы внедрения на блоки, от регистрации юридических данных компании, настройки тарифов, подключение терминалов, до сложных интеграций",
          }
        ]
      },
      text2:{
        title:"РЕКОМЕНДАЦИИ ПО НАСТРОЙКИ 1С:ОТЕЛЬ",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      text3:{
        title:"ПРАВИЛЬНОЕ ИСПОЛЬЗОВАНИЕ CHANNEL MANAGER",
        description:[
          {
            text: "Много лет мы помогаем нашим клиентам внедрить и настроить рабочий процессы по управлению отелем на базе программы 1С: Предприятие «Отель». На сегодняшний день, опираясь на наш опыт, мы выявили самые часто распространенные ошибки и пренебрежения при настройке, вот некоторые из них:",
          },
          {
            text: "Инструменты продаж (скидки, спецпредложения, карты гостя), Настройка тарифов (по дням недели, по сезонам), подробное описание и фото номеров (доп услуги), свойства номеров (повышение комфорта)",
          }
        ]
      },
      products:[
        "2900002083934",
        "2900002083958",
        "2900002084061"
      ]
    },
 
    {
      url:"devices/fiscal-storage",
      title:"Фискальные накопители",
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Оборудование", 
         
        },
        {
          title: "Фискальный накопитель",
          href: "/",
        }
      ],
     
     
    },
//Модуль бронирования продкуты
    {
      url:"developments/booking-module",
      title:"Модуль бронирования - Разработки",
      description: "Каждый день, тысячи людей ищут отели по всему миру, это стало очень удобно благодаря множествам программ и сервисам по бронированию номеров. Мы объединили все сложные процессы в единый модуль бронирования, присоединяйтесь и вы!",
      packages: this.packages.module, 
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Разработки",
        },
        {
          title: "Модуль бронирования",
          href: "developments/booking-module",
        }
      ],

      baner: {
        image: "assets/services/Banner-module.png",
        left: {
          title: "Интеграция с 1С: Отель и Битрикс 24",
          description: "Обмен данными между модулем бронирования с программой 1С:Отель и CRM Битрикс24, открывает новые возможности",
          image: "assets/services/Group 437.png",
          href: "",
        },
        right: {
          title: "Интеграция с channel manager",
          description: "Привлекайте больше гостей используя самые популярные каналы продаж, управление подключением автоматически",
          image: "assets/services/Group 438.png",
          href: "",
        },
        
      },
      text1:{
        title: "Решение которое действительно работает",
        subtitle: "Широкие возможности модуля бронирования позволяют привлекать больше гостей",
        description:[
          {
            text:"Наша собственная разработка, модуль онлайн-бронирования от компании «TripAdvance», представляет собой сложную, интегрированную систему, объединяющую передовые алгоритмы и инструменты для гостиничного бизнеса. Мы с гордостью совершенствуем наш продукт, регулярно расширяя его функционал и добавляя новые маркетинговые возможности, чтобы сделать процесс бронирования максимально удобным и эффективным для наших клиентов.",
          },

         /* {
            html:"<p>Модуль позволяет легко и быстро найти и забронировать свободный номер, как на сайте отеля, так и на любом из \
            <a class='link' href='/'>менеджеров каналов</a> (channel manager), например: Островок, Броневик, 101 Hotel и другими\
            </p>"
          },
          
          {
            image: "assets/services/Group 373.png",
            html:"<p><a class='link' href='/'>Интеграция с сервисом «Яндекс путешествие»</a> Модуль позволяет легко и быстро найти и забронировать свободный номер, как на сайте отеля, так и на любом из \
             (channel manager), например: Островок, Броневик, 101 Hotel и другими\
            </p>"
          },  */
        ]
      },
      text2:{
        title:"КАНАЛЫ ПРОДАЖ",
        description:[
          {
            text: "Более 100 +  популярных  каналов продаж для вашего объекта, таких как: Booking, Expedia, Airbnb, Ostrovok, Броневик, 101 Hotels",
          }
        ]
      },
      text3:{
        title:"ИНТЕГРАЦИИ",
        description:[
          {
            text: "Двухсторонняя интеграция модуля с любым сайтом, API, программами: 1C: Отель, Битрикс 24",
          }
        ]
      },
      text4:{
        title:"ФУНКЦИОНАЛ",
        description:[
          {
            text: "Динамические цены и остатки, акции, скидки, заселение в кредит, отзывы, бронирование через Instagram и другое",
          }
        ]
      },
      accordion:[
        { title: "Без комиссии и ограничений. Модуль бронирования", description:"Прямое бронирование на вашем сайте в удобном и понятном визуальном исполнении для поиска свободных номеров. Модуль отвечает всем современным требованиям для комфортной работы отедела бронирования", href:"/", expanded:true, image: "url('../../../assets/pages/booking-module/M1.png')"},
        { title: "Менеджеры каналов для увелечения продаж", description:"Модуль позволяет автоматически синхронизировать свободные номера, актуальные цены, тарифы в самые популярные сервисы бронирования. Моментальная выгрузка броней для специалистов по бронированию и оповещений для клиентов", expanded:false, image: "url('../../../assets/pages/booking-module/M2.png')"},
        { title: "Направляйте клиента - инструменты мотивации", description:"Для мотивации гостей в прямой выдачи свободных номеров на сайте. Нами реализованны, как базовые элементы: скидки, лидер продаж, популярность, но так-же: занят-освободится, возможность переселения гостей, повышение комфорта номера, календарь зеленых цен и другие мотиваторы", expanded:false, image: "url('../../../assets/pages/booking-module/M3.png')"},
        { title: "Интеграции - готовые решения для модуля бронирования", description:"Модуль позволяет полностью управлять бронированием и обмениваться всей технической информацией с программой 1С:Отель. Через панель управления - возможна настройка для выгрузки броней в Битрикс 24, подключить интернет эквайринг и другое", expanded:false, image: "url('../../../assets/pages/booking-module/M4.png')"}, 
      ],
      accordion2:[
        { title: "Без комиссии и ограничений. Модуль бронирования", description:"Прямое бронирование на вашем сайте в удобном и понятном визуальном исполнении для поиска свободных номеров. Модуль отвечает всем современным требованиям для комфортной работы отедела бронирования", href:"/", expanded:true, image: "url('../../../assets/pages/booking-module/M1.png')"},
        { title: "Менеджеры каналов для увелечения продаж", description:"Модуль позволяет автоматически синхронизировать свободные номера, актуальные цены, тарифы в самые популярные сервисы бронирования. Моментальная выгрузка броней для специалистов по бронированию и оповещений для клиентов", expanded:false, image: "url('../../../assets/pages/booking-module/M2.png')"},
        { title: "Направляйте клиента - инструменты мотивации", description:"Для мотивации гостей в прямой выдачи свободных номеров на сайте. Нами реализованны, как базовые элементы: скидки, лидер продаж, популярность, но так-же: занят-освободится, возможность переселения гостей, повышение комфорта номера, календарь зеленых цен и другие мотиваторы", expanded:false, image: "url('../../../assets/pages/booking-module/M3.png')"},
        { title: "Интеграции - готовые решения для модуля бронирования", description:"Модуль позволяет полностью управлять бронированием и обмениваться всей технической информацией с программой 1С:Отель. Через панель управления - возможна настройка для выгрузки броней в Битрикс 24, подключить интернет эквайринг и другое", expanded:false, image: "url('../../../assets/pages/booking-module/M4.png')"}, 
      ],
    },
    //программирование
    //----------------лицензии---------
    {
      url:"services/programming/licenzii",
      title:"Лицензии",
      breadcrumbs: [
        {
          title: "Главная",
          href: "/",
        },
        {
          title: "Услуги", 
         
        },
        {
          title: "Внедрение 1С Отель",
          href: "/",
        }
      ],
      accordion:[
        { title: "Современный подход в управление отелем", description:"Современный подход в управление отелем Программа 1С: Отель на сегодняшний день является одной из самых популярных PMS систем для управления отелем в России. Структура программы постоянно дописывается с помощью расширений и интеграций", href:"/", expanded:true, image: "url('../../../assets/pages/11.png')"},
        { title: "Кол-во рабочих мест (Лицензии)", description:"При приобретении “основной поставки 1С” - вы получаете лицензию для одного рабочего места, дополнительные лицензии для увеличения рабочих мест для ваших сотрудников приобретаются отдельно ", expanded:false, image: "url('../../../assets/pages/2.png')"},
        { title: "Подбор и настройка оборудования", description:"Проектируем решения любого масштаба и предложим оборудование под индивидуальные задачи. Настроим работу по сети и интеграции. Зарегистрируем в налоговой службе, подключим к необходимым сервисам", expanded:false, image: "url('../../../assets/pages/3.png')"},
        { title: "Полный цикл сопровождения и тех поддрежки", description:"Находим и устраняем многие проблемы еще до их появления. Понимаем наших клиентов с полуслова, оперативно решаем проблемы. Ваш проект получает лучшее из возможных технических решений учитывая все тонкости в работе", expanded:false, image: "url('../../../assets/pages/4.png')"}, 
      ],
      text1:{
        title: "Внедрение программы 1С:Отель ",
        description:[
          {
            text:"Проведем аудит и предложим правильное программное обеспечивание, как в базовой конфигурации, так и в индивидуальной. Внедрим и настроим программу на любом этапе, обучим персонал. Настроим сложные интеграции и создадим структуру по обмену данных. Разработаем новые печатные формы и отчеты. Синхронизируем кассы-онлайн, интернет эквайринг. Осуществляем базовую и расширенную техническую поддержку ",
          }
        ]
      },
      packages: this.packages.hotel,
     
    },
  ]

  public currentMenu:any = undefined;

  public GetDevice(id:any){
    for(var i = 0; i < this.products.length; i++){
      if(this.products[i].id == id)
        return this.products[i];
    }
    return undefined;
  }
  public GetDeviceKit(id:any){
    var kits = [];

    for(var i = 0; i < this.products.length; i++){
      if(this.products[i].id == id){
        if(this.products[i].kit == undefined)
          break;

        for(var j = 0; j < this.products[i].kit.length; j++){
          kits.push(this.GetDevice(this.products[i].kit[j]));
        }
      }  
    }
    return kits;
  }
  public GetCurrentPage(){
    var page:any = undefined;
    var url = this.router.url.substring(1);
    var index = url.indexOf("?");

    if(index != -1){
      url = url.substring(0, index);
    }

    for(var i = 0; i < this.pages.length; i++){
      if(this.pages[i].url == url){
        page = this.pages[i];
        break;
      }
    }
    return page;
  }
  public GetProducts(ids:any){
    var out = [];
    for(var i = 0; i < this.products.length; i++){
      for(var j = 0; j < ids.length; j++){
        if(this.products[i].id == ids[j]){
          out.push(this.products[i]);
          break;
        }
      }
    }
    return out;
  }
  public IsPlatformBrowser(){
    if (isPlatformBrowser(this.platformId)) {
      return true;
    }
    return false;
  }


  public setMenuActive(item:any){
    this.currentMenu = item;
  }

  public getMenuParent(item:any){
    for(var n1 = 0; n1 < this.menu.length; n1++){
      if(this.menu[n1] == item)
        return undefined;

      const items2 = this.menu[n1].items;

      for(var n2 = 0; n2 < items2.length; n2++){
        if(items2[n2] == item)
          return this.menu[n1];

        const items3 = items2[n2].items;
        for(var n3 = 0; n3 < items3.length; n3++){
          if(items3[n3] == item)
            return items2[n2];
        }
      }
    }
    return undefined;
  }
}

