import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent {
  @Input() name: string = "name";
  @Input() code: string = "00000000001";
  @Input() price: number = 10000;
  @Input() src: string = "assets/products/hotel.png";
  @Input() item:any = {}; 
}
