import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VisibilityService {
  private hideElementSource = new Subject<void>();
  public hideElement$ = this.hideElementSource.asObservable();

  triggerHide() {
    this.hideElementSource.next(); // Оповещаем подписчиков о необходимости скрыть элемент
  }
}