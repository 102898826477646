import { Component } from '@angular/core';

@Component({
  selector: 'app-paralel-scroll',
  templateUrl: './paralel-scroll.component.html',
  styleUrls: ['./paralel-scroll.component.scss']
})
export class ParalelScrollComponent {

}
