import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-equipment-connection-ofd',
  templateUrl: './equipment-connection-ofd.component.html', 
  styleUrls: ['./equipment-connection-ofd.component.scss']
})
export class EquipmentConnectionOfdComponent {
  currentPacket: any = []; 
  activePacket:any = 0;
  page:any = {};

  constructor(public site:SiteService, private route: ActivatedRoute){

  }

  ngOnInit(): void {   
    this.currentPacket = this.site.packages.training;
    this.page = this.site.GetCurrentPage();
  }

  ngAfterContentInit(){
  }

  isOpen = false;

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
