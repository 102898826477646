import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-services-programming-licenzii',
  templateUrl: './services-programming-licenzii.component.html',
  styleUrls: ['./services-programming-licenzii.component.scss']
})
export class ServicesProgrammingLicenziiComponent {
  page:any = {};

  constructor(public site:SiteService){
    this.page = this.site.GetCurrentPage();
    console.log(this.page);
  }
}
