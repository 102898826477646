import { Component, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-button-slider-wihte',
  templateUrl: './button-slider-wihte.component.html',
  styleUrls: ['./button-slider-wihte.component.scss']
})
export class ButtonSliderWihteComponent {
  
  @Input() small:any = false;

  @Output('onClick') event = new EventEmitter<any>();

  productNext(){
    this.event.emit(1);
  } 

  productPrev(){
    this.event.emit(-1);
  }
}
