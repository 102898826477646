import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-teh-stack',
  templateUrl: './teh-stack.component.html',
  styleUrls: ['./teh-stack.component.scss']
})
export class TehStackComponent {
  page:any = {};

  constructor(public site:SiteService, private route: ActivatedRoute){

  }


  ngOnInit(): void {   
    this.page = this.site.GetCurrentPage();
  }

  ngAfterContentInit(){
  }
}
