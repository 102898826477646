import { Component } from '@angular/core';

@Component({
  selector: 'app-services-programming-reports',
  templateUrl: './services-programming-reports.component.html',
  styleUrls: ['./services-programming-reports.component.scss']
})
export class ServicesProgrammingReportsComponent {

}
