<div class="wrapper">
    <div class="header">
        <div class="title">
            <h1 class="title__text">Новости</h1>
            <p class="title__button link">подробнее</p>
        </div>
        <!--

        
        <div class="buttons">
            <p class="button-left pointer" (click)="this.next()"><</p>
            <p class="button-right pointer" (click)="this.prev()">></p>
        </div>
        -->
    </div>

    <div class="line"></div>

    <div class="news">
        <div class="new">
            <div class="new-image">
                <img src="assets/news/news1.jpg"/>
            </div>
            <h4 class="new-title">Цикл курсов «1С:Отель с Нуля» для обучения сотрудников</h4>
            <p class="new-description">Мы предлагаем обучение 1C:Отель с нуля. Наши опытные специалисты научат вас работать с программой, а также помогут настроить ее под ваши нужды. Мы предоставляем полный курс обучения, который включает в себя основы работы с программой, управление клиентами, бронирование номеров и многое другое. </p>
            <div class="new-block__button">
                <p class="new-block__button-text" >Подробнее</p>
                <app-icon class="new-block__button-arrow" name="arrow-right" color="red" size="6px"></app-icon>
            </div>
        </div>

        <div class="new">
            <div class="new-image">
                <img src="assets/news/news2.jpg"/>
            </div>
            <h4 class="new-title">Цикл курсов «1С:Отель с Нуля» для обучения сотрудников</h4>
            <p class="new-description">Мы предлагаем обучение 1C:Отель с нуля. Наши опытные специалисты научат вас работать с программой, а также помогут настроить ее под ваши нужды. Мы предоставляем полный курс обучения, который включает в себя основы работы с программой, управление клиентами, бронирование номеров и многое другое. </p>
            <div class="new-block__button">
                <p class="new-block__button-text">Подробнее</p>
                <app-icon class="new-block__button-arrow" name="arrow-right" color="red" size="6px"></app-icon>
            </div>
        </div>

        <div class="new">
            <div class="new-image">
                <img src="assets/news/news3.jpg"/>
            </div>
            <h4 class="new-title">Цикл курсов «1С:Отель с Нуля» для обучения сотрудников</h4>
            <p class="new-description">Мы предлагаем обучение 1C:Отель с нуля. Наши опытные специалисты научат вас работать с программой, а также помогут настроить ее под ваши нужды. Мы предоставляем полный курс обучения, который включает в себя основы работы с программой, управление клиентами, бронирование номеров и многое другое. </p>
            <div class="new-block__button">
                <p class="new-block__button-text" >Подробнее</p>
                <app-icon class="new-block__button-arrow" name="arrow-right" color="red" size="6px"></app-icon>
            </div>
        </div>

        <div class="new">
            <div class="new-image">
                <img src="assets/news/news4.jpg"/>
            </div>
            <h4 class="new-title">Цикл курсов «1С:Отель с Нуля» для обучения сотрудников</h4>
            <p class="new-description">Мы предлагаем обучение 1C:Отель с нуля. Наши опытные специалисты научат вас работать с программой, а также помогут настроить ее под ваши нужды. Мы предоставляем полный курс обучения, который включает в себя основы работы с программой, управление клиентами, бронирование номеров и многое другое. </p>
            <div class="new-block__button">
                <p class="new-block__button-text" >Подробнее</p>
                <app-icon class="new-block__button-arrow" name="arrow-right" color="red" size="6px"></app-icon>
            </div>
        </div>
    </div>

</div>
