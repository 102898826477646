<div class="packet">
    <div class="packet-top">
        <h1>{{this.name}}</h1>
        <h1>{{this.type}}</h1>
        <p class="packet-description">Типовое решение с базовыми настройками и конфигурациями</p> 
        
        <div class="packet-blocks">

            <ng-content></ng-content>
            <!--


            
            <div class="packet-block pointer" *ngFor="let item of this.blocks">
                <div class="packet-block__active"></div>
                <p class="packet-block__text">{{item.name}}</p>
                <p class="points"></p>
                <app-arrow  [setOpen]="false"></app-arrow>
            </div>
            -->

            <!--
            <div class="packet-block pointer">
                <div class="packet-block__active"></div>
                <p class="packet-block__text">Базовый блок</p>
                <p class="points"></p>
                <app-arrow  [setOpen]="false"></app-arrow>
            </div>
            <div class="packet-block  pointer">
                <div class="packet-block__active"></div>
                <p class="packet-block__text ">Административный блок</p>
                <p class="points"></p>
                <app-arrow  [setOpen]="false"></app-arrow>
            </div>
            <div class="packet-block pointer">
                <div class="packet-block__active"></div>
                <p class="packet-block__text">Технический блок</p>
                <p class="points"></p>
                <app-arrow  [setOpen]="false"></app-arrow>
            </div>
            <div class="packet-block pointer">
                <div class="packet-block__active"></div>
                <p class="packet-block__text">Рабочий блок</p>
                <p class="points"></p>
                <app-arrow  [setOpen]="false"></app-arrow>
            </div>
            <div class="packet-block pointer">
                <div class="packet-block__noactive"></div>
                <p class="packet-block__text">Интеграции</p>
                <p class="points"></p>
                <app-arrow  [setOpen]="false"></app-arrow>
            </div>
            <div class="packet-block pointer">
                <div class="packet-block__noactive"></div>
                <p class="packet-block__text">Инфо блок</p>
                <p class="points"></p>
                <app-arrow  [setOpen]="false"></app-arrow>
            </div>
            <div class="packet-block pointer">
                <div class="packet-block__noactive"></div>
                <p class="packet-block__text">Модуль бронирования</p>
                <p class="points"></p>
                <app-arrow  [setOpen]="false"></app-arrow>
            </div>
            -->
           
        </div>
    </div>
    
    <div class="packet-footer">
        <h3>от 9 000₽</h3>
        <div class="packet__button pointer">
            <p>Подробнее</p>
        </div>
    </div>

</div>