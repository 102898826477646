import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-decision',
  templateUrl: './product-decision.component.html',
  styleUrls: ['./product-decision.component.scss']
})

export class ProductDecisionComponent {

  @Input() title:any = "";
  @Input() description:any = "";
  @Input() text:any = "";
  @Input() image:any = "";
  @Input() type:any = "Программы";
  @Input() href:any = "";
  
}
