

<div style="width: 100%; margin-top: 40px;" >

   <div style="width: 90%; margin: auto; margin-bottom: 100px;">
      <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
   </div> 
  
  
   


   <div style="margin-top: 100px;">
      <app-footer></app-footer> 
    </div> 
</div>




