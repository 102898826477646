import { Component } from '@angular/core';

@Component({
  selector: 'app-services-grm',
  templateUrl: './services-grm.component.html',
  styleUrls: ['./services-grm.component.scss']
})
export class ServicesGrmComponent {

}
