import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToggleService {

  private _backgroundChangedSubject = new Subject<void>();
  public backgroundChanged = this._backgroundChangedSubject.asObservable();


  constructor() { }

  changeBackground() {
    this._backgroundChangedSubject.next(); // Отправляем сигнал о необходимости сменить фон
  }

 
}