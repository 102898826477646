import { Component, Output, EventEmitter, Input, Renderer2, ViewChild, ElementRef, OnInit   } from '@angular/core';
import { ToggleService } from '../../servises/toggle.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VisibilityService } from '../../servises/Visibility.service'; // Импортируем созданный ранее сервис

@Component({
  selector: 'app-modal-forms-upper',
  templateUrl: './modal-forms-upper.component.html',
  styleUrls: ['./modal-forms-upper.component.scss'] 
})
export class ModalFormsUpperComponent  implements OnInit{
  @Output() removeUpper = new EventEmitter<void>();

  onClick() {
    this.removeUpper.emit();
  }

  @Input() item:any = {};
  @Input() item2:any = {};
  @Input() item3:any = {};
  @Input() item4:any = {};
  @Input() item5:any = {}; 

  public currentPage:any = undefined;
  public currentPageTwo:any = undefined;
  public currentPageTree:any = undefined;
  public currentPageFour:any = undefined;
  public currentPageFive:any = undefined; 

  @Input() inputTitle:any =[] 
  @Input() inputTitleTwo:any =[] 
  @Input() inputTitleTree:any =[] 
  @Input() inputTitleFour:any =[]
  @Input() inputTitleFive:any =[]

  @ViewChild("refItem1", {static:false}) refItem1?:ElementRef;
  @ViewChild("refItem2", {static:false}) refItem2?:ElementRef;
  @ViewChild("refItem3", {static:false}) refItem3?:ElementRef;

 // Свойство должно быть доступно и инициализировано


  phone: string | undefined;
   
  constructor(public renderer:Renderer2, private toggleService: ToggleService, private visibilityService: VisibilityService){
    this.phone = ''; // Или другое начальное значение
  }

  ngOnInit(): void{
    this.renderer.listen('window', 'click', (e: Event) => {
      if(!this.refItem1?.nativeElement.contains(e.target) &&
      !this.refItem2?.nativeElement.contains(e.target) &&
      !this.refItem3?.nativeElement.contains(e.target)
    ){
      this.item.top=false;
      this.item2.topTwo=false;
      this.item3.topTree=false;
    }

    });
    this.phoneForm = new FormGroup({
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$')
      ])
    });
  }
  onClickButton() {
    this.toggleService.changeBackground(); // Вызываем метод сервиса для изменения фона
  }
   
  onChange(item:any){
  
    this.item2.topTwo=false;
    this.item3.topTree=false;

    item.top = !item.top;

   
  }
  onChangeTwo(item2:any){
    this.item.top=false;
    this.item3.topTree=false;
    item2.topTwo = !item2.topTwo;
  }
  onChangeTree(item3:any){
    this.item2.topTwo=false;
    this.item.top=false;

    item3.topTree = !item3.topTree;

  }
  onChangeFour(item4:any){
    if(item4 == this.currentPageFour){
      return;
    }
    item4.topFour = !item4.topFour;
    this.currentPageFour = item4;

    if(item4.topFour){
      for(let i of this.inputTitleFour){
        if(i != item4)
          i.topFour = false;
      }
   
    }
  }
 
  phoneForm!: FormGroup;

 onKeyUp(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    let value = target.value.replace(/[^0-9]/g, ''); // Убираем все символы кроме цифр

    if (value.length === 10 || value.length === 11) {
      value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
    } else if (value.length >= 12) {
      value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }

    target.value = value;
  }

  



  onClick1() {
    this.visibilityService.triggerHide(); // Вызываем метод сервиса для отправки сигнала
  }

  
}
