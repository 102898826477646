import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-discriptions',
  templateUrl: './discriptions.component.html',
  styleUrls: ['./discriptions.component.scss']
})
export class DiscriptionsComponent {
  @Input() public title:any = "awdawd";

  @Input() public subtitle:any = "awdawd";
  @Input() public link:any = "awdawd";
 
}
