<div style="width: 100%; margin-top: 40px;" >

    <div style="width: 90%; margin: auto; margin-bottom: 100px;">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
    <div style="width: 90%; margin: auto;">
      <app-element-product-text
       [item]="this.page.text1"> 
      </app-element-product-text>
   </div>
   <div style="width: 90%;margin: auto; margin-top: 100px;">
      <app-discription-row
        [title2]="'Полный адрес'"
        [subtitle]="'353435 Краснодарский край г.о. город-курорт Анапа г. Анапа х. Усатова Балка ул. Красная д. 1/9 кв. 3'"
        [subtitle2]="'Пн-Пт с 9:00 - 18:00'"
        [subtitle3]="'Сб - Вс выходные дни'"
        [title3]="'Единый бесплатный телефон'"
        [subtitle4]="'8 (861) 213-23-13'"
        [title4]="'По вопросам сотрудничества'"
        [subtitle5]="'8 (993) 631-64-93'"
        [title5]="'По вопросам трудоустройства'"
        [subtitle6]="'8 (996) 405-07-36'"
        [title6]="'Официальная почта'"
        [subtitle7]="'acc@tripadvance.ru'"
        [title7]="'Почта'"
        [subtitle8]="'product@tripadvance.ru'"
        [title8]="'Почта'"
        [subtitle9]="'support@tripadvance.ru'"
      >
      </app-discription-row> 
   </div>
   <div style="width: 90%; margin:100px auto;">
      <app-element-card-marketing  [item]="this.page.marketingCards"></app-element-card-marketing>
   </div>
    
  <!--  <div style="margin-top: 150px;">
       <app-gallery></app-gallery>
    </div>-->
  
    <div style="margin-top: 100px;">
       <app-footer></app-footer> 
     </div> 
 </div>
    
