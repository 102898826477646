<div class="wrapper">
    <div class="title">
        <h1 class="title__text">ПРОГРАММНЫЕ ПРОДУКТЫ</h1>
        <a href="services/programming/licenzii"><app-red-arrow></app-red-arrow></a>
    </div>

    <div class="line"></div>

    <div class="products">
        <app-product 
            [name]="'1С:Предприятие 8. Отель для 5 пользователей. Электронная поставка'"
            [code]="'2900002083958'"
            [price]="130000"
            [src]="'assets/products/hotel.png'">
        </app-product>

       
        <app-product 
            [name]="'1С:Предприятие 8. Отель Доп. лицензия на 5 р.м. Электронная поставка'"
            [code]="'2900002084054'"
            [price]="117000"
            [src]="'assets/products/hotel.png'">
        </app-product>
       
        <app-product 
            [name]="'1С:Предприятие 8. Отель Доп. лицензия на 10 р.м. Электронная поставка'"
            [code]="'2900002084061'"
            [price]="214500"
            [src]="'assets/products/hotel.png'">
        </app-product>
    </div>
</div>

<div class="wrapper">
    <div class="title">
        <h1 class="title__text">ОБОРУДОВАНИЕ</h1>
        <a href="devices/cash-registers"><app-red-arrow></app-red-arrow></a>
    </div>

    <div class="line"></div>

    <div class="products">
        <app-product 
            [name]="'Онлайн-Касса Атол 25 Ф'"
            [code]="'cash-registers-25F'"
            [price]="34500"
            [src]="'assets/products/Атол-25Ф.jpg'">
        </app-product>
        
        <app-product 
            [name]="'Онлайн-Касса Атол 22 Ф'"
            [code]="'cash-registers-22F'"
            [price]="47600"
            [src]="'assets/products/Атол-22Ф.png'">
        </app-product>
     
        <app-product 
            [name]="'Онлайн-Касса Атол 30 Ф'"
            [code]="'cash-registers-30F'"
            [price]="38300"
            [src]="'assets/products/Atol-30F.jpg'"
            >
            
        </app-product>
    </div>
</div>
