import { Component } from '@angular/core';
import { SiteService } from '../site.service';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent {
  constructor(public site:SiteService){}
}
