import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-training-one-c-hotel',
  templateUrl: './training-one-c-hotel.component.html',
  styleUrls: ['./training-one-c-hotel.component.scss']
})
export class TrainingOneCHotelComponent {
 
  currentPacket: any = [];
  activePacket:any = 0;
  page:any = {};

  constructor(public site:SiteService, private route: ActivatedRoute){

  }

  ngOnInit(): void {   
    this.currentPacket = this.site.packages.training;
    this.page = this.site.GetCurrentPage();
  }

  ngAfterContentInit(){
  }
}
