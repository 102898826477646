<div style="width: 100%; margin-top: 40px;" >

    <div style="width: 90%; margin: auto; margin-bottom: 100px;">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
    <div style="width: 90%; margin: auto;">
      <app-element-product-text
       [item]="this.page.text1"> 
      </app-element-product-text>
   </div>
 
   <div class="margins-section-tag">
    <app-element-tags [item]='[
         { text:"Модуль бронирования + Битрикс24"},
         { text:"Обучение Битрикс24"},
         { text:"1С:Отель + Битрикс24"},
         { text:"Лицензии Битрикс24"},
         { text:"SIP телефония для Битрикс24"},
         { text:"Сопровождение Битрикс24"},
         { text:"Дополнительные воронки продаж Битрикс24"},
       ]'>
       </app-element-tags>
   </div>
 
    <div class="margins-section"> 
       <app-accordion-all-pages ngSkipHydration *ngIf="this.page.accordion!=undefined"
          [accordions]="this.page.accordion">
       </app-accordion-all-pages>
    </div>
    <div>
       <app-element-packages [item]="this.page.packages">
       </app-element-packages>
     </div>
     
    
  <!--  <div style="margin-top: 150px;">
       <app-gallery></app-gallery>
    </div>-->
 
    <div class="margins-section-footer">
       <app-footer></app-footer> 
     </div> 
 </div>
    
  


