<div class="line desktop wrapper_desktop">

</div>

<nav class="desktop wrapper_desktop" > 
                           <!--список меню--> 

    <li class="wrapper__list-top-menu">
        <!--меню главная------------------------------------------------------->
        <ul class="topmenu ">
            <li> 
               <div (click)="this.setMenuActive($event)" class="menu_button menu_adaptive__service">     
                  <a href="#" rel="nofollow" class="menu_button__text">Главная</a>
               </div>
            </li>
       
         </ul>
       <!--меню услуги------------------------------------------------------->
       <ul class="topmenu " id="wrapp-uslugi">
          <li> 
             <div (click)="this.setMenuActive($event)" class="menu_button menu_adaptive__service">     
                <a  rel="nofollow" class="menu_button__text">Услуги</a>
                <img src="../../assets/header/right-arrow.svg" class="menu_button__arrow"/>
                <!--<app-arrow class="menu_button__arrow desktop" [setOpen]="false"></app-arrow>-->
             </div>
          </li>
     
       </ul>

       <!--меню оборудование------------------------------------------------------->
       <ul class="topmenu " id="wrapp-equipment">
          <li> 

            <div (click)="this.setMenuActive($event)" class="menu_button menu_adaptive__service">     
               <a  rel="nofollow" class="menu_button__text">Оборудование</a>
               <img src="../../assets/header/right-arrow.svg" class="menu_button__arrow"/>
            </div>

          </li>
       </ul>

        <!--меню программы------------------------------------------------------->
       <ul class="topmenu " id="wrapp-programming">
          <li> 

            <div (click)="this.setMenuActive($event)" class="menu_button menu_adaptive__service">     
              <a  rel="nofollow" class="menu_button__text">Программы</a>
              <img src="../../assets/header/right-arrow.svg" class="menu_button__arrow"/>
            </div>

          </li>
       </ul>

       <!--меню продукты------------------------------------------------------->
       <ul class="topmenu" id="wrapp-product">
          <li> 

            <div (click)="this.setMenuActive($event)" class="menu_button menu_adaptive__service">     
              <a  rel="nofollow" class="menu_button__text">Продукты</a>
              <img src="../../assets/header/right-arrow.svg" class="menu_button__arrow"/>
            </div>

          </li>
       </ul>

        <!--меню интеграции------------------------------------------------------->
        <ul class="topmenu" id="wrapp-integration">
            <li> 
  
              <div (click)="this.setMenuActive($event)" class="menu_button menu_adaptive__service">     
                <a  rel="nofollow" class="menu_button__text">Интеграции</a>
                <img src="../../assets/header/right-arrow.svg" class="menu_button__arrow"/>
              </div>
  
            </li>
         </ul>
  
          <!--меню о компании------------------------------------------------------->
        <ul class="topmenu" id="wrapp-company">
            <li> 
  
              <div (click)="this.setMenuActive($event)" class="menu_button menu_adaptive__service">     
                <a  rel="nofollow" class="menu_button__text">О компании</a>
                <img src="../../assets/header/right-arrow.svg" class="menu_button__arrow"/>
              </div>
  
            </li>
         </ul>

           <!--меню контакты------------------------------------------------------->
        <ul class="topmenu" id="wrapp-contact">
            <li> 
  
              <div (click)="this.setMenuActive($event)" class="menu_button menu_adaptive__service">     
                <a href="contacts" rel="nofollow" class="menu_button__text">Контакты</a>
              </div>
  
            </li>
         </ul>
        


         <ul class="sabmenu-uslugi">
            <li>
               <ul class="menu-columns"> <!-- *ngIf="item.items.length > 0" [class.submenu]="item.items.length > 0"-->
                    <div class="left-column">
                        <div class="left-content" >
                           <img  class="left-content__icon" src=".././../assets/header/B11.svg"/>
                           <h3 class="left-content__title">УПРАВЛЕНИЕ ОТЕЛЕМ ПО НОВОМУ</h3>
                           <p class="left-content__discription">Современные решения для комфортной работы в программе 1С:Отель</p>
                           <a class="arrow">&rarr;</a>
                        </div>
                    </div>
                    <div class="center-columns">
                      <ul *ngFor="let item of this.site.menu" class="center-columns__menu">
                       <li *ngFor="let item2 of item.items" class="center-column">
                          <a class="list-title"  (click)="this.navigateTo(item2.href)" rel="nofollow" [attr.href]="item2.href" >{{item2.title}}<span class="fa fa-angle-down"></span></a>
                          <ul *ngIf="item2.items.length > 0" class="submenu">
                             <li *ngFor="let item3 of item2.items" style="display: flex;">
                               <img class="list-icon" src="../../assets/header/right-arrow.svg"/> 
                               <a class="list-link" (click)="this.navigateTo(item3.href)" rel="nofollow"  [attr.href]="item3.href"  [attr.href]="item3.href" [class.noactive]="item3.href=='/'">{{item3.title}}</a>
                             </li> 
                          </ul>
                       </li>
                     </ul>
                    </div>
                    <div class="riht-column">
                       <div style="position: absolute; left: 1vw; top:1vw;">
                       <img src="../../assets/header/B1.jpg" class="riht-column__img"/>
                       <p class="left-content__discription-two">Мы открыты для новых партнеров и интересных совместных решений</p>
                       <a class="arrow">&rarr;</a>
                       </div>
                    </div>
               </ul>
     
            </li>
         </ul>

         <ul class="sabmenu-equipment">
             <li>
                <ul class="menu-columns"> <!-- *ngIf="item.items.length > 0" [class.submenu]="item.items.length > 0"-->
                   <div class="left-column">
                      <div class="left-content" >
                         <img  class="left-content__icon" src="../../assets/header/equipment/oborudovanie v1.svg"/>
                         <h3 class="left-content__title">ПОЛНОСТЬЮ ПОДГОТОВИМ К РАБОТЕ</h3>
                         <p class="left-content__discription">Подберем и настроим оборудование любой сложности</p>
                         <a class="arrow">&rarr;</a>
                      </div>
                   </div>
                   <div class="center-columns1">
                     <div class="separator">
                        
                     </div> 
                      <ul class="center-columns__menu">
                          <div *ngFor="let item of this.site.menu" class="wrapp-columns">
                            <li *ngFor="let item2 of item.items2" class="center-column">
                               <a class="list-title"  (click)="this.navigateTo(item2.href)" rel="nofollow" [attr.href]="item2.href" >{{item2.title}}<span class="fa fa-angle-down"></span></a>
                               <ul *ngIf="item2.items2.length > 0" class="submenu">
                                  <li *ngFor="let item3 of item2.items2" style="display: flex;">
                                     <img class="list-icon" src="../../assets/header/right-arrow.svg"/> 
                                     <a class="list-link" (click)="this.navigateTo(item3.href)" rel="nofollow"  [attr.href]="item3.href" [class.noactive]="item3.href=='/'">{{item3.title}}</a>
                                  </li>
                               </ul>
                            </li>
                          </div>  
                              
                          <div *ngFor="let item of this.site.menu" class="wrapp-columns1">
                         
                           <li *ngFor="let item2 of item.items3" class="center-column">
                              <div class="center-column__menu-title">
                                 <img class="center-column__icon-title" [attr.src]="this.item2.image"/>
                                 <a class="list-title"  (click)="this.navigateTo(item2.href)" rel="nofollow" [attr.href]="item2.href" [class.noactive]="item2.href=='/'">{{item2.title}}<span class="fa fa-angle-down"></span></a>
                              </div>
                              <ul *ngIf="item2.items3.length > 0" class="submenu">
                                 <li *ngFor="let item3 of item2.items3" style="display: flex;">
                                    <img class="list-icon" src="../../assets/header/right-arrow.svg"/> 
                                    <a class="list-link" (click)="this.navigateTo(item3.href)" rel="nofollow"  [attr.href]="item3.href" [class.noactive]="item3.href=='/'">{{item3.title}}</a>
                                 </li>
                              </ul>
                           </li>
                           <!---->
                         </div> 
                      </ul> 
                  
                   </div>
                   <div class="riht-column">
                      <div style="position: absolute; left: 15px; top: 15px;">
                         <img src="../../assets/header/B1.jpg" class="riht-column__img"/>
                         <p class="left-content__discription-two">Мы открыты для новых партнеров и интересных совместных решений</p>
                         <a class="arrow">&rarr;</a>
                      </div>
                   </div>
                </ul>
             </li>
         </ul>

         <ul class="sabmenu-programming">
            <li>
               <ul class="menu-columns"> <!-- *ngIf="item.items.length > 0" [class.submenu]="item.items.length > 0"-->
                  <div class="left-column">
                     <div class="left-content" >
                        <img  class="left-content__icon" src="../../assets/header/programming/Programm v1.svg"/>
                        <h3 class="left-content__title">АВТОМАТИЗИРУЕМ СЛОЖНЫЕ ПРОЦЕССЫ</h3>
                        <p class="left-content__discription">Поэтапное внедрение программ для комфортной работы</p>
                        <a class="arrow">&rarr;</a>
                     </div>
                  </div>
                  <div class="center-columns1">
                     <div class="separator">
                        
                     </div> 
                     <ul class="center-columns__menu">
                         <div *ngFor="let item of this.site.menu" class="wrapp-columns">
                           <li *ngFor="let item2 of item.items4" class="center-column">
                              <a class="list-title"  (click)="this.navigateTo(item2.href)" rel="nofollow" [attr.href]="item2.href" >{{item2.title}}<span class="fa fa-angle-down"></span></a>
                              <ul *ngIf="item2.items4.length > 0" class="submenu">
                                 <li *ngFor="let item3 of item2.items4" style="display: flex;">
                                    <img class="list-icon" src="../../assets/header/right-arrow.svg"/> 
                                    <a class="list-link" (click)="this.navigateTo(item3.href)" rel="nofollow"  [attr.href]="item3.href" [attr.href]="item3.href" [class.noactive]="item3.href=='/'">{{item3.title}}</a>
                                 </li>
                              </ul>
                           </li>
                         </div>  
                             
                         <div *ngFor="let item of this.site.menu" class="wrapp-columns1">
                        
                          <li *ngFor="let item2 of item.items5" class="center-column">
                             <div class="center-column__menu-title">
                              <img class="center-column__icon-title" [attr.src]="this.item2.image"/>
                                <a class="list-title"  (click)="this.navigateTo(item2.href)" rel="nofollow" [attr.href]="item2.href" >{{item2.title}}<span class="fa fa-angle-down"></span></a>
                             </div>
                             <ul *ngIf="item2.items5.length > 0" class="submenu">
                                <li *ngFor="let item3 of item2.items5" style="display: flex;">
                                   <img class="list-icon" src="../../assets/header/right-arrow.svg"/> 
                                   <a class="list-link" (click)="this.navigateTo(item3.href)" rel="nofollow"  [attr.href]="item3.href" [attr.href]="item3.href" [class.noactive]="item3.href=='/'">{{item3.title}}</a>
                                </li>
                             </ul>
                          </li>
                          <!---->
                        </div> 
                     </ul>
                 
                  </div>
                  <div class="riht-column">
                     <div style="position: absolute; left: 15px; top: 15px;">
                        <img src="../../assets/header/B1.jpg" class="riht-column__img"/>
                        <p class="left-content__discription-two">Мы открыты для новых партнеров и интересных совместных решений</p>
                        <a class="arrow">&rarr;</a>
                     </div>
                  </div>
               </ul>
            </li>
        </ul>
        
        <ul class="sabmenu-product">
         <li>
            <ul class="menu-columns"> <!-- *ngIf="item.items.length > 0" [class.submenu]="item.items.length > 0"-->
               <div class="left-column">
                  <div class="left-content" >
                     <img  class="left-content__icon" src="../../assets/header/product/rrodukti v1.svg"/>
                     <h3 class="left-content__title">РАСШИРЕНИЯ И РАЗРАБОТКИ</h3>
                     <p class="left-content__discription">Созданные нами продукты уже решают сложные задачи</p>
                     <a class="arrow">&rarr;</a>
                  </div>
               </div>
               
               <div class="center-columns1">
                  <div class="separator">
                        
                  </div> 
                  <ul class="center-columns__menu">
                      <div *ngFor="let item of this.site.menu" class="wrapp-columns">
                        <li *ngFor="let item2 of item.items6" class="center-column">
                           <a class="list-title"  (click)="this.navigateTo(item2.href)" rel="nofollow" [attr.href]="item2.href" >{{item2.title}}<span class="fa fa-angle-down"></span></a>
                           <ul *ngIf="item2.items6.length > 0" class="submenu">
                              <li *ngFor="let item3 of item2.items6" style="display: flex;">
                                 <img class="list-icon" src="../../assets/header/right-arrow.svg"/> 
                                 <a class="list-link" (click)="this.navigateTo(item3.href)" rel="nofollow"  [attr.href]="item3.href" [attr.href]="item3.href" [class.noactive]="item3.href=='/'">{{item3.title}}</a>
                              </li>
                           </ul>
                        </li> 
                        
                      </div>  
                      
                      <div *ngFor="let item of this.site.menu" class="wrapp-columns1">
                       <li *ngFor="let item2 of item.items7" class="center-column">
                          <div class="center-column__menu-title">
                           <img class="center-column__icon-title" [attr.src]="this.item2.image"/>
                             <a class="list-title"  (click)="this.navigateTo(item2.href)" rel="nofollow" [attr.href]="item2.href" >{{item2.title}}<span class="fa fa-angle-down"></span></a>
                          </div>
                          <ul *ngIf="item2.items7.length > 0" class="submenu">
                             <li *ngFor="let item3 of item2.items7" style="display: flex;">
                                <img class="list-icon" src="../../assets/header/right-arrow.svg"/> 
                                <a class="list-link" (click)="this.navigateTo(item3.href)" rel="nofollow"  [attr.href]="item3.href" [attr.href]="item3.href" [class.noactive]="item3.href=='/'">{{item3.title}}</a>
                             </li>
                          </ul>
                       </li>
                       <!---->
                     </div> 
                  </ul>
              
               </div>
               <div class="riht-column">
                  <div style="position: absolute; left: 15px; top: 15px;">
                     <img src="../../assets/header/B1.jpg" class="riht-column__img"/>
                     <p class="left-content__discription-two">Мы открыты для новых партнеров и интересных совместных решений</p>
                     <a class="arrow">&rarr;</a>
                  </div>
               </div>
            </ul>
         </li>
        </ul>
    
        <ul class="sabmenu-integration"> 
         <li>
            <ul class="menu-columns"> <!-- *ngIf="item.items.length > 0" [class.submenu]="item.items.length > 0"-->
               <div class="left-column">
                  <div class="left-content" >
                     <img  class="left-content__icon" src="../../assets/header/integration/Integraciya v1.svg"/>
                     <h3 class="left-content__title">ДВУСТОРОННИЕ ИНТЕГРАЦИИ</h3>
                     <p class="left-content__discription">Модульная система обмена данных в единое поле между разными системами</p>
                     <a class="arrow">&rarr;</a>
                  </div>
               </div>
               <div class="center-columns1">
                  <div class="separator">
                        
                  </div> 
                  <ul class="center-columns__menu">
                      <div *ngFor="let item of this.site.menu" class="wrapp-columns">
                        <li *ngFor="let item2 of item.items8" class="center-column">
                           <a class="list-title"  (click)="this.navigateTo(item2.href)" rel="nofollow" [attr.href]="item2.href" >{{item2.title}}<span class="fa fa-angle-down"></span></a>
                           <ul *ngIf="item2.items8.length > 0" class="submenu">
                              <li *ngFor="let item3 of item2.items8" style="display: flex;">
                                 <img class="list-icon" src="../../assets/header/right-arrow.svg"/> 
                                 <a class="list-link" (click)="this.navigateTo(item3.href)" rel="nofollow"  [attr.href]="item3.href" [attr.href]="item3.href" [class.noactive]="item3.href=='/'">{{item3.title}}</a>
                              </li>
                           </ul>
                        </li>
                      </div>  
                          
                      <div *ngFor="let item of this.site.menu" class="wrapp-columns1">
                    
                       <li *ngFor="let item2 of item.items9" class="center-column">
                          <div class="center-column__menu-title">
                           <img class="center-column__icon-title" [attr.src]="this.item2.image"/>
                             <a class="list-title"  (click)="this.navigateTo(item2.href)" rel="nofollow" [attr.href]="item2.href" >{{item2.title}}<span class="fa fa-angle-down"></span></a>
                          </div>
                          <ul *ngIf="item2.items9.length > 0" class="submenu">
                             <li *ngFor="let item3 of item2.items9" style="display: flex;">
                                <img class="list-icon" src="../../assets/header/right-arrow.svg"/> 
                                <a class="list-link" (click)="this.navigateTo(item3.href)" rel="nofollow"  [attr.href]="item3.href" [attr.href]="item3.href" [class.noactive]="item3.href=='/'">{{item3.title}}</a>
                             </li>
                          </ul>
                       </li>
                       <!---->
                     </div> 
                  </ul>
              
               </div>
               <div class="riht-column">
                  <div style="position: absolute; left: 15px; top: 15px;">
                     <img src="../../assets/header/B1.jpg" class="riht-column__img"/>
                     <p class="left-content__discription-two">Мы открыты для новых партнеров и интересных совместных решений</p>
                     <a class="arrow">&rarr;</a>
                  </div>
               </div>
            </ul>
         </li>
        </ul>

        <ul class="sabmenu-company">
         <li>
            <ul class="menu-columns"> <!-- *ngIf="item.items.length > 0" [class.submenu]="item.items.length > 0"-->
               <div class="left-column">
                  <div class="left-content" >
                     <img  class="left-content__icon" src="../../assets/header/company/Kompani v1.svg"/>
                     <h3 class="left-content__title">ПРОЗРАЧНЫЕ РЕШЕНИЯ</h3>
                     <p class="left-content__discription">Чем сложнее задача, тем интереснее</p>
                     <a class="arrow">&rarr;</a>
                  </div>
               </div>
               <div class="center-columns1">
                  <div class="separator">
                        
                  </div> 
                  <ul class="center-columns__menu">
                      <div *ngFor="let item of this.site.menu" class="wrapp-columns">
                        <li *ngFor="let item2 of item.items10" class="center-column">
                           <a class="list-title"  (click)="this.navigateTo(item2.href)" rel="nofollow" [attr.href]="item2.href" >{{item2.title}}<span class="fa fa-angle-down"></span></a>
                           <ul *ngIf="item2.items10.length > 0" class="submenu">
                              <li *ngFor="let item3 of item2.items10" style="display: flex;">
                                 <img class="list-icon" src="../../assets/header/right-arrow.svg"/> 
                                 <a class="list-link" (click)="this.navigateTo(item3.href)" rel="nofollow"  [attr.href]="item3.href" [attr.href]="item3.href" [class.noactive]="item3.href=='/'">{{item3.title}}</a>
                              </li>
                           </ul>
                        </li>
                      </div>  
                          
                      <div *ngFor="let item of this.site.menu" class="wrapp-columns1">
                    
                       <li *ngFor="let item2 of item.items11" class="center-column">
                          <div class="center-column__menu-title">
                           <img class="center-column__icon-title" [attr.src]="this.item2.image"/>
                             <a class="list-title"  (click)="this.navigateTo(item2.href)" rel="nofollow" [attr.href]="item2.href" >{{item2.title}}<span class="fa fa-angle-down"></span></a>
                          </div>
                          <ul *ngIf="item2.items11.length > 0" class="submenu">
                             <li *ngFor="let item3 of item2.items11" style="display: flex;">
                                <img class="list-icon" src="../../assets/header/right-arrow.svg"/> 
                                <a class="list-link" (click)="this.navigateTo(item3.href)" rel="nofollow"  [attr.href]="item3.href" [attr.href]="item3.href" [class.noactive]="item3.href=='/'">{{item3.title}}</a>
                             </li>
                          </ul>
                       </li>
                       <!---->
                     </div> 
                  </ul>
              
               </div>
               <div class="riht-column">
                  <div style="position: absolute; left: 15px; top: 15px;">
                     <img src="../../assets/header/B1.jpg" class="riht-column__img"/>
                     <p class="left-content__discription-two">Мы открыты для новых партнеров и интересных совместных решений</p>
                     <a class="arrow">&rarr;</a>
                  </div>
               </div>
            </ul>
         </li>
        </ul>
      
    </li>


     <!--выпадающий список меню основное содержимое дублируетсся меняется только контент слева и справа-->
   



</nav>


<!--<div class="wrapper mobile" #wrapper>

    <header class="nav-top">
        <app-search></app-search>
        <span class="hamburger material-icons" id="ham" (click)="this.toggleNav()">menu</span>
    </header>
    
    <nav class="nav-drill">
      <ul class="nav-items nav-level-1">
        <li *ngFor="let item of this.site.menu" class="nav-item nav-expand">

            <a [class.nav-expand-link]="item.items.length>0" class="nav-link" [attr.href]="item.href">
                {{item.title}}
            </a>

            <ul *ngIf="item.items.length > 0" class="nav-items nav-expand-content">
                <li class="nav-item">
                    <a class="nav-link nav-back-link" href="javascript:;">
                        Назад
                    </a>
                </li>

                <li *ngFor="let item2 of item.items" class="nav-item nav-expand">
                    <a [class.nav-expand-link]="item2.items.length>0" class="nav-link "  [attr.href]="item2.href">
                        {{item2.title}}
                    </a>
                    <ul *ngIf="item2.items.length > 0" class="nav-items nav-expand-content">
                        <li class="nav-item">
                            <a class="nav-link nav-back-link" href="javascript:;">
                                Назад
                            </a>
                        </li>

                        <li *ngFor="let item3 of item2.items"  class="nav-item ">
                            <a  class="nav-link"  [attr.href]="item3.href">
                                {{item3.title}}
                            </a>
                            
                        </li>
                        
                    </ul>
                </li>
                
            </ul>
        </li>
      </ul>
    </nav> 
</div>-->

