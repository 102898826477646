<div class="wrapp" *ngIf="this.isClose==false">

    <div class="modal-window">
        <img class="cross" src="assets/main/cross.svg" (click)="this.close()"/>
        <div class="content"> 
            <img class="logo" src="assets/main/Logo red.svg"/>
            <h3 class="title">Уважаемые гости!</h3>
            <h3 class="subtitle">Сайт находится в разработке некоторые страницы и элементы могут быть неактивны</h3>
            <h3 class="subtitle2">Спасибо за понимание! Команда Tripadvance</h3>
        </div>
    </div>
</div>
 