export enum SliderAnimationType{
    LinearTween = 'linearTween',
    EaseInQuad = 'easeInQuad',
    EaseOutQuad = 'easeOutQuad',
    EaseInOutQuad = 'easeInOutQuad',
    EaseInCubic = 'easeInCubic',
    EaseOutCubic = 'easeOutCubic',
    EaseInOutCubic = 'easeInOutCubic',
    EaseOutQuart = 'easeOutQuart',
    EaseInOutQuart = 'easeInOutQuart',
    EaseInQuint = 'easeInQuint',
    EaseOutQuint = 'easeOutQuint',
    EaseInOutQuint = 'easeInOutQuint',
    EaseInSine = 'easeInSine',
    EaseOutSine = 'easeOutSine',
    EaseInOutSine = 'easeInOutSine',
    EaseInExpo = 'easeInExpo',
    EaseOutExpo = 'easeOutExpo',
    EaseInOutExpo = 'easeInOutExpo',
    EaseInCirc = 'easeInCirc',
    EaseOutCirc = 'easeOutCirc',
    EaseInOutCirc = 'easeInOutCirc' 
  }