import { Component } from '@angular/core';

@Component({
  selector: 'app-test-blocks',
  templateUrl: './test-blocks.component.html',
  styleUrls: ['./test-blocks.component.scss']
})
export class TestBlocksComponent {

}
