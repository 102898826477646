<section class="wrapp">
    <div class="contenier-card">
       <div class="cards cards-hover__one">
          <div class="cards__cont-title">
             <h3 class="cards__title color-txt__dark">РАССШИРЕНИЯ</h3>
             <h4 class="cards__subtitle color-txt__dark">РАЗРАБОТКА</h4>
             <h4 class="cards__subtitle color-txt__dark">ПРОГРАММИРОВАНИЕ</h4>
          </div>
          <img class="cards__img"  src="../../../assets/main/B1.png"/>

          <div class="content-feetbeck-one">
           <div class="cards__cont-title-one ">
               <h3 class="cards__title color-txt__lite-one">РАССШИРЕНИЯ</h3>
               <h4 class="cards__subtitle color-txt__lite-one">РАЗРАБОТКА</h4>
               <h4 class="cards__subtitle color-txt__lite-one" >ПРОГРАММИРОВАНИЕ</h4>
            </div>
           <p class="content-feetbeck__text-one color-txt__lite-one">Индивидуальная разработка нетиповых решений под нужды клиента, от печатных форм до собственных конфигураций. (Парковка, эл. замки, бронировние в Битрикс 24, персонализация карт и другое)</p>
           <app-button class="feetback-button">ПЕРЕЙТИ</app-button>
        </div>

       </div>
       <div class="cards cards-hover">
           <div class="cards__cont-title">
               <h3 class="cards__title color-txt__lite">ВНЕДРЕНИЕ</h3>
               <h4 class="cards__subtitle color-txt__lite">ПРОГРАММА 1С </h4>
               <h4 class="cards__subtitle color-txt__lite" >ИНТЕГРАЦИИ</h4>
            </div>
         
            <div class="content-front-feetbeck">
              
                <img class="content-front__img" src="../../../assets/main/B2V2.png"/>
             
                <div class="content-feetbeck">
                   <p class="content-feetbeck__text">Двухсторонние интеграции между программой 1С: Отель и модулем бронирования. Интеграция с популярными менеджерами каналов (Яндекс Путешествия и другими), Формирование брони в Битрикс 24</p>
                   <app-button class="feetback-button">ПЕРЕЙТИ</app-button>
                </div>
            </div>
       </div>
       <div class="cards cards-hover">
           <div class="cards__cont-title">
               <h3 class="cards__title color-txt__lite ">ОБСЛУЖИВАНИЕ</h3>
               <h4 class="cards__subtitle color-txt__lite">ОБОРУДОВАНИЕ</h4>
               <h4 class="cards__subtitle color-txt__lite">ПОДКЛЮЧЕНИЕ</h4>
            </div>
            <div class="content-front-feetbeck">
          
                   <img class="content-front__img overflow-img" src="../../../assets/main/B3V2.png"/>
            
               <div class="content-feetbeck">
                  <p class="content-feetbeck__text">Продажа и настройка оборудования: кассы - онлайн, считыватели штрих кодов, сканеры. Регистрация в ФНС, ОФД, ККМ. Отладка сетевого режима, web сервиса</p>
                  <app-button class="feetback-button">ПЕРЕЙТИ</app-button>
               </div>
           </div>
       </div>
       <div class="cards cards-hover">
           <div class="cards__cont-title">
               <h3 class="cards__title color-txt__lite">ОБЩЕПИТ</h3>
               <h4 class="cards__subtitle color-txt__lite">АВТОМАТИЗАЦИЯ</h4>
               <h4 class="cards__subtitle color-txt__lite">РЕСТОРАНА</h4>
            </div>
            <div class="content-front-feetbeck">
               <div></div>
               <img class="content-front__img" src=".../../../assets/main/BLOCK 4 V3.png"/>
               <div class="content-feetbeck">
                
                  <p class="content-feetbeck__text">Создание и настройка Back-Ofice / Front - Ofice. Подключение ЕГАИС. Регистрация в честном знаке. Подключение к ЭДО. Настройка УТМ. Полная настройка торгового оборудование, обучение сотрудников</p>
                  <app-button class="feetback-button" >ПЕРЕЙТИ</app-button>
               </div>
           </div>
         
       </div>
       <div class="cards mobile-cards">
        <img class="cards-logo" src="../../../assets/main/LOGO BLOCK.png"/>
    </div>
    </div>
</section>
