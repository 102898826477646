import { Component } from '@angular/core';

@Component({
  selector: 'app-services-programming-extensions',
  templateUrl: './services-programming-extensions.component.html',
  styleUrls: ['./services-programming-extensions.component.scss']
})
export class ServicesProgrammingExtensionsComponent {

}
