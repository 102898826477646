
<div action="">
    <input type="search" [(value)]="this.value" (change)="onChange($event)" required>
    <i class="fa fa-search"></i>
    <app-icon class="icon" name="seo-social" size="24" color="white"></app-icon>
</div>

<!--
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous">

<div class="pointer">
    
</div>
-->

