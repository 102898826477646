import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-discription-row',
  templateUrl: './discription-row.component.html',
  styleUrls: ['./discription-row.component.scss']
})
export class DiscriptionRowComponent {
  @Input() public title:any = "";
  @Input() public title2:any = "";
  @Input() public title3:any = "";
  @Input() public title4:any = "";
  @Input() public title5:any = "";
  @Input() public title6:any = "";
  @Input() public title7:any = "";
  @Input() public title8:any = "";

  @Input() public subtitle:any = "";
  @Input() public subtitle2:any = "";
  @Input() public subtitle3:any = "";
  @Input() public subtitle4:any = "";
  @Input() public subtitle5:any = "";
  @Input() public subtitle6:any = "";
  @Input() public subtitle7:any = "";
  @Input() public subtitle8:any = "";
  @Input() public subtitle9:any = "";

  @Input() public link:any = "";

  @Input() public discription:any = "";
}
