<div class="wrapp menu-dekstop">
    <div class="top-menu">
      <div class="top-menu__content">
        <div class="franch pointer" (click)="this.openPage('')"> 
        <div class="franch__icon">
            <img class="franch__icon-img" src="../../../assets/main/Logo red.svg"/>
            <div class="franch_1c">
                <div class="franch_title">
                   
                    <h5  class="franch_text">КОМПЛЕКС РЕШЕНИЙ</h5>
                </div>
                <h6 class="franch_text">ДЛЯ ОТЕЛЕЙ</h6>
            </div>
        </div>

        <!--

        -->

        </div>
        <div class="wrapper_right">
      
      <div class="navigation">
          <div>
         <app-search-dark></app-search-dark>
          </div>
          
          <div class="navigation_button pointer-underline navigation_adaptive__partner">
              <p class="navigation_button__text">Наши партнеры</p>
          </div>
          <div class="navigation_button pointer-underline navigation_adaptive__training">
              <p class="navigation_button__text">Обучение</p>
          </div>
          <div class="navigation_button pointer-underline navigation_adaptive__consultation">
              <img class="navigation__icon" src="../../../assets/icons/icon4.svg" alt="like"/>
              <p class="navigation_button__text">Бесплатная консультация</p>
          </div>

          <!--(onChange)="this.setObjectAction(object, $event.value)"-->

         <!-- <app-select class="navigation_adaptive__select" [showHeader]='false' [items]="this.getNavigationAction()" > 
              <div class="select-action pointer">
                  <p class="select-action__button">...</p>
              </div>
          </app-select> -->
          

      </div>
  
      <div class="contacts">
          <p class="contacts_phone pointer">
              8 (861) 213-23-13
          </p>
          <a class="contacts_email pointer" href="mailto:acc@tripadvance.ru">
            acc<div class="icon-mail"></div>tripadvance.ru
          </a>
      </div>
    <!--<div class="login">
        <a class="login__bg"><img src="../../../assets/header/bell.svg" style="width: 20px; "/></a>
        <div class="line-login"></div>
        <a class="login__bg"><img src="../../../assets/header/user.svg" style="width: 20px; "/></a>
      </div>-->
  
      <!--        
      <div class="account">
          <app-icon class="account_icon pointer" name="icon1" size="14" ></app-icon>
          <div class="accont_separator"></div>
          <app-icon class="account_icon pointer" name="icon2" size="14" ></app-icon>
      </div>

      -->

        </div>
      </div>
    </div>
    <div style="position: relative;">
        <div class="bottom-menu">
       
          <!--
          *ngIf="this.getScrollY()<100"
           <app-menu style="width: 1310px;" (onChange)="this.openPage($event)"></app-menu>-->
          <app-menu-big class="width-sabmenu-stiky" style="width: 83.5vw;" ></app-menu-big>
        </div>
        <div class="hidden-menu">
           <div class="top-nav">
              <a href="#" class="top-nav__left">
                <img class="hidden-menu__icon-img" src="../../assets/main/Logo red.svg"/>
                <h5 class="hidden-menu__title">КОМПЛЕКС РЕШЕНИЙ ДЛЯ ОТЕЛЕЙ</h5>
              </a>
              <div class="top-nav__search">
                <app-search-dark></app-search-dark>
              </div>

               <div class="top-nav__center">
                 <div class="navigation_button pointer-underline navigation_adaptive__partner">
                    <img class="navigation__icon" src="../../../assets/icons/icon4.svg"/>
                    <p class="navigation_button__text">Бесплатная консультация</p>
                 </div>
                 <div class="navigation_button pointer-underline navigation_adaptive__training">
                    <p class="contacts_phone__stiky pointer">
                     8 (861) 213-23-13
                    </p>
                 </div>
                 <div class="navigation_button pointer-underline navigation_adaptive__consultation">
                     <a class="contacts_email__stiky pointer" href="mailto:acc@tripadvance.ru">
                        acc<div class="icon-mail__stiky"></div>tripadvance.ru
                    </a>
                 </div>
                 
              </div>
              <app-cross-toogle (onClick)="this.menuOpen=!this.menuOpen"></app-cross-toogle>

              <!--
              
                            <input id="menu-toggle" type="checkbox" />
              <label class='menu-button-container' for="menu-toggle">
                 <div class='menu-button'></div>
              </label>
              -->

<!--
*ngIf="this.getScrollY()>70"              
-->
              <div class="menu-hidden__bottom" *ngIf="this.menuOpen"> 

                 <div>
                  <!--
                  menu-hidden__bottom
                  <app-menu style="width: 1310px;" (onChange)="this.openPage($event)"></app-menu>-->
                  <app-menu-big style="width: 83.5vw; "></app-menu-big>
                 </div>
             
              </div>
              
           </div>
        </div> 
    </div>

</div>

<div class="mobile-menu">
<div class="top-nav__mobile">
    <a href="#" class="top-nav__left">
      <img class="hidden-menu__icon-img" src="../../assets/main/Logo red.svg"/>
      <h5 class="hidden-menu__title">КОМПЛЕКС РЕШЕНИЙ ДЛЯ ОТЕЛЕЙ</h5>
    </a>
   <!--  <div class="top-nav__search">
     <app-search-dark></app-search-dark>
    </div>-->
    <div class="top-nav__center">
      <div class="navigation_button pointer-underline navigation_adaptive__partner">
       <img class="navigation__icon" src="../../../assets/icons/icon4.svg"/>
       <p class="navigation_button__text">Бесплатная консультация</p>
      </div>
    <div class="navigation_button pointer-underline navigation_adaptive__training">
       <p class="contacts_phone__stiky pointer">
        8 (861) 213-23-13
       </p>
    </div>
    <div class="navigation_button pointer-underline navigation_adaptive__consultation">
        <a class="contacts_email__stiky pointer" href="mailto:acc@tripadvance.ru">
           acc<div class="icon-mail__stiky"></div>tripadvance.ru
       </a>
    </div>
    
    </div>
    <input id="menu-toggle2" type="checkbox" />
    <label class='menu-button-container2' for="menu-toggle2">
      <div class='menu-button2'></div>
    </label>
    <div class="menu-hidden__bottom2">
       <div class="tablet-submenu">
        <app-menu-big style="width: 83.5vw;"></app-menu-big>
       </div>
       <div class="mobile-submenu">
          <app-menu-accordion-mobile></app-menu-accordion-mobile>
       </div>
       
    </div>
</div>
</div>