<app-page-content>


    <app-element-product-baner
        [item]="this.page.baner">
    </app-element-product-baner>

    <app-element-product-text
        [item]="this.page.text1">
    </app-element-product-text>
    
    <app-element-tags [item]='this.page.tags'></app-element-tags>

    <app-element-product-item *ngIf="this.device != undefined" [item]="this.device"></app-element-product-item>



    <app-element-product-list *ngIf="this.device == undefined" [item]="this.products">

    </app-element-product-list>

    <app-element-product-text
        [item]="this.page.text2">
    </app-element-product-text>

    
    <div style="margin-top: 48px;">
        <app-block-programming></app-block-programming>
    </div>

    <app-element-product-text
        [item]="this.page.text3">
    </app-element-product-text>

    <div style="margin-top: 48px;">
        <app-main-integrations></app-main-integrations>
    </div>
    <div style="margin-top: 48px;">
        <app-main-chapters></app-main-chapters>
    </div>
    
</app-page-content>
