import { Component, Input } from '@angular/core';



@Component({
  selector: 'app-banner-partner',
  templateUrl: './banner-partner.component.html',
  styleUrls: ['./banner-partner.component.scss']
})
export class BannerPartnerComponent {
  @Input() item:any = {}; 

}  
 