import { Component, OnInit  } from '@angular/core';
import { Subscription } from 'rxjs';
import { VisibilityService } from '../../servises/Visibility.service'; // сервис обмена между компонениами авто закрытие формы

@Component({
  selector: 'app-modal-forms',
  templateUrl: './modal-forms.component.html',
  styleUrls: ['./modal-forms.component.scss']
})
export class ModalFormsComponent implements OnInit{
  showUpper = true;
  isVisible = true;
  subscription!: Subscription;

  constructor(private visibilityService: VisibilityService) { 
    this.subscription = this.visibilityService.hideElement$.subscribe(() => {
      setTimeout(() => {
        this.isVisible = false;
      }, 7000); // Задержка в 1 секунду (1000 мс)
    });
  } 
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe(); // Отписываемся от подписки при уничтожении компонента
    }
  }


  ngOnInit(): void {}

  toggleComponents() {
    this.showUpper = !this.showUpper;
  }

  onRemoveUpper() {
    this.showUpper = false;
  }
  public isClose = false;
  close(){
    this.isClose = true;
  }

  
}
