import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent {
  page:any = {};

  constructor(public site:SiteService, private route: ActivatedRoute){

  } 


  ngOnInit(): void {   
    this.page = this.site.GetCurrentPage();
  }

  ngAfterContentInit(){
  }
}
