import {  Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, Inject, PLATFORM_ID  } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-stiky-header',
  templateUrl: './stiky-header.component.html',
  styleUrls: ['./stiky-header.component.scss']
}) 
export class StikyHeaderComponent {
  @Input() accordions:any =[] 
  public currentPage:any = undefined;
  getScrollY(){
    if (isPlatformBrowser(this.platformId)) {
      return window.scrollY; // Используйте window только на клиенте
    }
    return 0;
   }
   menuOpen: boolean = false;
   @ViewChild("hamburger", { static: false }) hamburger!: ElementRef;
 
   constructor(private router: Router, private route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object){}
 
   getNavigationAction(){
     var items = [      
       {title:'Наши партнеры', selection:false, value:'partner'}, 
       {title:'Обучение', selection:false, value:'training'}, 
       {title:'Бесплатная консультация', selection:false, value:'consultation'}
     ];
     return items;
   }
 
   toggleMenu(){
     this.menuOpen = !this.menuOpen;
   }
   openPageSearch(event:any){
     if(event != "")
       this.router.navigate(['search'],{ queryParams: {value: event}});
   }
 
   openPage(page:any){
     this.router.navigate([page]);
     this.menuOpen = false;
     this.hamburger.nativeElement.checked = false;
   }
   onChange(item:any){
    if(item == this.currentPage){
      return;
    }
    item.expanded = !item.expanded;
    this.currentPage = item;

    if(item.expanded){
      for(let i of this.accordions){
        if(i != item)
          i.expanded = false;
      }
    }
  }
}
