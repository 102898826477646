import { Component } from '@angular/core';

@Component({
  selector: 'app-main-company',
  templateUrl: './main-company.component.html',
  styleUrls: ['./main-company.component.scss']
})
export class MainCompanyComponent {

}
