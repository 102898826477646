<div style="width: 100%; margin-top: 40px;" >

    <div style="width: 90%; margin: auto; margin-bottom: 100px;">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
    <div style="width: 90%; margin: auto;">
      <app-element-product-text 
       [item]="this.page.text1">
      </app-element-product-text> 
   </div> 
 
   <div class="margins-section-tag">
    <app-element-tags [item]='[
         { text:"Модуль бронирования"},
         { text:"Обучение 1С:Отель с нуля"},
         { text:"1С:Отель + Битрикс24"},
         { text:"1С: Отель отчеты"},
         { text:"Лицензии"},
         { text:"Программа 1С: Отель"},
         { text:"1С + кассы онлайн"},
         { text:"Разработка печатных форм"},
         { text:"Услуги программиста по 1С: Отель"},
       ]'>
       </app-element-tags>
   </div>
 
    <div  class="margins-section">
       <app-accordion-all-pages ngSkipHydration *ngIf="this.page.accordion!=undefined"
          [accordions]="this.page.accordion">
       </app-accordion-all-pages>
    </div>
    <div>
       <app-element-packages [item]="this.page.packages">
       </app-element-packages>
     </div>
   
    
  <!--  <div style="margin-top: 150px;">
       <app-gallery></app-gallery>
    </div>-->
 
    <div class="margins-section-footer">
       <app-footer></app-footer> 
     </div> 
 </div>



<!--<app-page-content>

    <app-element-product-baner
        [item]="this.page.baner">
    </app-element-product-baner>

    <app-element-product-text 
        [item]="this.page.text1">
    </app-element-product-text>

    <app-element-product-text
        [item]="this.page.text2">
    </app-element-product-text>

    <app-element-product-text
        [item]="this.page.text3">
    </app-element-product-text>
    
    <app-element-tags [item]='[
        { text:"1С + касса \"Атол\""},
        { text:"Разработка печатных форм"},
        { text:"Обучение 1С:Отель с нуля"},
        { text:"Интеграция с 1С:Отель"},
        { text:"Эл. замки в 1С"},
        { text:"1С:Отель + Битрикс24"},   
    ]'>

    </app-element-tags>

    <app-element-packages [item]="this.page.packages">
    </app-element-packages>

    <app-element-product-text
        [item]="this.page.text4">
    </app-element-product-text>

    <div style="margin-top: 48px;">
        <app-block-programming></app-block-programming>
    </div>

    <app-element-product-text
        [item]="this.page.text5">
    </app-element-product-text>

    <div style="margin-top: 48px;">
        <app-main-integrations></app-main-integrations>
    </div>
    <div style="margin-top: 48px;">
        <app-main-chapters></app-main-chapters>
    </div>
    
</app-page-content>-->

