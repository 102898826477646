import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-marcetong-cards-two',
  templateUrl: './marcetong-cards-two.component.html',
  styleUrls: ['./marcetong-cards-two.component.scss']
})
export class MarcetongCardsTwoComponent {
  @Input() item:any = {};
}
 