<div class="wrapp">
    <div class="left" >
          <h2 class="txt1">{{this.title}}</h2>
          <h3 class="discription1">{{this.subtitle}}</h3>
    </div>
   
    <div class="riht">
        <div class="riht__txt-contenier">
          <h2 class="txt">{{this.title2}}</h2>
          <h3 class="discription">{{this.subtitle2}}</h3>
       </div>
       <div class="riht__img-contenier"  [style.background-image]="this.background"> 
        <button class="button-right">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 80" xml:space="preserve">
              <polyline fill="none" stroke="#fff" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" points="
              0.375,0.375 45.63,38.087 0.375,75.8 "/>
            </svg>
        </button>
       </div>
    </div>
</div>
