import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-element-slides-bottom-animation',
  templateUrl: './element-slides-bottom-animation.component.html',
  styleUrls: ['./element-slides-bottom-animation.component.scss']
})
export class ElementSlidesBottomAnimationComponent {
  @Input() item: any = {};
}
