import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-element-full-w-slider',
  templateUrl: './element-full-w-slider.component.html',
  styleUrls: ['./element-full-w-slider.component.scss']
}) 
export class ElementFullWSliderComponent {
  @Input() item:any = {}; 
} 
