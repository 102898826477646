import { Component } from '@angular/core';

@Component({
  selector: 'app-training-online',
  templateUrl: './training-online.component.html',
  styleUrls: ['./training-online.component.scss']
})
export class TrainingOnlineComponent {

}
