import { Component,Input } from '@angular/core';
import { SiteService } from '../../site.service';
@Component({
  selector: 'app-menu-accordion-element',
  templateUrl: './menu-accordion-element.component.html',
  styleUrls: ['./menu-accordion-element.component.scss']
})
export class MenuAccordionElementComponent {
 
}
