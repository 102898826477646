<div style="width: 86%; margin: auto;">
    <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
 </div> 

<div class="wrapper-stack">
    <h2 class="title-stack">Технологический стек </h2>
    <ul class="list-stack">
       <li>Технологический стекк - это набор технологий, которые используются для разработки и поддержки программного обеспечения. Он включает языки программирования, фреймворки, инструменты и службы. Вот что может входить в технологический стек:</li>  
    </ul>
    <h2 class="title-stack2">Языки программирования</h2>
    <ul class="list-stack">
       <li>Front-end: Языки, которые используются для создания интерфейсов, видимых пользователю </li>  
       <li>HTML и CSS — основа для разметки и стилизации веб-страниц</li> 
       <li>JavaScript — добавляет интерактивность и динамические элементы </li>
       <li>TypeScript — расширение JavaScript с поддержкой типов, упрощающее разработку и отладку крупных проектов </li>
       <li>Back-end: Языки, используемые для серверной логики, баз данных и интеграции с API </li>
       <li>Python — популярный выбор для серверных приложений и обработки данных </li>
       <li>Java — применяется в крупномасштабных корпоративных приложениях </li>
       <li>C# — используется в основном для .NET-платформы и корпоративных приложений </li>
       <li>PHP — традиционный язык для веб-разработки, особенно для CMS, таких как WordPress </li>
       <li>Ruby — в сочетании с фреймворком Ruby on Rails часто используется для стартапов и небольших проектов </li>
       <li>Node.js (JavaScript) — позволяет использовать JavaScript для серверной логики, подходит для приложений с реального времени, таких как чаты</li>
    </ul>
    <h2 class="title-stack2">Фреймворки и библотеки Front-end и Back-end</h2>
    <ul class="list-stack">
       <li>React — JavaScript-библиотека для построения пользовательских интерфейсов</li> 
       <li>Angular — полноценный фреймворк для создания веб-приложений</li> 
       <li>Vue.js — прогрессивный фреймворк для интерфейсов, известный простотой и гибкостью</li> 
       <li>Bootstrap и Tailwind CSS — библиотеки для стилизации, упрощающие создание адаптивных интерфейсов</li> 
       <li>Django и Flask </li> 
       <li>(Python) — популярные фреймворки для построения веб-приложений</li> 
       <li>Spring (Java) — используется для создания корпоративных приложений</li>
       <li>(C#) — для разработки веб-приложений на платформе Microsoft</li>
       <li>Ruby on Rails (Ruby) — удобный фреймворк для быстрого прототипирования</li>
       <li>Express (Node.js) — легковесный фреймворк для создания веб-серверов на JavaScript </li>
    </ul>
    <h2 class="title-stack2">Базы данных </h2>
    <ul class="list-stack">
       <li>MySQL и PostgreSQL — популярные и мощные open-source решения</li> 
       <li>Oracle и Microsoft SQL Server — коммерческие базы данных, часто используемые в корпоративной среде</li> 
       <li>NoSQL базы данных: Поддерживают нетабличные структуры, такие как JSON, графы, документы</li>
       <li>MongoDB — документно-ориентированная база данных, популярна в современных веб-приложениях</li>
       <li>Redis — база данных в памяти, используется для кэша и реального времени</li>
       <li>Cassandra — распределенная база данных для больших объемов данных</li>
       <li>Firebase — облачная база данных с поддержкой синхронизации в реальном времени</li>
    </ul>
    <h2 class="title-stack2">Контейнеризация </h2>
    <ul class="list-stack">
       <li>Docker — для создания и управления контейнерами</li> 
       <li>Kubernetes — система оркестрации контейнеров для управления их кластером</li> 
       <li>CI/CD (непрерывная интеграция и доставка)</li> 
       <li>Jenkins, GitLab CI/CD, CircleCI — для автоматизации сборки и развертывания кода</li> 
       <li></li> 
    </ul>
    <h2 class="title-stack2">1С направление</h2>
    <ul class="list-stack">
       <li>Language (1С) — основной язык разработки в 1С. Он используется для создания бизнес-логики, интерфейсов и взаимодействия с базой данных. Язык поддерживает объектно-ориентированное программирование и специально адаптирован для работы с данными в рамках задач учёта и автоматизации</li> 
       <li>1С:Предприятие 8.x — основная платформа для разработки и запуска приложений. Она включает в себя инструменты для разработки, базу данных, интерфейс и серверную часть</li> 
       <li>Файловый режим (DBF) — используется для небольших решений, где нет необходимости в отдельном сервере базы данных</li> 
       <li>Microsoft SQL Server — чаще всего используется в клиент-серверных установках, так как хорошо оптимизирован для работы с 1С</li> 
       <li>PostgreSQL — поддерживается как альтернатива MS SQL Server, часто применяется в open-source решениях</li> 
       <li>1С:Предприятие WEB-клиент — позволяет работать с приложениями 1С через веб-браузер, что делает систему доступной для удалённых пользователей</li>
       <li>HTTP-сервисы и REST API — поддерживаются для создания интеграций с другими системами. Позволяют взаимодействовать с внешними сервисами через стандартные веб-протоколы</li>
       <li>Конфигуратор 1С — основное средство разработки и настройки прикладных решений в 1С. Включает дизайнеры для создания бизнес-логики, интерфейсов и структуры данных</li>
       <li>Система компоновки данных (СКД) — фреймворк для создания отчётов, позволяющий гибко настраивать выборку и обработку данных</li>
    </ul>
</div>

<div style="margin-top: 100px;">
   <app-footer></app-footer> 
 </div> 
