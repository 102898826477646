import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-services-programming',
  templateUrl: './services-programming.component.html',
  styleUrls: ['./services-programming.component.scss']
})
export class ServicesProgrammingComponent {
  @Input() title:any = "";
  @Input() description: any = "";
}
