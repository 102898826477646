<div class="wrapper">
    <div class="left">
        <div class="content__left">
           <h3 class="content__left-title">{{item.leftTitle}}</h3> 
        </div>
        <div class="content_-center">
           <p class="content__left-discription">{{item.discription}}</p>
        </div>
        <div class="content__bottom">
           <ul class="content__left-list">
              <li class="content__left-list-title" *ngFor="let item of this.item.list">
                <img class="content__left-list-img" src="assets/pages/escort-one-c-hotel/Galochka.svg"/>
                {{item.listTitle}}
             </li>
           </ul>
        </div>
    </div>
    <div class="riht">
        <img class="riht-img" [src]="this.item.img"/>
    </div>
</div>
