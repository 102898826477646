

<div style="height:min-content" class="collapse description__text-collapse" [@collapse]="expanded ? 'visible' : 'hidden'">
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>

<!--
    <div #wrapper class="wrapper">
    <div #content  class="content"  > 
        <ng-content></ng-content>
    </div>
</div>

    [style.height.px]="this.getHight()"
     [style.display]="this.isInitialize()
-->
