<div class="wrapper" [style.background-image]="this.item.imageBg">
    <div class="text-contenier">
        <div class="text-contenier__before">
           <h2 class="title" >{{item.title}}</h2>
           <h3 class="subtitle">{{item.description}}</h3>
          <ul class="list-wrapp">
              <li class="list-title" *ngFor="let item of item.list">
                <div class="img-check" [style.background-image]="this.item.checkImg" ></div>
                {{item.listTitle}}
              </li>
           </ul>
        </div>
        <div class="text-contenier__before" [class.noactive]="item.titleLink==''">
            <app-button class="button" [attr.href]="item.link.href" [class.noactive]="item.titleLink==''">{{item.titleLink}}</app-button>
            <p class="link-discription">{{item.linkDiscription}}</p>
            <h4 class="conteiner-top__text-content-price" *ngFor="let item of item.prise">
                <p class="conteiner-top__text-content" > {{item.title}}</p>
                {{item.priseNew}}
                <h5 class="conteiner-top__text-content-price-sale">{{item.sale}}
                    <p class="conteiner-top__text-content-price-new">{{item.priseOld}}</p>
                </h5></h4>
        </div>
    </div> 
    
    <div class="banner-contenier">
        <div class="banner-contenier__imges">
            <div class="banner-contenier__img" [style.background-image]="this.item.imgChild">
 
            </div>
        </div>
    </div>
</div>
