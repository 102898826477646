import { Component } from '@angular/core';

@Component({
  selector: 'app-services-escort-hotel',
  templateUrl: './services-escort-hotel.component.html',
  styleUrls: ['./services-escort-hotel.component.scss']
})
export class ServicesEscortHotelComponent {

}
