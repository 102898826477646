import { Component } from '@angular/core';

@Component({
  selector: 'app-main-present-two',
  templateUrl: './main-present-two.component.html',
  styleUrls: ['./main-present-two.component.scss']
})
export class MainPresentTwoComponent {

}
