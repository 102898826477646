

<!--
<app-test>

</app-test>
-->



<div class="wrapper">
  
 <!-- <app-header style="z-index: 99999;"></app-header>-->
 <app-stiky-header></app-stiky-header>

 

  
 
  <router-outlet>
    <template ngbModalContainer></template> 
  </router-outlet>

  <!--

 
  <div style="margin-top: 48px;">
    <app-footer></app-footer>
  </div> 
 -->

</div>






