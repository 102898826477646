import { Component } from '@angular/core';

@Component({
  selector: 'app-developments-ukd',
  templateUrl: './developments-ukd.component.html',
  styleUrls: ['./developments-ukd.component.scss']
})
export class DevelopmentsUkdComponent {

}
