import { Component,Input } from '@angular/core';
import { SiteService } from '../../site.service';

@Component({
  selector: 'app-photo-gallerey',
  templateUrl: './photo-gallerey.component.html',
  styleUrls: ['./photo-gallerey.component.scss']
})
export class PhotoGallereyComponent {
  @Input() item:any = {};
  public photosGallerey:any = undefined;
  constructor(public site: SiteService){} 
}
