<div style="width: 100%; margin-top: 40px;" >

    <div style="width: 90%; margin: auto; margin-bottom: 100px;">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
    <div style="width: 90%; margin: auto;">
      <app-element-product-text
       [item]="this.page.text1">
      </app-element-product-text> 
   </div> 
 
   <div class="margins-section-tag">
    <app-element-tags [item]='[
         { text:"Модуль бронирования"},
         { text:"Обучение 1С:Отель с нуля"}, 
         { text:"1С:Отель + Битрикс24"},
         { text:"1С: Отель отчеты"},
         { text:"Лицензии"},
         { text:"Программа 1С: Отель"},
         { text:"1С + кассы онлайн"},
         { text:"Разработка печатных форм"},
         { text:"Услуги программиста по 1С: Отель"},
       ]'>
       </app-element-tags>
   </div>
 
    <div class="margins-section">
        <app-banner-partner [item]="this.page.banerOne" ngSkipHydration></app-banner-partner>
     
    </div>
    
    <div class="margins-section-tree">
       <app-panel-information [item]="this.page.panelInformation"></app-panel-information>
    </div>
    <div class="margins-section-four">
      <app-uslugi [products]="this.page.serviseKassa"></app-uslugi>
   </div>

   
   <app-element-baner-form></app-element-baner-form>

    <div class="margins-section-footer">
       <app-footer></app-footer> 
     </div> 
 </div>
