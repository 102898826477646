import { Component , Input} from '@angular/core';

@Component({
  selector: 'app-element-slide-card',
  templateUrl: './element-slide-card.component.html',
  styleUrls: ['./element-slide-card.component.scss']
})
export class ElementSlideCardComponent {
  @Input() item:any = {}; 
}
