import { Component } from '@angular/core';

@Component({
  selector: 'app-training-class',
  templateUrl: './training-class.component.html',
  styleUrls: ['./training-class.component.scss']
})
export class TrainingClassComponent {

}
