<div class="wrapper">
    <div class="left" *ngFor="let item of this.accordions" >
        <div class="btn"  (click)="this.onChange(item, this.accordions, true)">
                 <div *ngFor="let item2 of item.items">
                  <div class="btn"  (click)="this.onChange(item2, item.items, false)">
                    <div class="contenier-list" >
                      <h1 class="btn-subtitle">{{item2.title}}
                        <img class="icon" src="assets/header/right-arrow.svg" [class.arrow-actual]="item.expanded==true"/>
                      </h1>
                      
                    </div>
                 </div>
  
                  <app-collapse [expanded]="item2.expanded">
                    <div class="line">
                      <div class="contenier-list2" *ngFor="let item3 of item2.items">
                       
                        <h1 class="btn-subtitle3">{{item3.title}}  
                          <img class="icon2" src="assets/header/right-arrow.svg" [class.arrow-actual]="item.expanded==true"/>
                        </h1>
                      </div>
                    </div>
  
                  </app-collapse>
  
         </div> 
    </div>  
</div>
