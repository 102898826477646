import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mini-grin-arrow',
  templateUrl: './mini-grin-arrow.component.html',
  styleUrls: ['./mini-grin-arrow.component.scss']
})
export class MiniGrinArrowComponent {
 
  @Input() small:any = false;

  @Output('onClick') event = new EventEmitter<any>();

  productNext(){
    this.event.emit(1);
  }
 
  productPrev(){
    this.event.emit(-1); 
  }
}
