<div class="wrapper">
  
     
             <div class="product">
                 <div class="product-top">
                      <img class="product-header__title_icon" [attr.src]="this.item.image">
                 </div>
                 <div class="product-bottom">
                     <div class="product-navigation"> 
                         <h3 class="product-header__title_text1">{{this.item.title}}</h3>
                     </div>
                     <p class="product-description">{{this.item.text}}</p>
                     <p class="product-description">{{this.item.text2}}</p>
                 </div>
                 <div class="button-contenier">
                    <a class="button" [attr.href]="item.href" target="_blank">{{this.item.linkText}}</a>
                 </div>
             </div>
       
 
   
 </div>
