import { Component } from '@angular/core';

@Component({
  selector: 'app-training-individual',
  templateUrl: './training-individual.component.html',
  styleUrls: ['./training-individual.component.scss']
})
export class TrainingIndividualComponent {

}
