import { Component, Input  } from '@angular/core';

@Component({
  selector: 'app-checked-foms',
  templateUrl: './checked-foms.component.html',
  styleUrls: ['./checked-foms.component.scss']
})
export class CheckedFomsComponent {
  showElement = true;

  toggleElementVisibility() {
    this.showElement = !this.showElement;
  }
 
}
 