<div class="wrapp">

   <div class="galerey-left" >
       <div class="galerey-left__top">
          <div class="galerey__bg-img bg-img-one">
            
          </div>
          <div class="galerey__bg-img bg-img-two">

          </div>
       </div>
       <div class="galerey-left__bottom">
         <div class="galerey__bg-img bg-img-three" ></div>
         <div class="galerey__bg-img bg-img-four" ></div>
      </div>
   </div> 

   <div class="galerey-riht" >
       <div class="galerey__bg-img bg-img-five"></div>
       <div class="galerey-riht__bottom">
          <div class="galerey__bg-img bg-img-six" ></div>
          <div class="galerey__bg-img bg-img-seven" ></div>
       </div>
   </div> 
</div> 