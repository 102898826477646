import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-content-img-left',
  templateUrl: './content-img-left.component.html',
  styleUrls: ['./content-img-left.component.scss']
})
export class ContentImgLeftComponent {
  @Input() item:any = {};
}
