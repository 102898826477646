
<div class="wrapp">
       <div class="left">
              <div class="left__top">
                 <img class="left-logo" src="assets/header/logo.png"/>
                 <div class="contenier-top-title">
                    <h3 class="top-title">TripAdvance</h3>
                    <p class="top-subtitle">Официальный Сайт ООО "Трип-Адванс"</p>
                 </div>
              </div>
              <div class="left__bottom">
                 <div class="left-titels">
                    <h3 class="left-titel">Выбранная вами тема для заявки</h3> 
                    <p class="left-subtitel">тема раздела</p>
                 </div>
                 <div class="left__bottom__img-content">
                    <div class="img-title">
                      <img class="left__bottom__img" src="assets/pages/escort-one-c-hotel/Icon 1.png"/>
                      <p class="left__bottom-title">Внедрение 1С: Отель</p>
                    </div>
                    <img src="assets/forms/circle-arrow.svg" class="left__bottom-icon"/>
                 </div>
              </div>
           </div>
           <div class="riht">
             
               <form class="form-wrapp-content">
                  <div class="riht__titels">
                     <h3 class="riht__titel">Форма заявки</h3>
                     <p class="riht__subtitel">Заполните ваши данные</p>
   
                     <div class="item-content" #refItem1>
                       <p class="required-icon">*</p>
                       <div class="control" (click)="this.onChange(item)" > 
                           <input type="text"  class="input-title" required>
                           <div class="icon-title-cont"  [class.title-active]="item.top==true">
                             <div class="icon icon-img"  [class.icon-active]="item.top==true"> </div>
                             <label class="icon-title icon1" for="name" >Как вас зовут</label>
                           </div>
                       </div>
                     </div>  
   
                  </div>
   
                  <div class="center-inputs">
                   <div class="item-content" #refItem2>
                       <p class="required-icon">*</p>
                       <div class="control" (click)="this.onChangeTwo(item2)" >
                           <div class="input-title">
                            
                              <app-input-phone class="input-title-t" [value]="this.phone" (onChange)="this.phone = $event"  [required]="true"></app-input-phone>
                        
                           </div>
                           <div class="icon-title-cont" [class.title-active]="item2.topTwo==true">
                              <div class="icon icon-img1"  [class.icon-active1]="item2.topTwo==true"> </div>
                              <label class="icon-title icon1" for="name" >Введите телефон</label>
                           </div>
                        </div>
                     </div> 
                     <div class="item-content" #refItem3>
                       <p class="required-icon">*</p>
                       <div class="control" (click)="this.onChangeTree(item3)" >
                           <input type="text" id="name" class="input-title"  required>
                           <div class="icon-title-cont" [class.title-active]="item3.topTree==true" >
                              <div class="icon icon-img2"  [class.icon-active2]="item3.topTree==true"> </div>
                              <label class="icon-title icon1" for="name" >Введитеэл. почту</label>
                           </div> 
                        </div>
                     </div> 
                  </div>
   
                  <div class="bottom-textera">
                     <div class="item-content">
                       <p class="required-icon">*</p>
                       <div class="item-contenier">
                          <label for="main-text" class="form__label">Комментарий</label>
                          <textarea class="form__text" name="main-text" required></textarea>
                       </div>
                     </div>
                  </div>
   
                  <div class="contener-submit">
                     <app-button (click)="onClick()" (click)="onClickButton()" (click)="onClick1()">ОТПРАВИТЬ</app-button>
                     <div class="wrapp-checked">
                        <app-checked-foms class="margin-chek"></app-checked-foms>
                        <a href="politics-confendiality" class="policuty-href">Согласие на обработку персональных данных</a>
                     </div>
                  </div>
              </form>
   
   
           </div> 
     
       
      
</div> 


