import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-element-dicision',
  templateUrl: './element-dicision.component.html',
  styleUrls: ['./element-dicision.component.scss']
})
export class ElementDicisionComponent {
  @Input() item:any = {};
}
