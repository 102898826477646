import { Component, Input  } from '@angular/core';

@Component({
  selector: 'app-accordion-all-pages-two',
  templateUrl: './accordion-all-pages-two.component.html',
  styleUrls: ['./accordion-all-pages-two.component.scss']
})
export class AccordionAllPagesTwoComponent {
  @Input() item:any = {};
  public currentPage:any = undefined;
  @Input() accordionsT:any =[]
 
  constructor(){

  }
  ngOnInit(){
    if(this.accordionsT.length>0)
      this.currentPage = this.accordionsT[0];
  }

  onChange(item:any){
    if(item == this.currentPage){
      return;
    }
    item.expanded = !item.expanded;
    this.currentPage = item;

    if(item.expanded){
      for(let i of this.accordionsT){
        if(i != item)
          i.expanded = false;
      }
    }
  }
}
