import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Component({
  selector: 'app-fiscal-storage',
  templateUrl: './fiscal-storage.component.html',
  styleUrls: ['./fiscal-storage.component.scss']
})
export class FiscalStorageComponent {
 
  page:any = {};

  constructor(public site:SiteService){
    this.page = this.site.GetCurrentPage();
    console.log(this.page);
  }


  
}
