<div class="wrapp">
    <div class="button">
       <app-mini-grin-arrow  (onClick)="($event == 1) ? this.productNext() : this.productPrev()">
       </app-mini-grin-arrow > 
    </div>

    <div class="products">
        <app-slider [type]="this.animationType" [countView]="this.getCountProductView()" [duration]="500">
            
            <app-slide *ngFor="let item of this.site.elements.cardsForSlider">
                
                <app-element-slide-card class="slide-card" [item]="item" style="height:max-content"></app-element-slide-card>
                  
            </app-slide> 
                        
        </app-slider>
    </div>
</div>  

