import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-services-escort',
  templateUrl: './services-escort.component.html',
  styleUrls: ['./services-escort.component.scss']
})
export class ServicesEscortComponent {
  @Input() title:any = "";
  @Input() description: any = "";
}
