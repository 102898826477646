import { Component } from '@angular/core';

@Component({
  selector: 'app-round-span',
  templateUrl: './round-span.component.html',
  styleUrls: ['./round-span.component.scss']
})
export class RoundSpanComponent {

}
