<div style="width: 100%; margin-top: 40px;" >

    <div style="width: 90%; margin: auto;">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
   
    <div class="hi">
        <h1 class="hi__title">Привет! Мы Tripadvance</h1>
    </div>

    <div style="width: 90%; margin: auto; margin-top: 100px;">
        <app-photo-gallerey></app-photo-gallerey>
    </div>
    
    <div style="width: 90%;margin: auto;">
       <app-discription-row
         [title]="'НАША ЦЕЛЬ'"
         [subtitle]="'Быстрое реагирование на изменение ситуации в сфере размещения гостей. Развитие и создание собственных решений которые упрощают сложные процессы для управления объектом и бронированием'"
         [discription]="'Наша компания занимается IT-автоматизацией гостиничного бизнеса и интеграциями — от систем контроля доступа на территорию до разработки сайтов с автоматизированной системой бронирования и внедрением чат-ботов на основе GPT. Мы упрощаем сложные процессы бронирования и управления отелем чтобы наши клиенты могли уделять время своей самой важной работе — заботе о гостях. Благодаря нашим программным продуктам и решениям мы даем возможность объектам размещения развиваться в сфере гостеприимства, независимо от их размера'"
       >
       </app-discription-row> 
    </div>
    <div style="width: 100%;">
        <app-disripton-banner-row
          [title]="'ВМЕСТЕ С КОМАНДОЙ TRIPADVANCE'"
          [subtitle]="'Решения, позволяющие нашим клиентам улучшить качество обслуживания гостей и операций с помощью ведущих в отрасли сервисов, благодоря нашим партнерам'"
          [title2]="'НАШИ ПАРТНЕРЫ СОТРУДНИЧЕСТВО'"
          [subtitle2]="'Мы - это симбиоз увлеченных отельеров и лидеров отрасли. Наша преданная команда проведет вас через весь процесс внутреннего мира tripadvance'"
      
        >
        </app-disripton-banner-row> 
     </div>

    
     <div class="hi">
       <h1 class="hi__title">Некоторые наши новости!</h1>
     </div>
  <!--  <div style="margin-top: 150px;">
       <app-gallery></app-gallery>
    </div>-->
    <div style="width: 90%; margin:100px auto;">
      <app-card-slides ></app-card-slides>
    </div>  

    <div style="margin-top: 100px;">
      <app-footer></app-footer> 
    </div> 
 </div>
