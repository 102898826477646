<div class="wrapp" >
    <div class="left-content">
      <div class="left-banner">
          
           <div class="left-banner-img"></div>

        <div class="conteiner-top__text-content"> 
          <div>
            <div class="conteiner-top-title-content">
               <h2 class="conteiner-top-title-content__title">Полный пакет подключения <br>Онлайн Касс</h2>
             
            </div>
            <h3 class="conteiner-top__text-content-subtitle">Доставим оборудование в любую точку России. Подключим и настроим
              программное обеспечение. Вы получите быстрое решение при
              минимальных затратах.</h3>
            <p class="conteiner-top__text-content-list"><img class="conteiner-top__text-content-list-icon" src="assets/icons/arrow-text-content.svg"/>Регистрация в налоговую ФНС</p>
            <p class="conteiner-top__text-content-list"><img class="conteiner-top__text-content-list-icon" src="assets/icons/arrow-text-content.svg"/>Активация кабинета ОФД</p>
            <p class="conteiner-top__text-content-list"><img class="conteiner-top__text-content-list-icon"src="assets/icons/arrow-text-content.svg"/>Настройка ККМ</p>
            <p class="conteiner-top__text-content-list"><img class="conteiner-top__text-content-list-icon" src="assets/icons/arrow-text-content.svg"/>Подключени к 1С</p>
          </div>
            
         </div>
      </div>
    </div>
  
    <div class="wrapp-links" >
      <div class="conteiner-bottom">
        <app-button class="conteiner-bottom-button">ОФОРМИТЬ</app-button>
        <h4 class="conteiner-top__text-content-price" >Стоимость: 10500&#8381;
          <h5 class="conteiner-top__text-content-price-sale">1000
              <p class="conteiner-top__text-content-price-new">11500</p>
          </h5></h4>
      </div>
    </div>
   
   
  
    <div class="scroll-wrapper">
      <div class="scroll-section">
        <div class="left">
    
        </div>
        <div class="riht section1">
           <div class="card-contenier">
            <a href="#" class="card-contenier__wrapp-img">
              <img class="card-img" src="assets/paralel-scroll/2.jpg"/>
            </a>
            <div class="card-cotenier__text">
              <h3 class="card__title">Интеграции с программой<br>1С ОТЕЛЬ</h3>
              <p class="card-discription">Двухсторонние интеграции между программой 1С:
                Отель - электронными замками, менеджерами
                каналов, Битрикс 24%, модулем бронирования</p>
              <app-button-detail>ПОДРОБНЕЕ</app-button-detail>
            </div>
           </div>

           <div class="card-contenier">
            <a href="#" class="card-contenier__wrapp-img">
              <img class="card-img" src="assets/paralel-scroll/3.jpg"/>
            </a>
            <div class="card-cotenier__text">
              <h3 class="card__title">Модуль онлайн<br>бронирования для отелей</h3>
              <p class="card-discription">Прямые бронирования с сайта, без комиссии и
                ограничений. Интеграция с программой 1С:Отель,
                Битрикс 24, менеджерами каналов</p>
              <app-button-detail>ПОДРОБНЕЕ</app-button-detail>
            </div>
           </div>

           <div class="card-contenier">
            <a href="#" class="card-contenier__wrapp-img">
               <img class="card-img" src="assets/paralel-scroll/4.jpg"/>
            </a>
            <div class="card-cotenier__text">
              <h3 class="card__title">Программирование и<br>разработка на базе 1С ОТЕЛЬ</h3>
              <p class="card-discription">Индивидуальная разработка не типовых задач
                расширения, обработки, дополнения. Создание
                печатных форм и собственных конфигураций</p>
              <app-button-detail>ПОДРОБНЕЕ</app-button-detail>
            </div>
           </div>
        </div>
        
      </div>
      <div class="scroll-section">
        <div class="left">
         
        </div>
        <div class="riht section2">
          <div class="card-contenier">
            <a href="#" class="card-contenier__wrapp-img">
              <img class="card-img" src="assets/paralel-scroll/2.jpg"/>
            </a>
            <div class="card-cotenier__text">
              <h3 class="card__title">Интеграции с программой<br>1С ОТЕЛЬ</h3>
              <p class="card-discription">Двухсторонние интеграции между программой 1С:
                Отель - электронными замками, менеджерами
                каналов, Битрикс 24%, модулем бронирования</p>
              <app-button-detail>ПОДРОБНЕЕ</app-button-detail>
            </div>
           </div>

           <div class="card-contenier">
            <a href="#" class="card-contenier__wrapp-img">
              <img class="card-img" src="assets/paralel-scroll/3.jpg"/>
            </a>
            <div class="card-cotenier__text">
              <h3 class="card__title">Модуль онлайн<br>бронирования для отелей</h3>
              <p class="card-discription">Прямые бронирования с сайта, без комиссии и
                ограничений. Интеграция с программой 1С:Отель,
                Битрикс 24, менеджерами каналов</p>
              <app-button-detail>ПОДРОБНЕЕ</app-button-detail>
            </div>
           </div>

           <div class="card-contenier">
            <a href="#" class="card-contenier__wrapp-img">
               <img class="card-img" src="assets/paralel-scroll/4.jpg"/>
            </a>
            <div class="card-cotenier__text">
              <h3 class="card__title">Программирование и<br>разработка на базе 1С ОТЕЛЬ</h3>
              <p class="card-discription">Индивидуальная разработка не типовых задач
                расширения, обработки, дополнения. Создание
                печатных форм и собственных конфигураций</p>
              <app-button-detail>ПОДРОБНЕЕ</app-button-detail>
            </div>
           </div>
        </div>
      </div>
    
  
    </div> 
  
  </div>
