<div class="wrapp">
    <div class="riht" >
        <h2 class="riht__txt">{{this.title}}</h2>
        <h2 class="riht__txt2">{{this.title2}}</h2>
        <h3 class="riht__discription">{{this.subtitle}}</h3>
        <h3 class="riht__discription2">{{this.subtitle2}}</h3>
        <h3 class="riht__discription3">{{this.subtitle3}}</h3>
    </div>
    <div class="center">
        <div class="center__separator"></div>
    </div>
    <div class="left">
        <div class="left__cont-row">
           <div class="left__cont-column">
            <h2 class="riht__txt2">{{this.title3}}</h2>
            <h3 class="riht__discription4">{{this.subtitle4}}</h3>
            <h2 class="riht__txt2">{{this.title4}}</h2>
            <h3 class="riht__discription4">{{this.subtitle5}}</h3>
            <h2 class="riht__txt2">{{this.title5}}</h2>
            <h3 class="riht__discription4">{{this.subtitle6}}</h3>
           </div>
           <div class="left__cont-column">
            <h2 class="riht__txt2">{{this.title6}}</h2>
            <h3 class="riht__discription4">{{this.subtitle7}}</h3>
            <h2 class="riht__txt2">{{this.title7}}</h2>
            <h3 class="riht__discription4">{{this.subtitle8}}</h3>
            <h2 class="riht__txt2">{{this.title8}}</h2>
            <h3 class="riht__discription4">{{this.subtitle9}}</h3>
           </div>
        </div>

        <h3 class="left__txt">{{this.discription}}</h3>
    </div>
</div>
