import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-politics-confendiality',
  templateUrl: './politics-confendiality.component.html',
  styleUrls: ['./politics-confendiality.component.scss']
})
export class PoliticsConfendialityComponent {
  page:any = {};

  constructor(public site:SiteService, private route: ActivatedRoute){

  }


  ngOnInit(): void {   
    this.page = this.site.GetCurrentPage();
  }

  ngAfterContentInit(){
  }
}
