import { Component } from '@angular/core';

@Component({
  selector: 'app-button-mobile',
  templateUrl: './button-mobile.component.html',
  styleUrls: ['./button-mobile.component.scss']
})
export class ButtonMobileComponent {

}
